import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PosDeviceTo } from '../../models/api/pos-devices.model';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PosDevicesApiService {
  public readonly baseUrl: string;
  public readonly itemUrl: string;
  public readonly refreshUrl: string;
  public readonly deletePosDeviceUrl: string;

  constructor(private api: ApiService) {
    this.baseUrl = `/pos-devices`;
    this.itemUrl = `/v3${this.baseUrl}/{hashId}`;
    this.refreshUrl = `${this.baseUrl}/{hashId}/code`;
    this.deletePosDeviceUrl = `/v3/delete-pos-device/{hashId}`;
  }

  public getList(): Observable<PosDeviceTo[]> {
    return this.api.get(this.baseUrl);
  }

  public get(posDeviceId: string): Observable<PosDeviceTo> {
    const url = this.itemUrl.replace('{hashId}', posDeviceId);
    return this.api.get(url);
  }

  public unpair(posDeviceId: string): Observable<PosDeviceTo> {
    const url = this.itemUrl.replace('{hashId}', posDeviceId);
    return this.api.delete(url);
  }

  public delete(posDeviceId: string): Observable<PosDeviceTo> {
    const url = this.deletePosDeviceUrl.replace('{hashId}', posDeviceId);
    return this.api.delete(url);
  }

  public create(posDevice: PosDeviceTo): Observable<PosDeviceTo> {
    return this.api.post(this.baseUrl, posDevice);
  }

  public refresh(posDeviceId: string): Observable<PosDeviceTo> {
    const url = this.refreshUrl.replace('{hashId}', posDeviceId);
    return this.api.patch(url);
  }
}
