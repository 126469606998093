import { ReadOnlyRole } from './read-only.role';

export class SupportRole extends ReadOnlyRole {
  canViewRefundUrl(): boolean {
    return true;
  }

  canResolveInvoices(): boolean {
    return true;
  }

  canViewLightning(): boolean {
    return true;
  }
}
