import { createReducer, on } from '@ngrx/store';
import { CountryTo } from '../../models/api/country.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { getCountryListAction, getCountryListErrorAction, getCountryListSuccessAction } from './country-list.actions';

export type CountryListState = StateModel<CountryTo[]>;

const INITIAL_STATE: CountryListState = {
  errors: null,
  data: [],
  initial: true,
};

export const countryListReducer = createReducer(
  INITIAL_STATE,
  on(getCountryListAction, (state) => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getCountryListSuccessAction, (state, { countries }) => ({
    errors: null,
    data: countries,
  })),
  on(getCountryListErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
