import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getUserGuiSettingsAction,
  getUserGuiSettingsErrorAction,
  getUserGuiSettingsSuccessAction,
  updateUserGuiSettingsAction,
  updateUserGuiSettingsErrorAction,
  updateUserGuiSettingsSuccessAction,
} from '../actions/user-gui-settings.actions';
import { ApiService } from '../services/api.service';

export const USER_GUI_SETTINGS_ENDPOINT = '/user/gui-settings';

@Injectable()
export class UserGuiSettingsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserGuiSettingsAction),
      mergeMap((action) =>
        this.api.get(USER_GUI_SETTINGS_ENDPOINT).pipe(
          map((userSettings) => getUserGuiSettingsSuccessAction({ userSettings })),
          catchError((errors) => observableOf(getUserGuiSettingsErrorAction(errors))),
        ),
      ),
    ),
  );

  updateUserGuiSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserGuiSettingsAction),
      mergeMap(({ userSettings }) =>
        this.api.put(USER_GUI_SETTINGS_ENDPOINT, userSettings).pipe(
          map((response) => updateUserGuiSettingsSuccessAction()),
          catchError((errors) => observableOf(updateUserGuiSettingsErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
