import { Injectable } from '@angular/core';
import { Address4, Address6 } from 'ip-address';

export enum IpAddressType {
  V4,
  V6,
  NONE,
}

const IPV4_MASK = '/32';
const IPV6_MASK = '/128';

@Injectable()
export class IpAddressService {
  constructor() {}

  addMaskIfNotSet(address: string): string {
    return this.hasAddressMaskSet(address) ? address : this.addMaskByType(address);
  }

  removeMask(address: string): string {
    const slashIndex = address.indexOf('/');
    return address.substr(0, slashIndex);
  }

  private getIpAddressType(address: string): IpAddressType {
    return this.isAddressV4(address)
      ? IpAddressType.V4
      : this.isAddressV6(address)
        ? IpAddressType.V6
        : IpAddressType.NONE;
  }

  private getAddressObject(address: string): Address4 | Address6 {
    return this.getAddressV4Object(address) || this.getAddressV6Object(address);
  }

  private getAddressV4Object(address: string): Address4 {
    let addr;
    try {
      addr = new Address4(address);
    } catch (error) {
      return null;
    }

    return addr;
  }

  private getAddressV6Object(address: string): Address6 {
    let addr;
    try {
      addr = new Address6(address);
    } catch (error) {
      return null;
    }

    return addr;
  }

  private isAddressV4(address: string): boolean {
    try {
      new Address4(address);
    } catch (error) {
      return false;
    }

    return true;
  }

  private isAddressV6(address: string): boolean {
    try {
      new Address6(address);
    } catch (error) {
      return false;
    }

    return true;
  }

  private hasAddressMaskSet(address: string): boolean {
    const addressObj = this.getAddressObject(address);
    return addressObj.addressMinusSuffix !== addressObj.address;
  }

  private addMaskByType(address: string): string {
    const type = this.getIpAddressType(address);
    switch (type) {
      case IpAddressType.V6:
        return `${address}${IPV6_MASK}`;
      case IpAddressType.V4:
        return `${address}${IPV4_MASK}`;
    }
  }
}
