/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { DashboardChartModel } from '../models/api/dashboard-chart.model';
import { ErrorModel } from '../models/api/error.model';

export enum DashboardChartTypes {
  GET_DASHBOARD_CHART = 'dashboardChart/GET_DASHBOARD_CHART',
  GET_DASHBOARD_CHART_SUCCESS = 'dashboardChart/GET_DASHBOARD_CHART_SUCCESS',
  GET_DASHBOARD_CHART_ERROR = 'dashboardChart/GET_DASHBOARD_CHART_ERROR',
}

export const getDashboardChart = createAction(
  DashboardChartTypes.GET_DASHBOARD_CHART,
  props<{ chartCurrency: string; chartInterval: string }>(),
);

export const getDashboardChartSuccess = createAction(
  DashboardChartTypes.GET_DASHBOARD_CHART_SUCCESS,
  props<{ dashboardChartData: DashboardChartModel }>(),
);

export const getDashboardChartError = createAction(
  DashboardChartTypes.GET_DASHBOARD_CHART_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
