import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  loginTwoFANeededAction,
  logoutAction,
  logoutErrorAction,
  logoutSuccessAction,
  twoFactorAuthAction,
  twoFactorAuthErrorAction,
} from '../actions/auth.actions';
import { appActions } from '../constants';
import { ApiService } from '../services/api.service';

const USERS_LOGOUT_ENDPOINT = '/oauth/logout';

@Injectable()
export class AuthEffect {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap(({ email, password }) =>
        this.api.post_token(email, password).pipe(
          map((response) => loginSuccessAction({ token: response.tokenValue })),
          catchError((response: any) => {
            if (this.api.isInvalid2faErrorResponse(response)) {
              return observableOf(
                loginTwoFANeededAction({
                  username: email,
                  password: password,
                  twoFaRequiredType: this.api.get2faTypeFromErrorCode(response),
                }),
              );
            }
            return observableOf(loginErrorAction(response));
          }),
        ),
      ),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccessAction),
      map(() => ({ type: appActions.CLEAR })),
    ),
  );

  twoFactorAuthenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(twoFactorAuthAction),
      switchMap(({ email, password, otp }) =>
        this.api.post_token(email, password, otp).pipe(
          map((response) => loginSuccessAction({ token: response.tokenValue })),
          catchError((response: any) => observableOf(twoFactorAuthErrorAction(response))),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() =>
        this.api.post(USERS_LOGOUT_ENDPOINT).pipe(
          map(() => logoutSuccessAction()),
          catchError((errors) => observableOf(logoutErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
