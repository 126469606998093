import { createReducer, on } from '@ngrx/store';
import { CurrencyTo } from '../../models/api/currencyTo';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getCurrencyListAction,
  getCurrencyListErrorAction,
  getCurrencyListSuccessAction,
} from './currency-list.actions';

export type CurrencyListState = StateModel<CurrencyTo[]>;

const INITIAL_STATE: CurrencyListState = {
  errors: null,
  data: [],
  initial: true,
};

export const currencyListReducer = createReducer(
  INITIAL_STATE,
  on(getCurrencyListAction, (state) => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getCurrencyListSuccessAction, (state, { currencies }) => ({
    errors: null,
    data: currencies,
  })),
  on(getCurrencyListErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
