import { createReducer, on } from '@ngrx/store';
import { AdminCryptoPaymentMethodTo } from '../../../models/api/crypto-payment-method.model';
import { StateModel } from '../../../models/auxiliary/state.model';
import {
  getCryptoPaymentMethodsAdminAction,
  getCryptoPaymentMethodsAdminActionError,
  getCryptoPaymentMethodsAdminActionSuccess,
  updateCryptoPaymentMethodAdminActionSuccess,
} from './crypto-payment-methods-admin.actions';

export type AdminCryptoPaymentMethodsState = StateModel<AdminCryptoPaymentMethodTo[]>;

const INITIAL_STATE: AdminCryptoPaymentMethodsState = {
  errors: null,
  data: null,
  initial: true,
};

export const cryptoPaymentMethodsAdminReducer = createReducer(
  INITIAL_STATE,
  on(getCryptoPaymentMethodsAdminAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getCryptoPaymentMethodsAdminActionSuccess, (state, { paymentMethods }) => ({
    data: paymentMethods,
    errors: null,
  })),
  on(getCryptoPaymentMethodsAdminActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(updateCryptoPaymentMethodAdminActionSuccess, () => ({ ...INITIAL_STATE })),
);
