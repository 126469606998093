/**
 * Types of users:
 *
 * ro = read only
 * su = support
 * fa = full access (read-write)
 * ow = owner
 */
/**
 * Abstract role
 *
 * Blocks everything by default
 */
export abstract class AbstractRole {
  constructor() {
    // empty
  }

  canViewSettlementMethodList(): boolean {
    // ro+
    return false;
  }

  canModifySettlementMethod(): boolean {
    // fa+
    return false;
  }

  canCreateInvoice(): boolean {
    // fa+
    return false;
  }

  canViewSettlementList(): boolean {
    // ro+
    return false;
  }

  /*
  ›	Uživatel s readonly právy – zobrazení detailu, historie, odeslání webhooku, zobrazení webhook notifikace, resolve exception (take / refeund)
    ›	Uživatel s full-access a vyššími právy – zobrazení URL pro vrácení platby

   */

  canViewInvoiceDetail(): boolean {
    // fa+
    return false;
  }

  canViewRefundUrl(): boolean {
    // su+
    return false;
  }

  canResolveInvoices(): boolean {
    // su+
    return false;
  }

  canViewSettlementDetail(): boolean {
    // ro+
    return false;
  }

  canViewApiKeys(): boolean {
    // ro+
    return false;
  }

  canEditApiKeys(): boolean {
    // fa+
    return false;
  }

  canEdit2Fa(): boolean {
    // ro+
    return false;
  }

  canChangePassword(): boolean {
    // ro+
    return false;
  }

  canViewEmailInvoiceList(): boolean {
    // ro+
    return false;
  }

  canModifyAml(): boolean {
    // ro+
    return false;
  }

  canViewEmailInvoiceDetail(): boolean {
    // ro+
    return false;
  }

  canCreateEmailInvoice(): boolean {
    // ro+
    return false;
  }

  canEditInvoiceSettings(): boolean {
    // fa+
    return false;
  }

  canModifyInvoiceExpiration(): boolean {
    // fa+
    return false;
  }

  // 3.3.18
  canModifyAutoAcceptUnderpayment(): boolean {
    // fa+
    return false;
  }

  canModifyInvoiceExpirationAutoExtension(): boolean {
    // fa+
    return false;
  }

  canViewOsTransactionList(): boolean {
    // ro+
    return false;
  }

  canViewOsTransactionDetail(): boolean {
    // ro+
    return false;
  }

  canCreateOsTransaction(): boolean {
    // fa+
    return false;
  }

  canViewUserList(): boolean {
    // ow+
    return false;
  }

  canAccessUserDetail(): boolean {
    // ow+
    return false;
  }

  canCreatePaymentButton(): boolean {
    // fa+
    return false;
  }

  canViewPaymentButtonDetail(): boolean {
    // ro+
    return false;
  }

  canDeletePaymentButton(): boolean {
    // fa+
    return false;
  }

  canModifyCallbackPassword(): boolean {
    // ro+
    return false;
  }

  canAccessNotificationSettings(): boolean {
    // ro+
    return false;
  }

  canAccessPublicProfileSettings(): boolean {
    // fa+
    return false;
  }

  canEditCryptoPaymentMethod(): boolean {
    // fa+
    return false;
  }

  canViewLightning(): boolean {
    return false;
  }
}
