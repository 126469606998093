import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  loginTwoFANeededAction,
  twoFactorAuthAction,
  twoFactorAuthErrorAction,
  twoFactorInvalidCodeAction,
} from '../actions/auth.actions';
import {
  createMerchantEmailInvoiceAction,
  emailInvoiceErrorAction,
  emailInvoiceSuccessAction,
} from '../actions/email-invoice.actions';
import {
  confirmInvoiceTakeAction,
  confirmInvoiceTakeErrorAction,
  confirmInvoiceTakeOverpaymentAction,
  confirmInvoiceTakeOverpaymentErrorAction,
  confirmInvoiceTakeOverpaymentSuccessAction,
  confirmInvoiceTakeSuccessAction,
  createInvoiceTakeAction,
  createInvoiceTakeErrorAction,
  createInvoiceTakeOverpaymentAction,
  createInvoiceTakeOverpaymentErrorAction,
  createInvoiceTakeOverpaymentSuccessAction,
  createInvoiceTakeSuccessAction,
} from '../actions/invoice.actions';
import { hideLoaderAction, showLoaderAction } from '../actions/loader.actions';
import {
  changePasswordAction,
  changePasswordErrorAction,
  changePasswordSuccessAction,
  resetPasswordAction,
  resetPasswordErrorAction,
  resetPasswordSuccessAction,
  sendResetLinkAction,
  sendResetLinkErrorAction,
  sendResetLinkSuccessAction,
} from '../actions/login-prefill.actions';
import {
  uploadMerchantDocumentsAction,
  uploadMerchantDocumentsErrorAction,
  uploadMerchantDocumentsSuccessAction,
} from '../actions/merchant-document.actions';
import { createUserAction, createUserErrorAction, createUserSuccessAction } from '../actions/user-detail.actions';
import {
  createRefundMerchantAction,
  createRefundMerchantErrorAction,
  createRefundMerchantSuccessAction,
} from '../store/refund/refund.actions';

const showLoaderActions = [
  loginAction,
  twoFactorAuthAction,
  sendResetLinkAction,
  createInvoiceTakeAction,
  createInvoiceTakeOverpaymentAction,
  uploadMerchantDocumentsAction,
  changePasswordAction,
  resetPasswordAction,
  createUserAction,
  createMerchantEmailInvoiceAction,
  createRefundMerchantAction,
  confirmInvoiceTakeAction,
  confirmInvoiceTakeOverpaymentAction,
];

const hideLoaderActions = [
  loginTwoFANeededAction,
  loginSuccessAction,
  loginErrorAction,
  twoFactorInvalidCodeAction,
  twoFactorAuthErrorAction,
  sendResetLinkSuccessAction,
  sendResetLinkErrorAction,
  createInvoiceTakeSuccessAction,
  createInvoiceTakeOverpaymentSuccessAction,
  createInvoiceTakeErrorAction,
  createInvoiceTakeOverpaymentErrorAction,
  uploadMerchantDocumentsSuccessAction,
  uploadMerchantDocumentsErrorAction,
  changePasswordSuccessAction,
  changePasswordErrorAction,
  resetPasswordSuccessAction,
  resetPasswordErrorAction,
  createUserSuccessAction,
  createUserErrorAction,
  emailInvoiceSuccessAction,
  emailInvoiceErrorAction,
  createRefundMerchantSuccessAction,
  createRefundMerchantErrorAction,
  confirmInvoiceTakeSuccessAction,
  confirmInvoiceTakeOverpaymentSuccessAction,
  confirmInvoiceTakeErrorAction,
  confirmInvoiceTakeOverpaymentErrorAction,
];

@Injectable()
export class LoaderEffects {
  showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...showLoaderActions),
      map(() => showLoaderAction()),
    ),
  );

  hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...hideLoaderActions),
      map(() => hideLoaderAction()),
    ),
  );

  constructor(private actions$: Actions) {}
}
