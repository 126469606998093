/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { UserActivationModel } from '../models/api/user-activation.model';

export const loginPrefillActions = {
  ACTIVATE_USER_REQUEST: 'loginPrefill/ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS: 'loginPrefill/ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_ERROR: 'loginPrefill/ACTIVATE_USER_ERROR',
  RESET_PASSWORD_REQUEST: 'loginPrefill/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'loginPrefill/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'loginPrefill/RESET_PASSWORD_ERROR',
  SEND_RESET_LINK_REQUEST: 'password/SEND_RESET_LINK_REQUEST',
  SEND_RESET_LINK_SUCCESS: 'password/SEND_RESET_LINK_SUCCESS',
  SEND_RESET_LINK_ERROR: 'password/SEND_RESET_LINK_ERROR',
  CHANGE_PASSWORD_REQUEST: 'password/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'password/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'password/CHANGE_PASSWORD_ERROR',
};

export const activateUserAction = createAction(loginPrefillActions.ACTIVATE_USER_REQUEST, props<{ hash: string }>());

export const activateUserSuccessAction = createAction(
  loginPrefillActions.ACTIVATE_USER_SUCCESS,
  props<{ activationInfo: UserActivationModel }>(),
);

export const activateUserErrorAction = createAction(
  loginPrefillActions.ACTIVATE_USER_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const resetPasswordAction = createAction(
  loginPrefillActions.RESET_PASSWORD_REQUEST,
  props<{ hash: string; body: { password: string } }>(),
);

export const resetPasswordSuccessAction = createAction(
  loginPrefillActions.RESET_PASSWORD_SUCCESS,
  props<{ activationInfo: UserActivationModel }>(),
);

export const resetPasswordErrorAction = createAction(
  loginPrefillActions.RESET_PASSWORD_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const sendResetLinkAction = createAction(
  loginPrefillActions.SEND_RESET_LINK_REQUEST,
  props<{ body: { email: string } }>(),
);

export const sendResetLinkSuccessAction = createAction(loginPrefillActions.SEND_RESET_LINK_SUCCESS);

export const sendResetLinkErrorAction = createAction(
  loginPrefillActions.SEND_RESET_LINK_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const changePasswordAction = createAction(
  loginPrefillActions.CHANGE_PASSWORD_REQUEST,
  props<{ body: { oldPassword: string; newPassword: string }; twoFaCode: string }>(),
);

export const changePasswordSuccessAction = createAction(loginPrefillActions.CHANGE_PASSWORD_SUCCESS);

export const changePasswordErrorAction = createAction(
  loginPrefillActions.CHANGE_PASSWORD_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
