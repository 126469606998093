/* eslint-disable max-classes-per-file */
import { createAction } from '@ngrx/store';

export enum LoaderActionTypes {
  ShowLoader = 'Loader/SHOW',
  HideLoader = 'Loader/HIDE',
}

export const showLoaderAction = createAction(LoaderActionTypes.ShowLoader);

export const hideLoaderAction = createAction(LoaderActionTypes.HideLoader);
