import { UntypedFormControl } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function phoneNumberValidator(numberControl: UntypedFormControl): { [key: string]: boolean } {
  const phoneNumber = numberControl.value;

  if (!phoneNumber) {
    return null;
  }

  if (!isValidPhoneNumber(phoneNumber)) {
    return { phoneNumber: true };
  } else {
    return null;
  }
}
