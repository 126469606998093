import { Injectable } from '@angular/core';

const TYPE_FORM: boolean = false;
const TYPE_FORM_HASH: string = 'LgJqgPEf';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  showTypeForm(): boolean {
    return TYPE_FORM;
  }

  getTypeFormHash(): string {
    return TYPE_FORM_HASH;
  }
}
