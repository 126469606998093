import { createReducer, on } from '@ngrx/store';
import {
  getMerchantFeesErrorAction,
  getMerchantFeesSuccessAction,
  resetMerchantFeesErrorAction,
  updateMerchantFeesErrorAction,
  updateMerchantFeesSuccessAction,
} from '../actions/merchant-fees.actions';
import { MerchantFeesModel } from '../models/api/merchant-fees.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantFeesState = StateModel<MerchantFeesModel>;

const INITIAL_STATE: MerchantFeesState = {
  errors: null,
  data: null,
};

export const merchantFeesReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantFeesSuccessAction, updateMerchantFeesSuccessAction, (state, { merchantFees }) => ({
    errors: null,
    data: merchantFees,
  })),
  on(getMerchantFeesErrorAction, updateMerchantFeesErrorAction, resetMerchantFeesErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
