import { createReducer, on } from '@ngrx/store';
import {
  getMerchantProfilePageErrorAction,
  getMerchantProfilePageSuccessAction,
} from '../actions/merchant-profile-page.actions';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantProfilePageState = StateModel<PagingModel<MerchantProfileUnionModel>>;

const INITIAL_STATE: MerchantProfilePageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
};

export const merchantProfilePageReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantProfilePageSuccessAction, (state, { merchantProfiles }) => ({
    errors: null,
    data: merchantProfiles,
  })),
  on(getMerchantProfilePageErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
