import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PublicCryptoPaymentMethodTo } from '../../../models/api/crypto-payment-method.model';
import { AppStateModel } from '../../../models/auxiliary/app-state.model';
import { MessageService } from '../../../services/message.service';
import {
  getCryptoPaymentMethodsPublicAction,
  getCryptoPaymentMethodsPublicActionError,
} from './crypto-payment-methods-public.actions';
import { selectPublicCryptoPaymentMethods } from './crypto-payment-methods-public.selector';

@Injectable({
  providedIn: 'root',
})
export class CryptoPaymentMethodsPublicService {
  private isError = false;

  constructor(
    private store: Store<AppStateModel>,
    private messageService: MessageService,
    private actions: Actions,
  ) {
    this.store.select(selectPublicCryptoPaymentMethods).subscribe((cryptoPaymentMethods) => {
      if (cryptoPaymentMethods.initial) {
        this.refresh();
      }
    });

    this.actions.pipe(ofType(getCryptoPaymentMethodsPublicActionError)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Public Crypto Payment Method List Error`);
      this.isError = true;
    });
  }

  public refresh(): void {
    this.isError = false;
    this.store.dispatch(getCryptoPaymentMethodsPublicAction());
  }

  public getPaymentMethods(): Observable<PublicCryptoPaymentMethodTo[]> {
    if (this.isError) {
      this.refresh();
    }

    return this.store.pipe(
      select(selectPublicCryptoPaymentMethods),
      filter((state) => state != null && state.data != null),
      map(({ data }) => data),
    );
  }
}
