<div class="idenfy-qr-code-content">
  <div class="back-arrow__container">
    <mat-icon class="back-arrow__button" (click)="stepBack()">keyboard_backspace</mat-icon>
  </div>
  <ng-container *ngIf="methodType === 'APP'">
    <div class="wizard-step__header" *ngIf="methodType === 'APP'">
      <h1 class="bp-heading idenfy-qr-code-heading">App verification</h1>
      <p class="wizard-step__legend">Install iDenfy’s mobile app to upload your documents.</p>
      <div class="idenfy-qr-code-badges">
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/gb/app/idenfy-identity-verification/id1447603050"
          >
            <img
              alt="Download on the App Store"
              class="idenfy-qr-code-badge__apple"
              src="assets/idenfy/app-store-badge.svg"
            />
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.idenfy.idenfyApp"
          >
            <img
              alt="Get it on Google Play"
              class="idenfy-qr-code-badge__google"
              src="assets/idenfy/google-play-badge.png"
            />
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="wizard-step__legend">Once installed, enter the code below or scan the QR code from the app.</div>
      <div class="idenfy-qr-code-code" *ngIf="qrCodeMobileString">{{ qrCodeMobileString }}</div>
      <div class="idenfy-qr-code-image" *ngIf="qrCodeMobileImg">
        <img class="qr-code" src="{{ qrCodeMobileImg }}" alt="QR code" />
      </div>
      <div class="idenfy-qr-code-validity">
        This code is valid for {{ defaultExpiryTime }}.
        <br />
        The session expires after {{ defaultSessionLength }}.
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="methodType === 'BROWSER'">
    <div class="wizard-step__header" *ngIf="methodType === 'BROWSER'">
      <h1 class="bp-heading idenfy-qr-code-heading">Browser verification</h1>
      <p class="wizard-step__legend">
        Scan the QR code with your smartphone camera and continue in your mobile browser.
      </p>
    </div>
    <div>
      <div class="idenfy-qr-code-image" *ngIf="qrCodeBrowserImg">
        <img class="qr-code" src="{{ qrCodeBrowserImg }}" alt="QR code" />
      </div>
      <div class="idenfy-qr-code-validity">
        This code is valid for {{ defaultExpiryTime }}.
        <br />
        The session expires after {{ defaultSessionLength }}.
      </div>
    </div>
    <form [formGroup]="emailForm" (ngSubmit)="sendLinkToEmail()">
      <p class="wizard-step__legend">Use email instead</p>
      <div class="idenfy-qr-code-input-container">
        <input class="confirmo-platform-input idenfy-qr-code-input" placeholder="Your email" formControlName="email" />
        <div
          class="confirmo-input-invalid-tooltip__no-prepend idenfy-qr-code-input-tooltip"
          *ngIf="emailForm.invalid && emailForm.touched"
        >
          Please enter a valid email address
        </div>
        <button
          class="confirmo-button confirmo-button__white-blue idenfy-qr-code-button"
          type="submit"
          [disabled]="emailForm.invalid"
        >
          Send link
        </button>
      </div>
    </form>
    <button class="confirmo-button confirmo-button__blue" (click)="openDialog()">Continue in this browser</button>
  </ng-container>
</div>
