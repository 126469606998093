import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  finishMerchantDocumentAction,
  finishMerchantDocumentErrorAction,
  finishMerchantDocumentSuccessAction,
  getMerchantDocumentsAction,
  getMerchantDocumentsErrorAction,
  getMerchantDocumentsSuccessAction,
  uploadMerchantDocumentsAction,
  uploadMerchantDocumentsErrorAction,
  uploadMerchantDocumentsSuccessAction,
} from '../actions/merchant-document.actions';
import { ApiService } from '../services/api.service';

export const MERCHANT_DOCUMENT_ENDPOINT = '/merchant-profile/files';

@Injectable()
export class MerchantDocumentEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDocumentsAction),
      mergeMap(() =>
        this.api.get(MERCHANT_DOCUMENT_ENDPOINT).pipe(
          map((files) => getMerchantDocumentsSuccessAction({ files })),
          catchError((errors) => observableOf(getMerchantDocumentsErrorAction(errors))),
        ),
      ),
    ),
  );

  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadMerchantDocumentsAction),
      switchMap(({ files }) =>
        this.api.post(MERCHANT_DOCUMENT_ENDPOINT, files).pipe(
          map((files) => uploadMerchantDocumentsSuccessAction({ files })),
          catchError((errors) => observableOf(uploadMerchantDocumentsErrorAction(errors))),
        ),
      ),
    ),
  );

  finish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finishMerchantDocumentAction),
      switchMap(({ body }) =>
        this.api.post(`${MERCHANT_DOCUMENT_ENDPOINT}/finish`, body).pipe(
          map(() => finishMerchantDocumentSuccessAction()),
          catchError((errors) => observableOf(finishMerchantDocumentErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
