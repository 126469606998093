import { createReducer, on } from '@ngrx/store';
import {
  getFinancialReportInvoicesAction,
  getFinancialReportInvoicesActionError,
  getFinancialReportInvoicesActionSuccess,
  getFinancialReportPayoutsAction,
  getFinancialReportPayoutsActionError,
  getFinancialReportPayoutsActionSuccess,
  getUserStatsReportAction,
  getUserStatsReportActionError,
  getUserStatsReportActionSuccess,
} from '../actions/financial-reporting.actions';
import { FinancialReportTo } from '../models/api/financial-report.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type FinancialReportingState = StateModel<PagingModel<FinancialReportTo>>;

const INITIAL_STATE: FinancialReportingState = {
  errors: null,
  data: null,
};

export const financialReportingInvoicesReducer = createReducer(
  INITIAL_STATE,
  on(getFinancialReportInvoicesAction, () => ({ ...INITIAL_STATE, loading: true })),
  on(getFinancialReportInvoicesActionSuccess, (state, { financialReport }) => ({
    data: financialReport,
    errors: null,
    loading: false,
  })),
  on(getFinancialReportInvoicesActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);

export const financialReportingPayoutsReducer = createReducer(
  INITIAL_STATE,
  on(getFinancialReportPayoutsAction, () => ({ ...INITIAL_STATE, loading: true })),
  on(getFinancialReportPayoutsActionSuccess, (state, { financialReport }) => ({
    data: financialReport,
    errors: null,
    loading: false,
  })),
  on(getFinancialReportPayoutsActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);

export const reportingUserStatsReducer = createReducer(
  INITIAL_STATE,
  on(getUserStatsReportAction, () => ({ ...INITIAL_STATE, loading: true })),
  on(getUserStatsReportActionSuccess, (state, { financialReport }) => ({
    data: financialReport,
    errors: null,
    loading: false,
  })),
  on(getUserStatsReportActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
