import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable()
export class LogService {
  constructor(private appConfig: AppConfigService) {}

  debug(message: string, ...args: string[]): void {
    if (this.appConfig.config.production) {
      console.info(message, ...args);
    }
  }
}
