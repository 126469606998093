import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../models/api/error.model';
import { SubscriptionModel } from '../subscription.model';

export enum SubscriptionDetailActionTypes {
  GetSubscriptionDetail = 'subscriptionDetail/GET_SUBSCRIPTION_DETAIL',
  GetSubscriptionDetailSuccess = 'subscriptionDetail/GET_SUBSCRIPTION_DETAIL_SUCCESS',
  GetSubscriptionDetailError = 'subscriptionDetail/GET_SUBSCRIPTION_DETAIL_ERROR',
}

export const getSubscriptionDetailAction = createAction(
  SubscriptionDetailActionTypes.GetSubscriptionDetail,
  props<{ id: string }>(),
);

export const getSubscriptionDetailSuccessAction = createAction(
  SubscriptionDetailActionTypes.GetSubscriptionDetailSuccess,
  props<{ subscription: SubscriptionModel }>(),
);

export const getSubscriptionDetailErrorAction = createAction(
  SubscriptionDetailActionTypes.GetSubscriptionDetailError,
  props<{ errors: ErrorModel[] }>(),
);
