import { getCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { BlockchainCode } from '../enums/crypto-payment-methods/blockchain-code.enum';
import { CryptoAssetCode } from '../enums/crypto-payment-methods/crypto-asset-code.enum';
import { Layer2Code } from '../enums/crypto-payment-methods/layer2-code.enum';

const BASE_URL = 'assets/currencies';
const BASE_URL_CRYPTO_NETWORKS = BASE_URL + '/bw';
const DEFAULT_CRYPTO_ICON = BASE_URL + '/generic.svg';
const DEFAULT_PUBLIC_INVOICE_ICON = BASE_URL + '/public-generic.svg';

export const CRYPTO_ASSETS_LOGO_URLS: { asset: string; url: string; isLightning?: boolean }[] = [
  { asset: CryptoAssetCode.BTC, url: BASE_URL + '/btc.svg' },
  { asset: CryptoAssetCode.BTC, url: BASE_URL + '/bcl.png', isLightning: true },
  { asset: CryptoAssetCode.LTC, url: BASE_URL + '/ltc.svg' },
  { asset: CryptoAssetCode.ETH, url: BASE_URL + '/eth.svg' },
  { asset: CryptoAssetCode.USDC, url: BASE_URL + '/usdc.svg' },
  { asset: CryptoAssetCode.USDT, url: BASE_URL + '/usdt.svg' },
  { asset: CryptoAssetCode.SOL, url: BASE_URL + '/solana.png' },
  { asset: CryptoAssetCode.TRX, url: BASE_URL + '/trx.svg' },
  { asset: CryptoAssetCode.POL, url: BASE_URL + '/pol.svg' },
];

export const CRYPTO_NETWORKS_LOGO_URLS: { network: string; url: string }[] = [
  { network: BlockchainCode.TRON, url: BASE_URL_CRYPTO_NETWORKS + '/trx.svg' },
  { network: BlockchainCode.ETHEREUM, url: BASE_URL_CRYPTO_NETWORKS + '/eth.svg' },
  { network: BlockchainCode.SOLANA, url: BASE_URL_CRYPTO_NETWORKS + '/sol.svg' },
  { network: BlockchainCode.BITCOIN, url: BASE_URL_CRYPTO_NETWORKS + '/btc.svg' },
  { network: BlockchainCode.LITECOIN, url: BASE_URL_CRYPTO_NETWORKS + '/ltc.svg' },
  { network: BlockchainCode.BSC, url: BASE_URL_CRYPTO_NETWORKS + '/bsc.svg' },
  { network: Layer2Code.POLYGON, url: BASE_URL_CRYPTO_NETWORKS + '/polygon.svg' },
  { network: Layer2Code.ARBITRUM, url: BASE_URL_CRYPTO_NETWORKS + '/arbitrum.svg' },
  { network: Layer2Code.OPTIMISM, url: BASE_URL_CRYPTO_NETWORKS + '/optimism.svg' },
  { network: Layer2Code.LIGHTNING, url: BASE_URL_CRYPTO_NETWORKS + '/btc.svg' },
];

@Injectable({
  providedIn: 'root',
})
export class CurrencyIconService {
  constructor() {}

  getCryptoAssetIcon(asset: string, isLightning?: boolean): string {
    return (
      CRYPTO_ASSETS_LOGO_URLS.find((logo) => logo.asset === asset.toUpperCase() && !!logo.isLightning === !!isLightning)
        ?.url ?? DEFAULT_CRYPTO_ICON
    );
  }

  /**
   * Our icons for some fiat currencies: CAD, CHF, CZK, EUR, GBP, HUF, PLN, USD.
   * @param currency
   */
  getFiatCurrencyIcon(currency: string): string {
    return `assets/currencies/${currency.toLowerCase()}.png`;
  }

  /**
   * Returns css class of currency icon from `currency-flags` library.
   * See swprods/frontend/node_modules/currency-flags/dist/currency-flags.css for the list of classes.<br>
   * Important! **@use 'currency-flags/dist/currency-flags.css'** should be added into the component scss
   * @param currency
   */
  getFiatCurrencyIconClass(currency: string): string {
    return `currency-flag-${currency.toLowerCase()}`;
  }

  getCryptoNetworkIcon(blockchainCode: BlockchainCode, layer2Code: Layer2Code): string {
    if (layer2Code !== null) {
      return CRYPTO_NETWORKS_LOGO_URLS.find((logo) => layer2Code?.includes(logo.network))?.url;
    } else return CRYPTO_NETWORKS_LOGO_URLS.find((logo) => blockchainCode?.toUpperCase().includes(logo.network))?.url;
  }

  getDefaultCryptoLogo(): string {
    return DEFAULT_PUBLIC_INVOICE_ICON;
  }

  getFiatLogoText(currency: string): string {
    return getCurrencySymbol(currency, 'narrow');
  }

  /**
   * Returns css class of flag icon from `flag-icons` library.
   * See swprods/frontend/node_modules/flag-icons/sass/flag-icons.css for the list of classes.<br>
   * Important! **@use 'flag-icons/sass/flag-icons'** should be added into the component scss
   * @param country
   */
  getFlagIcon(country: string): string {
    return `fi fi-${country.toLowerCase()}`;
  }

  getBoltIcon(): string {
    return BASE_URL + '/bolt.svg';
  }
}
