/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { AdminEmailInvoiceModel } from '../models/api/email-invoice.model';
import { ErrorModel } from '../models/api/error.model';
import { PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum EmailInvoicePageTypes {
  GET_EMAIL_INVOICE_PAGE_ACTION = 'emailInvoicePage/GET_EMAIL_INVOICE_PAGE_ACTION',
  EMAIL_INVOICE_PAGE_ACTION_SUCCESS = 'emailInvoicePage/GET_EMAIL_INVOICE_PAGE_ACTION_SUCCESS',
  EMAIL_INVOICE_PAGE_ACTION_ERROR = 'emailInvoicePage/GET_EMAIL_INVOICE_PAGE_ACTION_ERROR',
}

export const getEmailInvoicePageAction = createAction(
  EmailInvoicePageTypes.GET_EMAIL_INVOICE_PAGE_ACTION,
  props<{ isAdmin: boolean; paging: PageRequestModel }>(),
);

export const emailInvoicePageActionSuccess = createAction(
  EmailInvoicePageTypes.EMAIL_INVOICE_PAGE_ACTION_SUCCESS,
  props<{ emailInvoices: PagingModel<AdminEmailInvoiceModel> }>(),
);

export const emailInvoicePageActionError = createAction(
  EmailInvoicePageTypes.EMAIL_INVOICE_PAGE_ACTION_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
