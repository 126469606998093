/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { OpenOrdersStatistics } from '../models/api/market-operation.model';

export enum openOrdersStatisticsActions {
  GET_REQUEST = 'openOrderStatistics/GET_REQUEST',
  GET_SUCCESS = 'openOrderStatistics/GET_SUCCESS',
  GET_ERROR = 'openOrderStatistics/GET_ERROR',
}

export const getOpenOrdersStatisticsAction = createAction(openOrdersStatisticsActions.GET_REQUEST);

export const getOpenOrdersStatisticsSuccessAction = createAction(
  openOrdersStatisticsActions.GET_SUCCESS,
  props<{ statistics: OpenOrdersStatistics }>(),
);

export const getOpenOrdersStatisticsErrorAction = createAction(
  openOrdersStatisticsActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
