import { createReducer, on } from '@ngrx/store';
import {
  updateMerchantProfileAdminAction,
  updateMerchantProfileAdminErrorAction,
  updateMerchantProfileAdminSuccessAction,
} from '../actions/merchant.actions';
import { MerchantProfileState } from './merchant-profile.reducer';

const INITIAL_STATE: MerchantProfileState = {
  errors: null,
  data: null,
};

export const merchantProfileAdminReducer = createReducer(
  INITIAL_STATE,
  on(updateMerchantProfileAdminAction, () => ({
    ...INITIAL_STATE,
  })),
  on(updateMerchantProfileAdminSuccessAction, (state, { merchantProfile }) => ({
    ...state,
    data: merchantProfile,
  })),
  on(updateMerchantProfileAdminErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
