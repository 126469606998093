import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';
import {
  getSubscriptionDetailAction,
  getSubscriptionDetailErrorAction,
  getSubscriptionDetailSuccessAction,
} from './subscription-detail.actions';

@Injectable()
export class SubscriptionDetailEffects {
  private readonly actions$ = inject(Actions);
  private readonly subscriptionService = inject(SubscriptionService);

  getDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptionDetailAction),
      mergeMap(({ id }) =>
        this.subscriptionService.getDetail(id).pipe(
          map((subscription) => getSubscriptionDetailSuccessAction({ subscription })),
          catchError((errors) => of(getSubscriptionDetailErrorAction(errors))),
        ),
      ),
    ),
  );
}
