import { createReducer, on } from '@ngrx/store';
import { StateModel } from '../../../models/auxiliary/state.model';
import { SubscriptionModel } from '../subscription.model';
import {
  getSubscriptionDetailAction,
  getSubscriptionDetailErrorAction,
  getSubscriptionDetailSuccessAction,
} from './subscription-detail.actions';

export type SubscriptionDetailState = StateModel<SubscriptionModel>;

const INITIAL_STATE: SubscriptionDetailState = {
  errors: null,
  data: undefined,
};

export const subscriptionDetailReducer = createReducer(
  INITIAL_STATE,
  on(getSubscriptionDetailAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(getSubscriptionDetailSuccessAction, (state, { subscription }) => ({
    ...state,
    data: subscription,
    loading: false,
  })),
  on(getSubscriptionDetailErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
