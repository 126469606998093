import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { addRefundTokenMapIfAbsentAction } from '../actions/refund-token-map.actions';
import { CUSTOMER_TOKEN_LENGTH } from '../constants';
import { InvoiceModel } from '../models/api/invoice.model';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { selectRefundTokenMap } from '../selectors/refund-token-map.selector';
import { randomHexString } from '../utils';
import { AppConfigService } from './app-config.service';
import { LogService } from './log-service';

const SOCKET_ENDPOINT = (invoiceId: string) => `/websocket/invoices/${invoiceId}/live`;
const SOCKET_CHECK_INTERVAL = 7500;
const SOCKET_RECONNECT_INTERVAL = 1000;

@Injectable()
export class InvoiceSocketService {
  private disposeOpenSocket: () => void;

  constructor(
    private store: Store<AppStateModel>,
    private appConfig: AppConfigService,
    private log: LogService,
  ) {}

  receiveInvoice(invoiceId: string): Observable<InvoiceModel> {
    const randomToken = randomHexString(CUSTOMER_TOKEN_LENGTH);
    this.addRefundTokenMapIfAbsent(invoiceId, randomToken);
    return new Observable((subscriber) => {
      this.store
        .select(selectRefundTokenMap)
        .pipe(
          map(({ data }) => data[invoiceId]),
          filter((token) => token != null),
          take(1),
        )
        .subscribe((token) => {
          const socketUrl = `${this.appConfig.config.apiSocketUrl}${SOCKET_ENDPOINT(invoiceId)}?token=${token}`;
          const connect = () => {
            this.initSocket(socketUrl).subscribe({
              next: (invoice) => subscriber.next(invoice),
              error: () => setTimeout(connect, SOCKET_RECONNECT_INTERVAL),
              complete: () => subscriber.complete(),
            });
          };
          connect();
        });
    });
  }

  private initSocket(url: string): Observable<InvoiceModel> {
    return new Observable((subscriber) => {
      // dispose last socket if there was one
      if (this.disposeOpenSocket != null) {
        this.disposeOpenSocket();
      }

      // create socket and heartbeat-nexting subject
      this.log.debug(`Initializing socket (${url})`);
      const socket = new WebSocket(url);
      const heartbeat = new Subject<void>();

      // close socket and error-break returned observable chain when heartbeat not received for too long
      const heartbeatSub = heartbeat.pipe(debounceTime(SOCKET_CHECK_INTERVAL)).subscribe(() => {
        this.log.debug(`Heartbeat not received; closing (${url})`);
        socket.close();
        heartbeat.complete();
        subscriber.error();
      });

      heartbeat.next();

      // create disposal function to be used before next initialization
      this.disposeOpenSocket = () => {
        this.log.debug(`Closing open socket (${url})`);
        socket.close();
        heartbeatSub.unsubscribe();
        heartbeat.complete();
        subscriber.complete();
      };

      // next invoice model updates and next received heartbeats
      socket.addEventListener('message', (event) => {
        const model = JSON.parse(event.data);
        if (model == null) {
          this.log.debug(`Received heartbeat (${url})`);
          heartbeat.next();
          return;
        }

        this.log.debug(`Received invoice model (${url})`);
        subscriber.next(model);
      });

      // error-break returned observable chain on error event emission
      socket.addEventListener('error', () => {
        this.log.debug(`Cannot connect to socket (${url})`);
        heartbeat.complete();
        subscriber.error();
      });
    });
  }

  private addRefundTokenMapIfAbsent(invoiceId: string, randomToken: string) {
    // we need to add new token only its absent
    this.store
      .select(selectRefundTokenMap)
      .pipe(
        map(({ data }) => (data == null ? null : data[invoiceId])),
        take(1),
      )
      .subscribe((map) => {
        if (map == null) {
          this.store.dispatch(addRefundTokenMapIfAbsentAction({ invoiceIdToken: { [invoiceId]: randomToken } }));
        }
      });
  }
}

// @Injectable()
// export class InvoiceSocketService {
//   readonly mockInvoice: InvoiceModel = {
//     refunds: [],
//     address: 'n4inBPmPop8HGQaqYBQWrwDoWgvkkAacjg',
//     cryptoUri: 'bitcoin:n4inBPmPop8HGQaqYBQWrwDoWgvkkAacjg?amount=0.01038367&r=https://bitcoinpay.com/sci/invoice/btc/inv4v9e1xdz8/',
//     createdAt: 1504268983,
//     timeoutTime: 1504269883,
//     id: 'inv4v9e1xdz8',
//     reference: '',
//     product: {
//       name: '',
//       description: 'pDesc'
//     },
//     status: 'paid',
//     unhandledExceptions: true,
//     rate: {
//       currencyFrom: 'USD',
//       currencyTo: 'TSC',
//       value: '4815.25318120'
//     },
//     invoice: {
//       amount: '50.00',
//       currency: 'USD'
//     },
//     crypto: {
//       amount: '0.01038367',
//       currency: 'TSC'
//     },
//     paid: {
//       amount: '0.00000001',
//       currency: 'TSC',
//       diff: '-0.01038367'
//     },
//     cryptoTransactions: [
//       {
//         txid: 'YayW76jrnrvIwCAGbYdq6m5JFneKl28r7',
//         amount: '0.10000000',
//         createdAt: 1505481246,
//         updatedAt: 1505481255,
//         confirmations: 3
//       },
//       {
//         txid: 'YayW76jrnrvIwCAGbYdq6m5JFneKl28r70',
//         amount: '0.19473769',
//         createdAt: 1505481409,
//         updatedAt: 1505481409,
//         confirmations: 3
//       }
//     ],
//     confirmations: 3,
//     requiredConfirmations: 5,
//     confirmingSince: 0,
//     paidSince: 0,
//     returnUrl: 'https://seznam.cz'
//   };
//
//   receiveInvoice(invoiceId: string): Observable<InvoiceModel> {
//     return Observable.of(this.mockInvoice);
//   }
// }
