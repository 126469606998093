import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replaces whitespaces inside string for using it as an identifier
 */
@Pipe({
  name: 'bpIdentifier',
  standalone: true,
})
export class IdentifierPipe implements PipeTransform {
  transform(identifier: string): string {
    return identifier.toString().replace(/ /g, '-');
  }
}
