import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MerchantModel } from '../../../../models/api/merchant.model';
import { PagingModel } from '../../../../models/auxiliary/paging.model';
import { RebalancingGroupState } from './rebalancing.reducer';

export const selectCurrentRebalancingGroup = createFeatureSelector<RebalancingGroupState>('rebalancingGroup');
export const selectCurrentRebalancingGroupData = createSelector(selectCurrentRebalancingGroup, ({ data }) => data);
export const selectCurrentRebalancingGroupLoading = createSelector(
  selectCurrentRebalancingGroup,
  ({ loading }) => loading,
);

export const selectCurrentRebalancingGroupPaging = createSelector(
  selectCurrentRebalancingGroupData,
  ({ offset, limit, totalCount, totalSentTo }): Omit<PagingModel<MerchantModel>, 'data'> => ({
    offset,
    limit,
    totalCount,
    totalSentTo,
  }),
);
