import { createReducer, on } from '@ngrx/store';
import {
  generateMerchantVerification,
  generateMerchantVerificationError,
  generateMerchantVerificationSuccess,
  getMerchantVerificationPersonAction,
  getMerchantVerificationPersonActionError,
  getMerchantVerificationPersonActionSuccess,
} from '../actions/merchant-verification-idenfy.actions';
import { IdcheckVerificationDataModel } from '../models/api/merchant-profile/idcheck-data.model';
import { PersonModel } from '../models/api/merchant-profile/person-model';
import { StateModel } from '../models/auxiliary/state.model';

export type IdcheckVerificationDataState = StateModel<IdcheckVerificationDataModel>;
export type VerificationPersonState = StateModel<PersonModel>;

const INITIAL_IDCHECK_STATE: IdcheckVerificationDataState = {
  errors: null,
  data: null,
};

const INITIAL_PERSON_STATE: VerificationPersonState = {
  errors: null,
  data: null,
};

export const merchantVerificationIdenfyGenerate = createReducer(
  INITIAL_IDCHECK_STATE,
  on(generateMerchantVerification, () => ({ ...INITIAL_IDCHECK_STATE })),
  on(generateMerchantVerificationSuccess, (state, { idcheckVerificationData }) => ({
    data: idcheckVerificationData,
    errors: null,
  })),
  on(generateMerchantVerificationError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);

export const merchantVerificationIdenfyPersonReducer = createReducer(
  INITIAL_PERSON_STATE,
  on(getMerchantVerificationPersonAction, () => ({ ...INITIAL_PERSON_STATE })),
  on(getMerchantVerificationPersonActionSuccess, (state, { person }) => ({
    data: person,
    errors: null,
  })),
  on(getMerchantVerificationPersonActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
