export const GOOGLE_TAG_MANAGER_ID = 'GTM-MV8MGV49'; // PROD vs TEST analytics is set via custom script in GTM Workspace UI
export const COOKIEYES_PROD_ID = '6cbc0b3180778f13a8b41cff';
export const COOKIEYES_TEST_ID = '65bb70b6e8e076b854372f89';
export const SOLANA_MAINNET_RPC_URL =
  'https://capable-patient-dinghy.solana-mainnet.quiknode.pro/1e4e03b11b5cb4aebc8ed787f3869378ae15390b/';
export const SOLANA_DEVNET_RPC_URL = 'https://api.devnet.solana.com';
export const WALLET_CONNECT_PROJECT_ID = 'd20b504445b2ab74e3148edc179025a2';

export const WALLET_CONNECT_METADATA = {
  name: 'Confirmo',
  description: 'Confirmo Payment Gateway',
  url: 'https://confirmo.net',
  icons: ['https://confirmo.net/confirmofavicon.png'],
};

export const WALLET_CONNECT_THEME = {
  '--w3m-z-index': 1500,
  '--w3m-accent-color': '#8100ff',
  '--w3m-border-radius-master': '3px',
};
