import { createReducer, on } from '@ngrx/store';
import {
  getDefaultPaymentSettingsAction,
  getDefaultPaymentSettingsErrorAction,
  getDefaultPaymentSettingsSuccessAction,
  getPaymentSettingsAction,
  getPaymentSettingsAdminAction,
  getPaymentSettingsErrorAction,
  getPaymentSettingsSuccessAction,
  updatePaymentSettingsSuccessAction,
} from '../actions/payment-settings.actions';
import { PaymentSettingsModel } from '../models/api/payment.settings.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantPayoutState = StateModel<PaymentSettingsModel>;

const INITIAL_STATE: MerchantPayoutState = {
  errors: null,
  data: null,
};

export const paymentSettingsReducer = createReducer(
  INITIAL_STATE,
  on(getPaymentSettingsAction, getPaymentSettingsAdminAction, getDefaultPaymentSettingsAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(
    getPaymentSettingsSuccessAction,
    getDefaultPaymentSettingsSuccessAction,
    updatePaymentSettingsSuccessAction,
    (state, { settings }) => ({
      ...state,
      data: settings,
    }),
  ),
  on(getPaymentSettingsErrorAction, getDefaultPaymentSettingsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
