import { createReducer, on } from '@ngrx/store';
import {
  getDefaultPaymentSettingsAction,
  getDefaultPaymentSettingsErrorAction,
  getDefaultPaymentSettingsSuccessAction,
} from '../actions/payment-settings.actions';
import { PaymentSettingsModel } from '../models/api/payment.settings.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantPayoutState = StateModel<PaymentSettingsModel>;

const INITIAL_STATE: MerchantPayoutState = {
  errors: null,
  data: null,
};

export const paymentSettingsDefaultReducer = createReducer(
  INITIAL_STATE,
  on(getDefaultPaymentSettingsAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getDefaultPaymentSettingsSuccessAction, (state, { settings }) => ({
    ...state,
    data: settings,
  })),
  on(getDefaultPaymentSettingsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
