import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getUserStatsAction, getUserStatsErrorAction, getUserStatsSuccessAction } from '../actions/user-stats.actions';
import { ApiService } from '../services/api.service';

@Injectable()
export class UserStatsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserStatsAction),
      mergeMap(() =>
        this.api.get('/admin/reporting/user-statistics').pipe(
          map((userStats) => getUserStatsSuccessAction({ userStats })),
          catchError((errors) => of(getUserStatsErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
