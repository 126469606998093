import { createReducer, on } from '@ngrx/store';
import { ANY_FILTER_VALUE } from '../../constants';
import {
  clearMerchantPageFiltersAction,
  getMerchantPageFiltersAction,
  updateMerchantPageFiltersAction,
} from './merchant-page-filters.actions';
import { MerchantPageFiltersModel } from './merchant-page-filters.model';

export type MerchantPageFiltersState = MerchantPageFiltersModel;

export const MERCHANT_PAGE_FILTERS_ANY_STATE: MerchantPageFiltersState = {
  verificationStatus: null,
  status: null,
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
  active: null,
  fiatSettlementsEnabled: null,
  countryCodeAlpha3: null,
};

export const merchantPageFiltersReducer = createReducer(
  MERCHANT_PAGE_FILTERS_ANY_STATE,
  on(getMerchantPageFiltersAction, (state) => state),
  on(updateMerchantPageFiltersAction, (_, { filters }) => ({
    ...filters,
  })),
  on(clearMerchantPageFiltersAction, () => MERCHANT_PAGE_FILTERS_ANY_STATE),
);
