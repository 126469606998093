/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { UserRole } from '../../enums/user-role.enum';
import { ErrorModel } from '../../models/api/error.model';
import { UserModel } from '../../models/api/user.model';

export enum UserListAsAdminActionTypes {
  GetUserListAsAdmin = 'userListAsAdmin/GET_REQUEST',
  GetUserListAsAdminSuccess = 'userListAsAdmin/GET_SUCCESS',
  GetUserListAsAdminError = 'userListAsAdmin/GET_ERROR',

  DeleteUserAsAdmin = 'userListAsAdmin/DELETE_USER_REQUEST',
  DeleteUserAsAdminSuccess = 'userListAsAdmin/DELETE_USER_SUCCESS',
  DeleteUserAsAdminError = 'userListAsAdmin/DELETE_USER_ERROR',

  ResendActivationEmailAsAdmin = 'userListAsAdmin/RESEND_ACTIVATION_EMAIL',
  ResendActivationEmailAsAdminSuccess = 'userListAsAdmin/RESEND_ACTIVATION_EMAIL_SUCCESS',
  ResendActivationEmailAsAdminError = 'userListAsAdmin/RESEND_ACTIVATION_EMAIL_ERROR',

  UpdateUserRoleAsAdmin = 'userListAsAdmin/UPDATE_USER_ROLE_AS_ADMIN_REQUEST',
  UpdateUserRoleAsAdminSuccess = 'userListAsAdmin/UPDATE_USER_ROLE_AS_ADMIN_SUCCESS',
  UpdateUserRoleAsAdminError = 'userListAsAdmin/UPDATE_USER_ROLE_AS_ADMIN_ERROR',
}

export const getUserListAsAdminAction = createAction(
  UserListAsAdminActionTypes.GetUserListAsAdmin,
  props<{ merchantHashId: string }>(),
);

export const getUserListAsAdminSuccessAction = createAction(
  UserListAsAdminActionTypes.GetUserListAsAdminSuccess,
  props<{ users: UserModel[] }>(),
);

export const getUserListAsAdminErrorAction = createAction(
  UserListAsAdminActionTypes.GetUserListAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);
export const deleteUserAsAdminAction = createAction(
  UserListAsAdminActionTypes.DeleteUserAsAdmin,
  props<{ user: UserModel }>(),
);

export const deleteUserAsAdminSuccessAction = createAction(
  UserListAsAdminActionTypes.DeleteUserAsAdminSuccess,
  props<{ user: UserModel }>(),
);

export const deleteUserAsAdminErrorAction = createAction(
  UserListAsAdminActionTypes.DeleteUserAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const resendActivationEmailAsAdminAction = createAction(
  UserListAsAdminActionTypes.ResendActivationEmailAsAdmin,
  props<{ user: UserModel }>(),
);

export const resendActivationEmailAsAdminSuccessAction = createAction(
  UserListAsAdminActionTypes.ResendActivationEmailAsAdminSuccess,
  props<{ user: UserModel }>(),
);

export const resendActivationEmailAsAdminErrorAction = createAction(
  UserListAsAdminActionTypes.ResendActivationEmailAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const updateUserRoleAsAdminAction = createAction(
  UserListAsAdminActionTypes.UpdateUserRoleAsAdmin,
  props<{ id: string; role: UserRole }>(),
);

export const updateUserRoleAsAdminSuccessAction = createAction(
  UserListAsAdminActionTypes.UpdateUserRoleAsAdminSuccess,
  props<{ user: UserModel }>(),
);

export const updateUserRoleAsAdminErrorAction = createAction(
  UserListAsAdminActionTypes.UpdateUserRoleAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);
