/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { FiatPaymentMethodModel } from '../../models/api/fiat-payment-method.model';

export enum FiatPaymentMethodsActions {
  GET_FIAT_PAYMENT_METHODS_REQUEST = 'fiatPaymentMethods/GET_FIAT_PAYMENT_METHODS_REQUEST',
  GET_FIAT_PAYMENT_METHODS_SUCCESS = 'fiatPaymentMethods/GET_FIAT_PAYMENT_METHODS_SUCCESS',
  GET_FIAT_PAYMENT_METHODS_ERROR = 'fiatPaymentMethods/GET_FIAT_PAYMENT_METHODS_ERROR',
}

export const getFiatPaymentMethodsAction = createAction(
  FiatPaymentMethodsActions.GET_FIAT_PAYMENT_METHODS_REQUEST,
  props<{ settlementEnabled?: boolean }>(),
);

export const getFiatPaymentMethodsSuccessAction = createAction(
  FiatPaymentMethodsActions.GET_FIAT_PAYMENT_METHODS_SUCCESS,
  props<{ paymentMethods: FiatPaymentMethodModel[] }>(),
);

export const getFiatPaymentMethodsErrorAction = createAction(
  FiatPaymentMethodsActions.GET_FIAT_PAYMENT_METHODS_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
