<bp-generic-dialog (closePressed)="closeModal(false)">
  <div class="display-flex display-flex__column min-height">
    <div class="animate__appear-down dialog-header__platform">
      <div class="dialog-header-text dialog-header-text__platform bp-heading">Unsaved documents</div>
    </div>
    <div class="animate__appear-down dialog-info-message extra-padding">
      <p class="bp-large-paragraph">
        Please save uploaded documents by pressing
        <span class="accent-color">Finish</span>
        button on the verification page
      </p>
    </div>
    <div class="display-flex animate__appear-down extra-padding">
      <button class="confirmo-button confirmo-button__white-blue extra-margin" (click)="closeModal(true)">
        Leave page anyway
      </button>
      <button class="confirmo-button confirmo-button__blue extra-margin" (click)="closeModal(false)">Ok</button>
    </div>
  </div>
</bp-generic-dialog>
