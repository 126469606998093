import { createReducer, on } from '@ngrx/store';
import { ANY_FILTER_VALUE } from '../../constants';
import { Time } from '../../enums/time.enum';
import { getLedgerFiltersAction, updateLedgerFiltersAction } from './ledger-filters.actions';
import { LedgerFiltersModel } from './ledger-filters.model';

export type LedgerFiltersState = LedgerFiltersModel;

export const LEDGER_FILTERS_ANY_STATE: LedgerFiltersState = {
  currency: ANY_FILTER_VALUE,
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const LEDGER_FILTERS_INITIAL_STATE: LedgerFiltersState = {
  currency: ANY_FILTER_VALUE,
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const ledgerFiltersReducer = createReducer(
  LEDGER_FILTERS_INITIAL_STATE,
  on(getLedgerFiltersAction, (state) => ({
    ...state,
  })),
  on(updateLedgerFiltersAction, (state, { filters }) => ({
    ...filters,
  })),
);
