import { HttpParams } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import deepEqual from 'deep-equal';
import { printFormat } from 'iban';
import moment, { Moment } from 'moment';
import { toDataURL } from 'qrcode';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ANY_FILTER_VALUE,
  CUSTOMER_TOKEN_LENGTH,
  CUSTOM_RANGE,
  DATE_FILTER_MONTH_FORMAT,
  NEVER_ACTIVE_INVOICE_STATES,
} from './constants';
import { Time } from './enums/time.enum';
import { InvoiceModel } from './models/api/invoice.model';
import { SelectItemModel } from './models/auxiliary/select-item.model';
import { StateModel } from './models/auxiliary/state.model';
import { WizardModel } from './models/auxiliary/wizard.model';
import { FilterTimeRange } from './store/filters/filter-time-range.model';

declare const scrypt: any;
type AnyWizardState = StateModel<WizardModel<any>>;

export function decodeRefundHash(refundHash: string): [string, string] {
  try {
    const match = atob(refundHash).match(new RegExp(`^(.*)(.{${CUSTOMER_TOKEN_LENGTH}})$`));
    return [match[1], match[2]];
  } catch (e) {
    return [null, null];
  }
}

export function filterWizardStep(step: number): (state: Observable<AnyWizardState>) => Observable<AnyWizardState> {
  return (wizardState) => wizardState.pipe(filter(({ data }) => data.step === step));
}

export function generateQrCodeDataUrl(
  data: string,
  callback: (dataUrl?: string) => void,
  errorCallback?: (error: Error) => void,
): void {
  toDataURL(data, { margin: 0 }, (error: Error, dataUrl: string) => {
    if (error) {
      if (errorCallback) {
        errorCallback(error);
      } else {
        callback(null);
      }
    } else {
      callback(dataUrl);
    }
  });
}

export function getMonthDay(dayNumber: number): string {
  const suffixes: { [day: number]: string } = { 1: 'st', 2: 'nd', 3: 'rd', 21: 'st', 22: 'nd', 23: 'rd' };
  return `${dayNumber}${suffixes[dayNumber] || 'th'}`;
}

export function hashPassword(password: string, email: string, cnonce: string, nonce: string): Observable<string> {
  const scryptOptions = { N: 16384, r: 8, p: 1, dkLen: 32, encoding: 'base64' };
  return new Observable((subscriber) => {
    scrypt(password, email, scryptOptions, (innerHash: string) => {
      scrypt(`${nonce}${innerHash}`, cnonce, scryptOptions, (finalHash: string) => {
        subscriber.next(finalHash);
        subscriber.complete();
      });
    });
  });
}

export function markAllAsTouched(formGroup: UntypedFormGroup, onlyNonEmpty: boolean = false): void {
  const groupChildren = Object.keys(formGroup.controls).map((key) => formGroup.controls[key]);
  groupChildren
    .filter((child) => child instanceof UntypedFormGroup)
    .forEach((group) => markAllAsTouched(group as UntypedFormGroup, onlyNonEmpty));
  groupChildren
    .filter((child) => child instanceof UntypedFormControl && (!onlyNonEmpty || child.value !== ''))
    .forEach((control) => control.markAsTouched());
}

export interface QueryParams {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
}

export function queryParams(params: QueryParams = {}): string {
  return `?${new HttpParams({ fromObject: params })}`;
}

export function randomHexString(length: number = 16): string {
  return Array(length)
    .fill(null)
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
}

export function range(fromIncluding: number, toExcluding: number): number[] {
  return Array(toExcluding - fromIncluding)
    .fill(null)
    .map((_, index) => fromIncluding + index);
}

export function toSnakeCase(camelString: string): string {
  return String(camelString)
    .split('')
    .map((char, index) => (char >= 'A' && char <= 'Z' && index > 0 ? `-${char.toLowerCase()}` : char))
    .join('');
}

export function invoiceFilterAmountLabel(exponent: number): string {
  if (exponent === 4) {
    return '10000.00 \u2264'; /* \u2264 char is LESS-THAN OR EQUAL TO */
  } else {
    return `${(10 ** exponent).toFixed(2)} \u2013 ${(10 ** (exponent + 1) - 0.01).toFixed(2)}`;
    /* \u2013 char is dash */
  }
}

export function generateMonthsToPast(monthsToPast: number): SelectItemModel[] {
  const months: SelectItemModel[] = [];
  let time = moment();
  for (let i = 0; i < monthsToPast; i++) {
    time = time.subtract(1, 'month');
    const date = time.format(DATE_FILTER_MONTH_FORMAT);
    months.push({ label: date, value: date });
  }
  return months;
}

export function resolveCreatedAtFrom(input: Time | string): string {
  let time;
  if (typeof input === 'string') {
    time = moment(input, DATE_FILTER_MONTH_FORMAT);
  } else {
    time = moment().subtract(input, 'd');
  }
  return String(time.unix());
}

export function resolveCreatedAtTo(input: Time | string, secondsShift: number = 0): string {
  let time;
  if (typeof input === 'string') {
    time = moment(input, DATE_FILTER_MONTH_FORMAT).endOf('month');
  } else {
    time = moment();
  }
  return String(time.unix() + secondsShift);
}

export function resolveCreatedAtFromAndToByTimeRange(createdAt: FilterTimeRange): {
  createdAtFrom: number;
  createdAtTo: number;
} {
  let createdAtFrom;
  let createdAtTo;
  if (createdAt.type === CUSTOM_RANGE) {
    createdAtFrom = createdAt.createdAtFrom;
    createdAtTo = createdAt.createdAtTo;
  } else if (createdAt.type === ANY_FILTER_VALUE) {
    createdAtFrom = null;
    createdAtTo = null;
  } else {
    createdAtFrom = resolveCreatedAtFrom(createdAt.type);
    createdAtTo = resolveCreatedAtTo(createdAt.type, 60);
  }

  return { createdAtFrom, createdAtTo };
}

export function isFilterValueSet<T>(value: T | null | undefined | typeof ANY_FILTER_VALUE): value is T {
  return value != null && value !== ANY_FILTER_VALUE;
}

export function amountToValue(amountFrom: string): string {
  if (amountFrom === '0') {
    return Number(0.09).toFixed(2);
  } else if (amountFrom === '10000.00') {
    return (Number(amountFrom) * 1000000000000).toFixed(2);
  } else {
    return (Number(amountFrom) * 10 - 0.01).toFixed(2);
  }
}

export function isNotEqual(value: any): ValidatorFn {
  return (amountControl: AbstractControl): { isEqual: true } => {
    if (amountControl.value === value) {
      return { isEqual: true };
    }
    return null;
  };
}

export function isGreaterThan(value: number): ValidatorFn {
  return (amountControl: AbstractControl): { isGreaterThan: true } => {
    if (isNaN(amountControl.value) || parseFloat(amountControl.value) <= value) {
      return { isGreaterThan: true };
    }

    return null;
  };
}

export function getIsoDateString(date: Moment): string {
  return date.format('YYYY-MM-DD');
}

export function isElementOverflown(element: HTMLElement): boolean {
  if (!element) {
    return false;
  }

  return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
}

export function scrollToElement(parentElem: ElementRef, elemId: string): void {
  const childNodes = [...parentElem.nativeElement.childNodes];
  const elem = childNodes.find((child) => child.id === elemId);
  elem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
}

/**
 * Replaces empty values (empty string, empty object) with undefined.
 *
 * Is used in JSON.stringify to remove empty fields.
 *
 * @param {string} key - JSON key
 * @param {any} value - JSON value
 */
function replaceEmptyValuesByUndefined(key: string, value: any): any {
  if (
    value == null ||
    value === '' ||
    (Object.keys(value).length === 0 && value.constructor === Object) ||
    deepEqual(value, {})
  ) {
    return undefined;
  } else if (value && typeof value === 'object') {
    let isEmptyObject = true;

    Object.keys(value).forEach((objKey) => {
      if (value[objKey] != null && value[objKey] !== '') {
        isEmptyObject = isEmptyObject && false;
      }
    });

    if (isEmptyObject) {
      return undefined;
    } else {
      return value;
    }
  } else {
    return value;
  }
}

export function removeEmptyFields(obj: any): any {
  const stringWithoutEmptyFields = JSON.stringify(obj, replaceEmptyValuesByUndefined);

  return JSON.parse(stringWithoutEmptyFields);
}

export function neverActiveInvoice(invoice: InvoiceModel): boolean {
  return NEVER_ACTIVE_INVOICE_STATES.includes(invoice.status) && invoice.activeSince === null;
}

export function isStringNullOrEmpty(text: string): boolean {
  return text === undefined || text === null || text === '';
}

export function isMobileView(): boolean {
  return window.innerWidth <= 600;
}

export function isDesktopView(): boolean {
  return window.innerWidth >= 1000;
}

export const sortAlphabetically = (a: string, b: string) => a.localeCompare(b);

export const formatIban = (iban: string): string => {
  return printFormat(iban);
};

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function deepEqualCheck(obj1: any, obj2: any): boolean {
  return deepEqual(obj1, obj2);
}

export function copyToClipBoard(textToCopy: string): Promise<void> {
  return window.isSecureContext && navigator.clipboard
    ? navigator.clipboard.writeText(textToCopy).catch(() => unsecuredCopyText(textToCopy)) // try unsecured copy on fail
    : unsecuredCopyText(textToCopy);
}

function unsecuredCopyText(textToCopy: string): Promise<void> {
  const mockTextarea = document.createElement('textarea');
  mockTextarea.value = textToCopy;

  document.body.appendChild(mockTextarea);
  mockTextarea.select();
  const promise = new Promise<void>((resolve, reject) => {
    try {
      document.execCommand('copy');
      resolve();
    } catch (err) {
      reject();
    }
  });
  document.body.removeChild(mockTextarea);
  return promise;
}

export function onNavigate(url: string) {
  window.open(url, '_blank');
}
