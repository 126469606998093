import { AbstractRole } from './abstract.role';

export class ReadOnlyRole extends AbstractRole {
  canViewSettlementMethodList(): boolean {
    return true;
  }

  canViewSettlementList(): boolean {
    return true;
  }

  canViewInvoiceDetail(): boolean {
    return true;
  }

  canViewSettlementDetail(): boolean {
    return true;
  }

  canViewApiKeys(): boolean {
    return true;
  }

  canEdit2Fa(): boolean {
    return true;
  }

  canChangePassword(): boolean {
    return true;
  }

  canViewEmailInvoiceList(): boolean {
    return true;
  }

  canModifyAml(): boolean {
    return true;
  }

  canViewEmailInvoiceDetail(): boolean {
    return true;
  }

  canViewOsTransactionList(): boolean {
    return true;
  }

  canViewOsTransactionDetail(): boolean {
    return true;
  }

  canViewPaymentButtonDetail(): boolean {
    return true;
  }

  canAccessNotificationSettings(): boolean {
    return true;
  }
}
