import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MerchantProfileService } from '../../platform/services/merchant-profile.service';
import { merchantVerificationCompanyRoutes, merchantVerificationPersonRoutes } from '../../platform/wizard-routing';
import {
  wizardNextStepAction,
  wizardSelectStepAction,
  wizardSetMaxAllowedStepsAction,
  wizardStepBackAction,
} from '../actions/wizard.actions';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DIALOG_CONFIG_WITH_AUTOFOCUS } from '../dialogs-utils';
import { MerchantProfileType } from '../enums/merchant-profile-type.enum';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { selectMerchantProfile } from '../selectors/merchant-profile.selectors';
import { selectRouter } from '../selectors/router.selector';
import { MerchantVerificationWizardService } from '../services/merchant-verification-wizard.service';

@Injectable()
export class WizardEffects {
  selectStep = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wizardSelectStepAction),
        withLatestFrom(this.store.select(selectRouter)),
        tap(([action, routerState]) => {
          const countSteps = routerState.state.flattened.data.countSteps;
          const currentStep = routerState.state.flattened.data.currentStep;
          const nextStep = action.step;

          if (nextStep !== currentStep) {
            if (nextStep >= 0 && nextStep <= countSteps) {
              this.router.navigate(['../', nextStep.toString()], { relativeTo: action.route });
            } else {
              this.router.navigate(['/']);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  refreshMaxAllowedStep = createEffect(() =>
    this.getNextStep$().pipe(
      map(([action, routerState, profile]) => {
        const maxAllowedSteps = this.merchantVerificationWizardService.getMaximalAllowedStepForMerchant(profile);
        return wizardSetMaxAllowedStepsAction({ maxAllowedSteps });
      }),
    ),
  );

  nextStep = createEffect(
    () =>
      this.getNextStep$().pipe(
        tap(([action, routerState, profile]) => {
          const wizardRoutes =
            profile.type === MerchantProfileType.COMPANY
              ? merchantVerificationCompanyRoutes
              : merchantVerificationPersonRoutes;

          const countSteps = wizardRoutes.routes.length;
          const currentStep = routerState.state.flattened.data.currentStep;
          const nextStep = currentStep + 1;

          if (nextStep <= countSteps) {
            this.router.navigate([`/platform/wizard/${wizardRoutes.prefix}`, 'step', nextStep.toString()]);
          } else {
            this.displayFinishDialog();
          }
        }),
      ),
    { dispatch: false },
  );

  stepBack = createEffect(
    () =>
      this.getStepBack$().pipe(
        tap(([action, routerState, profile]) => {
          const wizardRoutes =
            profile.type === MerchantProfileType.COMPANY
              ? merchantVerificationCompanyRoutes
              : merchantVerificationPersonRoutes;

          const currentStep = routerState.state.flattened.data.currentStep;
          const stepBack = currentStep - 1;

          this.router.navigate([`/platform/wizard/${wizardRoutes.prefix}`, 'step', stepBack.toString()]);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppStateModel>,
    private router: Router,
    private merchantProfileService: MerchantProfileService,
    private merchantVerificationWizardService: MerchantVerificationWizardService,
    private dialog: MatDialog,
  ) {}

  protected getNextStep$() {
    return this.actions$.pipe(
      ofType(wizardNextStepAction),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select(selectRouter).pipe(take(1)),
          this.store.select(selectMerchantProfile).pipe(
            take(1),
            mergeMap((state) => {
              if (state.data == null) {
                return this.merchantProfileService.get();
              } else {
                return of(state.data);
              }
            }),
          ),
        ]),
      ),
    );
  }

  protected getStepBack$() {
    return this.actions$.pipe(
      ofType(wizardStepBackAction),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select(selectRouter).pipe(take(1)),
          this.store.select(selectMerchantProfile).pipe(
            take(1),
            mergeMap((state) => {
              if (state.data == null) {
                return this.merchantProfileService.get();
              } else {
                return of(state.data);
              }
            }),
          ),
        ]),
      ),
    );
  }

  private displayFinishDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...DIALOG_CONFIG_WITH_AUTOFOCUS,
      data: {
        title: `Documents Submitted`,
        confirmMessage:
          `Your documents have been submitted and are being reviewed.` +
          ` You will receive an email once your verification is complete.`,
        okButtonText: `Ok`,
        cancelButtonText: ``,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/platform/dashboard']);
    });
  }
}
