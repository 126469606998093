import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  createMerchantPersonAction,
  createMerchantPersonErrorAction,
  createMerchantPersonSuccessAction,
} from '../../../../../shared/actions/merchant-person.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { closeDialogWithAnimation } from '../../../../../shared/dialogs-utils';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { MessageService } from '../../../../../shared/services/message.service';
import { markAllAsTouched } from '../../../../../shared/utils';
import { MerchantPersonFormComponent } from '../../../../common-components/aml-commons/merchant-person-form/merchant-person-form.component';

@Component({
  selector: 'bp-person-add-dialog',
  templateUrl: './person-add-dialog.component.html',
  styleUrls: ['./person-add-dialog.component.scss'],
})
export class PersonAddDialogComponent extends AbstractComponent {
  @ViewChild(MerchantPersonFormComponent, { static: true })
  formComponent: MerchantPersonFormComponent;

  constructor(
    private dialogRef: MatDialogRef<PersonAddDialogComponent>,
    private store: Store<AppStateModel>,
    private actions: Actions,
    private messageService: MessageService,
  ) {
    super();

    this.actions.pipe(ofType(createMerchantPersonSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.messageService.success('Person successfully created');
      closeDialogWithAnimation(this.dialogRef);
    });

    this.actions
      .pipe(ofType(createMerchantPersonErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => this.messageService.showErrors(errors, 'Person Create Error'));
  }

  add(): void {
    if (this.formComponent.form.invalid) {
      markAllAsTouched(this.formComponent.form);
      return;
    }

    this.store.dispatch(createMerchantPersonAction({ person: this.formComponent.form.value }));
  }

  close(): void {
    closeDialogWithAnimation(this.dialogRef);
  }
}
