<form [formGroup]="companyAccountForm" (ngSubmit)="updateCompanyAccount()">
  <div class="heading-container">
    <ng-container *ngIf="!isAdmin">
      <h2 class="bp-subheading-new merchant-verification-heading">Company information</h2>
    </ng-container>
  </div>
  <div class="container margin-bottom">
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="formFieldInput; context: { controlName: 'name', placeholder: 'Company name' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'street', placeholder: 'Address', autocomplete: 'street-address' }
        "
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'city', placeholder: 'City', grow: true, autocomplete: 'address-level2' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldSelectCountries;
          context: { controlName: 'countryCode', placeholder: 'Country', countries: countries, grow: true }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          formFieldInput;
          context: { controlName: 'zip', placeholder: 'ZIP', autocomplete: 'postal-code' }
        "
      ></ng-container>
    </div>
    <div class="confirmo-row display-block-on-mobile">
      <ng-container
        *ngTemplateOutlet="formFieldInput; context: { controlName: 'businessId', placeholder: 'Company ID' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="formFieldInput; context: { controlName: 'vatId', placeholder: 'VAT' }"
      ></ng-container>
    </div>
  </div>
  <button
    *ngIf="!isAdmin"
    class="confirmo-button confirmo-button__blue merchant-verification-button"
    type="submit"
    [disabled]="companyAccountForm.invalid"
  >
    Continue
  </button>

  <ng-template
    #formFieldSelectCountries
    let-controlName="controlName"
    let-placeholder="placeholder"
    let-countries="countries"
    let-grow="grow"
  >
    <div class="text-input-group nopadding" [ngClass]="grow ? 'col-wider' : 'col'">
      <label class="label-text">
        {{ placeholder }}
      </label>
      <div class="verification-form-input-tooltip-container">
        <div class="verification-input">
          <bp-country-select
            [countries]="countries"
            [(selected)]="selectedCountry"
            (selectedChange)="onSelectChange($event)"
            [normalFontWeight]="true"
            errorMessage="Required"
          ></bp-country-select>
        </div>
        <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="companyCountryNotSupported">
          Country not supported
        </div>
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="companyAccountFieldMissing(controlName)"
        >
          Required
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #formFieldInput
    let-controlName="controlName"
    let-placeholder="placeholder"
    let-grow="grow"
    let-autocomplete="autocomplete"
  >
    <div class="text-input-group nopadding" [ngClass]="grow ? 'col-wider' : 'col'">
      <label class="label-text">
        {{ placeholder }}
      </label>
      <div class="verification-form-input-tooltip-container">
        <input
          [formControlName]="controlName"
          class="confirmo-platform-input verification-input"
          type="text"
          [placeholder]="placeholder"
          [autocomplete]="autocomplete"
        />
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="companyAccountFieldMissing(controlName)"
        >
          Required
        </div>
        <div
          class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
          *ngIf="containsWhitespaceAtStartOrEnd(controlName)"
        >
          Can't contain whitespace at the start or the end
        </div>
      </div>
    </div>
  </ng-template>
</form>
