/**
 * Id check status of profile person.
 */
export enum IdCheckStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  SUSPECTED = 'SUSPECTED',
  REVIEWING = 'REVIEWING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  NONE = 'NONE',
}
