/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { CountryTo } from '../../models/api/country.model';
import { ErrorModel } from '../../models/api/error.model';

export enum countryListActions {
  GET_REQUEST = 'countryList/GET_REQUEST',
  GET_SUCCESS = 'countryList/GET_SUCCESS',
  GET_ERROR = 'countryList/GET_ERROR',
}

export const getCountryListAction = createAction(countryListActions.GET_REQUEST);

export const getCountryListSuccessAction = createAction(
  countryListActions.GET_SUCCESS,
  props<{ countries: CountryTo[] }>(),
);

export const getCountryListErrorAction = createAction(countryListActions.GET_ERROR, props<{ errors: ErrorModel[] }>());
