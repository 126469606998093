import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'bp-header-dialog',
  templateUrl: './header-dialog.component.html',
  styleUrls: ['./header-dialog.component.scss'],
})
export class HeaderDialogComponent {
  @Output()
  closePressed = new EventEmitter<void>();

  @Input()
  dialogTitle: string;

  @Input()
  isPlatformDialog = false;

  @Input()
  headerButton: TemplateRef<any>;
}
