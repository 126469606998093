export enum CloseReason {
  SUCCESS,
  CANCEL,
  ERROR,
}

export class DialogResult<T = null> {
  reason: CloseReason;
  data: T;

  static success<U>(data?: U): DialogResult<U> {
    return new DialogResult<U>(CloseReason.SUCCESS, data);
  }

  static cancel(): DialogResult<null> {
    return new DialogResult<null>(CloseReason.CANCEL);
  }

  static error<U>(data?: U): DialogResult<U> {
    return new DialogResult<U>(CloseReason.ERROR, data);
  }

  constructor(reason: CloseReason = CloseReason.SUCCESS, data: T = null) {
    this.reason = reason;
    this.data = data;
  }
}
