import { createReducer, on } from '@ngrx/store';
import {
  clearUserDetailStateAction,
  getUserDetailAction,
  getUserDetailErrorAction,
  getUserDetailSuccessAction,
  updateMerchantPopupFormAction,
  updateMerchantPopupFormErrorAction,
  updateMerchantPopupFormSuccessAction,
} from '../actions/user-detail.actions';
import { UserModel } from '../models/api/user.model';
import { StateModel } from '../models/auxiliary/state.model';

export type UserDetailState = StateModel<UserModel>;

const INITIAL_STATE: UserDetailState = {
  errors: null,
  data: null,
};

export const userDetailReducer = createReducer(
  INITIAL_STATE,
  on(clearUserDetailStateAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getUserDetailAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(getUserDetailSuccessAction, (state, { user }) => ({
    ...state,
    data: user,
  })),
  on(getUserDetailErrorAction, updateMerchantPopupFormErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(updateMerchantPopupFormAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(updateMerchantPopupFormSuccessAction, (state) => ({
    ...state,
  })),
);
