import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { MessageService } from '../../../../services/message.service';
import { queryParams } from '../../../../utils';
import { getMerchantBalancesAction } from '../../../balances/merchant-balance.actions';
import {
  getCurrentRebalancingGroupAction,
  getCurrentRebalancingGroupErrorAction,
  getCurrentRebalancingGroupSuccessAction,
  postRebalancingCorrectionTransactionAction,
  postRebalancingCorrectionTransactionErrorAction,
  postRebalancingCorrectionTransactionSuccessAction,
} from './rebalancing.actions';

@Injectable()
export class RebalancingEffects {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiService);
  private readonly messageService = inject(MessageService);

  getCurrentRebalancingGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentRebalancingGroupAction),
      switchMap(({ paging, groupId }) =>
        this.api.get(`/admin/merchants/rebalancing-group/${groupId}${queryParams({ ...paging })}`).pipe(
          map((rebalancingGroupRes) => getCurrentRebalancingGroupSuccessAction({ rebalancingGroupRes })),
          catchError((errors) => of(getCurrentRebalancingGroupErrorAction({ errors }))),
        ),
      ),
    ),
  );

  postRebalanceTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postRebalancingCorrectionTransactionAction),
      switchMap(({ merchantId, rebalanceTx }) =>
        this.api.post(`/admin/merchants/${merchantId}/rebalancing-correction-transaction`, rebalanceTx).pipe(
          map(() => {
            this.messageService.success('Merchant was successfully rebalanced!');
            return postRebalancingCorrectionTransactionSuccessAction({
              senderId: merchantId,
              recipientId: rebalanceTx.merchantRecipientHashId,
            });
          }),
          catchError(({ errors }) => {
            this.messageService.showErrors(errors, 'Could not rebalance merchant!');
            return of(postRebalancingCorrectionTransactionErrorAction({ errors }));
          }),
        ),
      ),
    ),
  );

  refreshSenderBalancesOnRebalanceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postRebalancingCorrectionTransactionSuccessAction),
      map(({ senderId }) => getMerchantBalancesAction({ merchantId: senderId })),
    ),
  );
}
