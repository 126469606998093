/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { SelectedPaymentMethodTo } from '../models/api/invoice.model';
import { CryptoPriceTo } from '../models/api/prepared-state-currency.model';

export enum InvoicePreparedActionTypes {
  GetPreparedCurrencies = 'invoicePreparedCurrencies/GET_REQUEST',
  GetPreparedCurrenciesSuccess = 'invoicePreparedCurrencies/GET_REQUEST_SUCCESS',
  GetPreparedCurrenciesError = 'invoicePreparedCurrencies/GET_REQUEST_ERROR',

  UpdatePreparedInvoiceCurrency = 'invoicePreparedCurrencies/PATCH_REQUEST',
  UpdatePreparedInvoiceCurrencySuccess = 'invoicePreparedCurrencies/PATCH_SUCCESS',
  UpdatePreparedInvoiceCurrencyError = 'invoicePreparedCurrencies/PATCH_ERROR',
}

export const getPreparedCurrenciesAction = createAction(
  InvoicePreparedActionTypes.GetPreparedCurrencies,
  props<{ invoiceHashId: string }>(),
);

export const getPreparedCurrenciesSuccessAction = createAction(
  InvoicePreparedActionTypes.GetPreparedCurrenciesSuccess,
  props<{ currencies: CryptoPriceTo[] }>(),
);

export const getPreparedCurrenciesErrorAction = createAction(
  InvoicePreparedActionTypes.GetPreparedCurrenciesError,
  props<{ errors: ErrorModel[] }>(),
);

export const updatePreparedInvoiceCurrencyAction = createAction(
  InvoicePreparedActionTypes.UpdatePreparedInvoiceCurrency,
  props<{ invoiceId: string; selectedPaymentMethod: SelectedPaymentMethodTo }>(),
);

export const updatePreparedInvoiceCurrencySuccessAction = createAction(
  InvoicePreparedActionTypes.UpdatePreparedInvoiceCurrencySuccess,
);

export const updatePreparedInvoiceCurrencyErrorAction = createAction(
  InvoicePreparedActionTypes.UpdatePreparedInvoiceCurrencyError,
  props<{ error: ErrorModel[] }>(),
);
