import { createReducer, on } from '@ngrx/store';
import { PublicCryptoPaymentMethodTo } from '../../../models/api/crypto-payment-method.model';
import { StateModel } from '../../../models/auxiliary/state.model';
import {
  getCryptoPaymentMethodsPublicAction,
  getCryptoPaymentMethodsPublicActionError,
  getCryptoPaymentMethodsPublicActionSuccess,
} from './crypto-payment-methods-public.actions';

export type PublicCryptoPaymentMethodsState = StateModel<PublicCryptoPaymentMethodTo[]>;

const INITIAL_STATE: PublicCryptoPaymentMethodsState = {
  errors: null,
  data: null,
  initial: true,
};

export const cryptoPaymentMethodsPublicReducer = createReducer(
  INITIAL_STATE,
  on(getCryptoPaymentMethodsPublicAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getCryptoPaymentMethodsPublicActionSuccess, (state, { paymentMethods }) => ({
    data: paymentMethods,
    errors: null,
  })),
  on(getCryptoPaymentMethodsPublicActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
