import { UntypedFormControl } from '@angular/forms';

/**
 * Validates if FormControl contains whitespaces at the start or the end
 * @param control
 */
export function whitespaceTrimValidator(control: UntypedFormControl): { [key: string]: boolean } {
  const value = control.value;
  if (value === null) {
    return null;
  }

  const trimmed = value.trim();
  if (value !== trimmed) {
    return { whitespaces: true };
  } else {
    return null;
  }
}
