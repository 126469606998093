/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { FinancialReportTo } from '../models/api/financial-report.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum FinancialReportActionsTypes {
  GET_FINANCIAL_REPORT_INVOICES = 'financialReport/GET_INVOICES_REQUEST',
  GET_FINANCIAL_REPORT_INVOICES_SUCCESS = 'financialReport/GET_INVOICES_SUCCESS',
  GET_FINANCIAL_REPORT_INVOICES_ERROR = 'financialReport/GET_INVOICES_ERROR',
  GET_FINANCIAL_REPORT_PAYOUTS = 'financialReport/GET_PAYOUTS_REQUEST',
  GET_FINANCIAL_REPORT_PAYOUTS_SUCCESS = 'financialReport/GET_PAYOUTS_SUCCESS',
  GET_FINANCIAL_REPORT_PAYOUTS_ERROR = 'financialReport/GET_PAYOUTS_ERROR',
  GET_USER_STATS_REPORT = 'report/GET_USER_STATS_REPORT_REQUEST',
  GET_USER_STATS_REPORT_SUCCESS = 'report/GET_USER_STATS_REPORT_SUCCESS',
  GET_USER_STATS_REPORT_ERROR = 'report/GET_USER_STATS_REPORT_ERROR',
  POST_EXPORT_REQUEST = 'report/POST_EXPORT_REQUEST',
  POST_EXPORT_SUCCESS = 'report/POST_EXPORT_SUCCESS',
  POST_EXPORT_ERROR = 'report/POST_EXPORT_ERROR',
}

export const getFinancialReportInvoicesAction = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_INVOICES,
  props<{ pagination: PageRequestModel }>(),
);

export const getFinancialReportInvoicesActionSuccess = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_INVOICES_SUCCESS,
  props<{ financialReport: PagingModel<FinancialReportTo> }>(),
);

export const getFinancialReportInvoicesActionError = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_INVOICES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getFinancialReportPayoutsAction = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_PAYOUTS,
  props<{ pagination: PageRequestModel }>(),
);

export const getFinancialReportPayoutsActionSuccess = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_PAYOUTS_SUCCESS,
  props<{ financialReport: PagingModel<FinancialReportTo> }>(),
);

export const getFinancialReportPayoutsActionError = createAction(
  FinancialReportActionsTypes.GET_FINANCIAL_REPORT_PAYOUTS_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getUserStatsReportAction = createAction(
  FinancialReportActionsTypes.GET_USER_STATS_REPORT,
  props<{ pagination: PageRequestModel }>(),
);

export const getUserStatsReportActionSuccess = createAction(
  FinancialReportActionsTypes.GET_USER_STATS_REPORT_SUCCESS,
  props<{ financialReport: PagingModel<FinancialReportTo> }>(),
);

export const getUserStatsReportActionError = createAction(
  FinancialReportActionsTypes.GET_USER_STATS_REPORT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getReportingExportAction = createAction(
  FinancialReportActionsTypes.POST_EXPORT_REQUEST,
  props<{ filters: PageRequestFiltersModel; uuid: string }>(),
);

export const getReportingExportSuccessAction = createAction(FinancialReportActionsTypes.POST_EXPORT_SUCCESS);

export const getReportingExportErrorAction = createAction(
  FinancialReportActionsTypes.POST_EXPORT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
