import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  getFinancialReportInvoicesAction,
  getFinancialReportInvoicesActionError,
  getFinancialReportInvoicesActionSuccess,
  getFinancialReportPayoutsAction,
  getFinancialReportPayoutsActionError,
  getFinancialReportPayoutsActionSuccess,
  getReportingExportAction,
  getReportingExportErrorAction,
  getReportingExportSuccessAction,
  getUserStatsReportAction,
  getUserStatsReportActionError,
  getUserStatsReportActionSuccess,
} from '../actions/financial-reporting.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { FinancialReportType } from '../enums/financial-report-type';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';

export const FINANCIAL_REPORT_ENDPOINT = 'reporting/financial-report';
export const ACCOUNTING_REPORT_ENDPOINT = 'reporting/accounting';

@Injectable()
export class FinancialReportEffect {
  getInvoicesReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFinancialReportInvoicesAction),
      mergeMap(({ pagination }) =>
        this.api.get(this.url(pagination, FinancialReportType.INVOICE)).pipe(
          map((financialReport) => getFinancialReportInvoicesActionSuccess({ financialReport })),
          catchError((errors) => of(getFinancialReportInvoicesActionError(errors))),
        ),
      ),
    ),
  );

  getPayoutsReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFinancialReportPayoutsAction),
      mergeMap(({ pagination }) =>
        this.api.get(this.url(pagination, FinancialReportType.PAYOUT)).pipe(
          map((financialReport) => getFinancialReportPayoutsActionSuccess({ financialReport })),
          catchError((errors) => of(getFinancialReportPayoutsActionError(errors))),
        ),
      ),
    ),
  );

  getUserStatsReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserStatsReportAction),
      mergeMap(({ pagination }) =>
        this.api.get(this.url(pagination, FinancialReportType.MERCHANT)).pipe(
          map((financialReport) => getUserStatsReportActionSuccess({ financialReport })),
          catchError((errors) => of(getUserStatsReportActionError(errors))),
        ),
      ),
    ),
  );

  getExportAccountingReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingExportAction),
      switchMap(({ filters, uuid }) =>
        this.api.post(this.accountingExportUrl({ ...filters, uuid: uuid })).pipe(
          map((financialReport) => getReportingExportSuccessAction()),
          catchError((errors) => of(getReportingExportErrorAction(errors))),
        ),
      ),
    ),
  );

  url(pagination: any, reportType: FinancialReportType): string {
    return `/${ADMIN_ENDPOINT_PREFIX}/${FINANCIAL_REPORT_ENDPOINT}${queryParams({
      ...pagination,
      type: reportType,
    })}`;
  }

  accountingExportUrl(params: any): string {
    return `/${ADMIN_ENDPOINT_PREFIX}/${ACCOUNTING_REPORT_ENDPOINT}${queryParams(params)}`;
  }

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
