/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { AdminCryptoNetworkInputTo, AdminCryptoNetworkTo } from '../../models/api/crypto-network.model';
import { ErrorModel } from '../../models/api/error.model';

export enum CryptoNetworksActions {
  GET_CRYPTO_NETWORKS_LIST_REQUEST = 'cryptoNetworksList/GET_CRYPTO_NETWORKS_LIST_REQUEST',
  GET_CRYPTO_NETWORKS_LIST_SUCCESS = 'cryptoNetworksList/GET_CRYPTO_NETWORKS_LIST_SUCCESS',
  GET_CRYPTO_NETWORKS_LIST_ERROR = 'cryptoNetworksList/GET_CRYPTO_NETWORKS_LIST_ERROR',
  UPDATE_CRYPTO_NETWORK_REQUEST = 'cryptoNetworksList/UPDATE_CRYPTO_NETWORK_REQUEST',
  UPDATE_CRYPTO_NETWORK_SUCCESS = 'cryptoNetworksList/UPDATE_CRYPTO_NETWORK_SUCCESS',
  UPDATE_CRYPTO_NETWORK_ERROR = 'cryptoNetworksList/UPDATE_CRYPTO_NETWORK_ERROR',
}

export const getCryptoNetworksAction = createAction(CryptoNetworksActions.GET_CRYPTO_NETWORKS_LIST_REQUEST);

export const getCryptoNetworksActionSuccess = createAction(
  CryptoNetworksActions.GET_CRYPTO_NETWORKS_LIST_SUCCESS,
  props<{ networks: AdminCryptoNetworkTo[] }>(),
);

export const getCryptoNetworksActionError = createAction(
  CryptoNetworksActions.GET_CRYPTO_NETWORKS_LIST_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateCryptoNetworkAction = createAction(
  CryptoNetworksActions.UPDATE_CRYPTO_NETWORK_REQUEST,
  props<{ id: string; network: AdminCryptoNetworkInputTo }>(),
);

export const updateCryptoNetworkActionSuccess = createAction(CryptoNetworksActions.UPDATE_CRYPTO_NETWORK_SUCCESS);

export const updateCryptoNetworkActionError = createAction(
  CryptoNetworksActions.UPDATE_CRYPTO_NETWORK_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
