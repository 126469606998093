<bp-generic-dialog (closePressed)="closePressed.emit()">
  <div class="animate__appear-down" [ngClass]="isPlatformDialog ? 'dialog-header__platform' : 'dialog-header'">
    <div
      class="dialog-header-text"
      [ngClass]="{ 'dialog-header-text__platform': isPlatformDialog, 'dialog-header-text__with-button': headerButton }"
    >
      {{ dialogTitle }}
      <ng-container *ngTemplateOutlet="headerButton"></ng-container>
    </div>
  </div>
  <div class="animate__appear-down" [ngClass]="{ 'dialog-separator-top': !isPlatformDialog }"></div>
  <ng-content></ng-content>
</bp-generic-dialog>
