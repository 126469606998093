<form class="merchant-person-form" [formGroup]="form">
  <!-- https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill -->
  <div class="text-input-group col">
    <label for="firstName" class="label-text">First name</label>
    <input
      id="firstName"
      formControlName="firstName"
      type="text"
      placeholder="First name"
      autocomplete="given-name"
      class="confirmo-platform-input verification-input"
    />
    <ng-container *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
      <div class="confirmo-input-invalid-tooltip__no-prepend" *ngIf="firstName.errors?.required">
        First name is required
      </div>
      <div class="confirmo-input-invalid-tooltip__no-prepend" *ngIf="firstName.errors?.maxlength">
        First name is too long
      </div>
      <div
        class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
        *ngIf="containsWhitespaceAtStartOrEnd('firstName')"
      >
        Can't contain whitespace at the start or the end
      </div>
    </ng-container>
  </div>

  <div class="text-input-group col">
    <label for="lastName" class="label-text">Surname</label>
    <input
      id="lastName"
      formControlName="lastName"
      type="text"
      placeholder="Surname"
      autocomplete="family-name"
      class="confirmo-platform-input verification-input"
    />
    <ng-container *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
      <div class="confirmo-input-invalid-tooltip__no-prepend" *ngIf="lastName.errors?.required">
        Surname is required
      </div>
      <div class="confirmo-input-invalid-tooltip__no-prepend" *ngIf="lastName.errors?.maxlength">
        Surname is too long
      </div>
      <div
        class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
        *ngIf="containsWhitespaceAtStartOrEnd('lastName')"
      >
        Can't contain whitespace at the start or the end
      </div>
    </ng-container>
  </div>
  <div class="text-input-group col">
    <label for="birthDate" class="label-text">Date of birth</label>
    <input
      id="birthDate"
      formControlName="birthDate"
      type="date"
      autocomplete="bday"
      [max]="minimalBirthDate | bpMomentDate: 'YYYY-MM-DD'"
      class="date-input confirmo-platform-input verification-input"
    />
    <ng-container *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
      <div class="confirmo-input-invalid-tooltip__no-prepend" *ngIf="birthDate.errors?.required">
        Date of birth is required
      </div>
      <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="birthDate.errors?.maxDate">
        Maximum date is {{ minimalBirthDate | bpMomentDate }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="displayFlags" formGroupName="flags" class="checkbox-wrapper">
    <div class="confirmo-checkbox-wrapper margin-bottom">
      <bp-platform-checkbox label="Politically exposed person" class="checkbox-text">
        <input type="checkbox" class="confirmo-checkbox-input" formControlName="pep" />
      </bp-platform-checkbox>
    </div>
    <div class="confirmo-checkbox-wrapper margin-bottom">
      <bp-platform-checkbox label="Ultimate Beneficiary" class="checkbox-text">
        <input type="checkbox" class="confirmo-checkbox-input" formControlName="ubo" />
      </bp-platform-checkbox>
    </div>
    <div class="confirmo-checkbox-wrapper margin-bottom">
      <bp-platform-checkbox label="U.S. person / citizen" class="checkbox-text">
        <input type="checkbox" class="confirmo-checkbox-input" formControlName="us" />
      </bp-platform-checkbox>
    </div>
  </div>
</form>
