/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { InvoicePageFiltersModel } from './invoice-page-filters.model';

export enum InvoicePageFiltersActions {
  GET = 'invoicePageFilters/GET',
  UPDATE = 'invoicePageFilters/UPDATE',
}

export const getInvoicePageFiltersAction = createAction(InvoicePageFiltersActions.GET);

export const updateInvoicePageFiltersAction = createAction(
  InvoicePageFiltersActions.UPDATE,
  props<{ filters: InvoicePageFiltersModel }>(),
);
