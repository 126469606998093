import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryTo } from '../models/api/country.model';
import { ApiService } from './api.service';
import { MessageService } from './message.service';

const IP_INFO_USER_API_URL = '/countries/ip-info';

@Injectable()
export class IpInfoService {
  constructor(
    private api: ApiService,
    private messageService: MessageService,
  ) {}

  get countryCode(): Observable<string> {
    return this.api.get(IP_INFO_USER_API_URL).pipe(map((response) => response.countryCode));
  }

  setCurrentCountryCodeToSelector(selector: AbstractControl, countries: CountryTo[]): void {
    if (!selector.value) {
      this.countryCode.subscribe(
        (countryCode) => {
          if (countries.some((country) => country.alpha2 === countryCode)) {
            selector.setValue(selector.value || countryCode);
          }
        },
        () => {
          this.messageService.error('IP info fetching error');
        },
      );
    }
  }
}
