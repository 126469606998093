import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { PaymentButtonModel, PaymentButtonPublicInfo } from '../models/api/payment-button.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum PaymentButtonActionTypes {
  GetPaymentButtonPage = 'paymentButtonPage/GET_PAYMENT_BUTTON_PAGE',
  GetPaymentButtonPageSuccess = 'paymentButtonPage/GET_PAYMENT_BUTTON_PAGE_SUCCESS',
  GetPaymentButtonPageError = 'paymentButtonPage/GET_PAYMENT_BUTTON_PAGE_ERROR',

  GetPaymentButtonDetail = 'paymentButton/GET_REQUEST',
  GetPaymentButtonDetailSuccess = 'paymentButton/GET_SUCCESS',
  GetPaymentButtonDetailError = 'paymentButton/GET_ERROR',

  GetPaymentButtonPublicInfo = 'paymentButton/GET_PUBLIC_INFO_REQUEST',
  GetPaymentButtonPublicInfoSuccess = 'paymentButton/GET_PUBLIC_INFO_SUCCESS',
  GetPaymentButtonPublicInfoError = 'paymentButton/GET_PUBLIC_INFO_ERROR',

  CreatePaymentButton = 'paymentButton/POST_REQUEST',
  CreatePaymentButtonSuccess = 'paymentButton/POST_SUCCESS',
  CreatePaymentButtonError = 'paymentButton/POST_ERROR',

  DeletePaymentButton = 'paymentButton/DELETE_REQUEST',
  DeletePaymentButtonSuccess = 'paymentButton/DELETE_SUCCESS',
  DeletePaymentButtonError = 'paymentButton/DELETE_ERROR',

  InitializePaymentButtonForm = 'paymentButton/INITIALIZE_FORM',
}

export const getPaymentButtonDetailAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonDetail,
  props<{ id: string }>(),
);

export const getPaymentButtonDetailSuccessAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonDetailSuccess,
  props<{ button: PaymentButtonModel }>(),
);

export const getPaymentButtonDetailErrorAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonDetailError,
  props<{ errors: ErrorModel[] }>(),
);

export const getPaymentButtonPublicInfoAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPublicInfo,
  props<{ hashCode: string }>(),
);

export const getPaymentButtonPublicInfoSuccessAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPublicInfoSuccess,
  props<{ publicInfo: PaymentButtonPublicInfo }>(),
);

export const getPaymentButtonPublicInfoErrorAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPublicInfoError,
  props<{ errors: ErrorModel[] }>(),
);

export const createPaymentButtonAction = createAction(
  PaymentButtonActionTypes.CreatePaymentButton,
  props<{ button: PaymentButtonModel }>(),
);

export const createPaymentButtonSuccessAction = createAction(
  PaymentButtonActionTypes.CreatePaymentButtonSuccess,
  props<{ button: PaymentButtonModel }>(),
);

export const createPaymentButtonErrorAction = createAction(
  PaymentButtonActionTypes.CreatePaymentButtonError,
  props<{ errors: ErrorModel[] }>(),
);

export const deletePaymentButtonAction = createAction(
  PaymentButtonActionTypes.DeletePaymentButton,
  props<{ button: PaymentButtonModel }>(),
);

export const deletePaymentButtonSuccessAction = createAction(
  PaymentButtonActionTypes.DeletePaymentButtonSuccess,
  props<{ button: PaymentButtonModel }>(),
);

export const deletePaymentButtonErrorAction = createAction(
  PaymentButtonActionTypes.DeletePaymentButtonError,
  props<{ errors: ErrorModel[] }>(),
);

export const getPaymentButtonPageAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPage,
  props<{ paging: PageRequestModel; filters: PageRequestFiltersModel }>(),
);

export const getPaymentButtonPageSuccessAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPageSuccess,
  props<{ paymentButtonPage: PagingModel<PaymentButtonModel> }>(),
);

export const getPaymentButtonPageErrorAction = createAction(
  PaymentButtonActionTypes.GetPaymentButtonPageError,
  props<{ errors: ErrorModel[] }>(),
);

export const initializePaymentButtonFormAction = createAction(PaymentButtonActionTypes.InitializePaymentButtonForm);
