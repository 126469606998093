/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { LedgerModel } from '../models/api/ledger.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum LedgerActionTypes {
  GetLedger = 'ledger/GET_REQUEST',
  GetLedgerSuccess = 'ledger/GET_SUCCESS',
  GetLedgerError = 'ledger/GET_ERROR',
  GetLedgerForSettlement = 'settlement-ledger/GET_REQUEST',
  GetLedgerAsAdmin = 'ledgerAdmin/GET_REQUEST',
  PostExportRequest = 'ledgerAdmin/POST_EXPORT_REQUEST',
  PostExportSuccess = 'ledgerAdmin/POST_EXPORT_SUCCESS',
  PostExportError = 'ledgerAdmin/POST_EXPORT_ERROR',
}

export const getLedgerForSettlementAction = createAction(
  LedgerActionTypes.GetLedgerForSettlement,
  props<{ settlementId: string; paging: PageRequestModel }>(),
);

export const getLedgerAction = createAction(
  LedgerActionTypes.GetLedger,
  props<{ paging: PageRequestModel; filters: PageRequestFiltersModel }>(),
);

export const getLedgerSuccessAction = createAction(
  LedgerActionTypes.GetLedgerSuccess,
  props<{ ledger: PagingModel<LedgerModel> }>(),
);

export const getLedgerErrorAction = createAction(LedgerActionTypes.GetLedgerError, props<{ errors: ErrorModel[] }>());

export const getLedgerAsAdminAction = createAction(
  LedgerActionTypes.GetLedgerAsAdmin,
  props<{ merchantHashId: string; paging: PageRequestModel; filters: PageRequestFiltersModel }>(),
);

export const getLedgerExportAction = createAction(
  LedgerActionTypes.PostExportRequest,
  props<{ paging: PageRequestModel; filters: PageRequestFiltersModel; isAdmin: boolean; uuid: string }>(),
);

export const getLedgerExportSuccessAction = createAction(LedgerActionTypes.PostExportSuccess);

export const getLedgerExportErrorAction = createAction(
  LedgerActionTypes.PostExportError,
  props<{ errors: ErrorModel[] }>(),
);
