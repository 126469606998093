import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  getCryptoNetworksAction,
  getCryptoNetworksActionError,
  getCryptoNetworksActionSuccess,
  updateCryptoNetworkAction,
  updateCryptoNetworkActionError,
  updateCryptoNetworkActionSuccess,
} from './crypto-networks.actions';

export const CRYPTO_NETWORKS_ENDPOINT = '/admin/crypto-networks';

@Injectable()
export class CryptoNetworksEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoNetworksAction),
      mergeMap(() =>
        this.api.get(CRYPTO_NETWORKS_ENDPOINT).pipe(
          map((networks) => getCryptoNetworksActionSuccess({ networks })),
          catchError((errors) => of(getCryptoNetworksActionError(errors))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCryptoNetworkAction),
      mergeMap(({ id, network }) =>
        this.api.put(`${CRYPTO_NETWORKS_ENDPOINT}/${id}`, network).pipe(
          map(() => updateCryptoNetworkActionSuccess()),
          catchError((errors) => of(updateCryptoNetworkActionError(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
