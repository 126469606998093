import { createAction, props } from '@ngrx/store';
import { PayoutPageFiltersModel } from './payout-page-filters.model';

export enum PayoutPageFiltersActions {
  GET = 'payoutPageFilters/GET',
  UPDATE = 'payoutPageFilters/UPDATE',
}

export const getPayoutPageFiltersAction = createAction(PayoutPageFiltersActions.GET);

export const updatePayoutPageFiltersAction = createAction(
  PayoutPageFiltersActions.UPDATE,
  props<{ filters: PayoutPageFiltersModel }>(),
);
