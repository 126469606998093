import { LanguageCode } from './enums/language.enum';

export const normalizeToSupportedLocale = (culture: LanguageCode | string): LanguageCode | null => {
  const locale = culture.toLowerCase();
  if (locale === 'zh-hk' || locale === 'zh-tw' || locale === 'zh-mo') {
    return LanguageCode.CHINESE_TRADITIONAL;
  }

  // other cases should be handled with base - first 2 letters of the locale
  const localeBase = locale.substring(0, 2);

  // fallback for all other known/unknown Chinese locales to simplified (zh, zh-CN, zh-SG, zh-*)
  if (localeBase === 'zh') {
    return LanguageCode.CHINESE_SIMPLIFIED;
  }

  // set default Czech lang for sk. Remove this when Slovak translations are added!
  if (localeBase === 'sk') {
    return LanguageCode.CZECH;
  }

  if (Object.values(LanguageCode).some((l) => l === localeBase)) {
    return localeBase as LanguageCode;
  }

  // return null and NOT English for unsupported locale – handle fallbacks where necessary
  return null;
};
