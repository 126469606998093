import { createReducer, on } from '@ngrx/store';
import {
  getPublicInvoicePreparedCurrencyCodeAction,
  setPublicInvoicePreparedCurrencyCodeAction,
} from './public-invoice-prepared-currency-code.actions';

export type PublicInvoicePreparedCurrencyCodeState = {
  currencyCode: string | null;
};

const INITIAL_STATE: PublicInvoicePreparedCurrencyCodeState = {
  currencyCode: null,
};

export const publicInvoicePreparedCurrencyCodeReducer = createReducer(
  INITIAL_STATE,
  on(getPublicInvoicePreparedCurrencyCodeAction, (state) => ({ ...state })),
  on(setPublicInvoicePreparedCurrencyCodeAction, (state, { currencyCode }) => ({ ...state, currencyCode })),
);
