import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export const DIALOG_CONFIG_WITHOUT_AUTOFOCUS: MatDialogConfig = {
  panelClass: ['mat-dialog-zero-padding', 'animate__appear-up'],
  backdropClass: 'dialog-backdrop-purple',
  autoFocus: false,
  maxHeight: '90vh',
  disableClose: true,
  closeOnNavigation: true,
};
export const DIALOG_CONFIG_WITHOUT_AUTOFOCUS_GREY_BG: MatDialogConfig = {
  panelClass: ['mat-dialog-zero-padding', 'animate__appear-up'],
  backdropClass: 'dialog-backdrop-grey',
  autoFocus: false,
  maxHeight: '100vh',
  disableClose: true,
  closeOnNavigation: true,
};

export const DIALOG_CONFIG_WITH_AUTOFOCUS: MatDialogConfig = {
  panelClass: ['mat-dialog-zero-padding', 'animate__appear-up'],
  backdropClass: 'dialog-backdrop-purple',
  maxHeight: '90vh',
  disableClose: true,
  closeOnNavigation: true,
};

export const DIALOG_CONFIG_WITH_AUTOFOCUS_GREY_BG: MatDialogConfig = {
  panelClass: ['mat-dialog-zero-padding', 'animate__appear-up'],
  backdropClass: 'dialog-backdrop-grey',
  maxHeight: '100vh',
  disableClose: true,
  closeOnNavigation: true,
};

export function closeDialogWithAnimation(dialogRef: MatDialogRef<any>, data?: any): void {
  dialogRef.removePanelClass('animate__appear-up');
  dialogRef.addPanelClass('animate__disappear-down');
  setTimeout(() => {
    dialogRef.close(data);
  }, 350);
}

export const addCloseDialogWithAnimationOnBackdropClickSubscriber = (dialogRef: MatDialogRef<any>): void => {
  dialogRef.backdropClick().subscribe(() => closeDialogWithAnimation(dialogRef));
};
