import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getDashboardChart,
  getDashboardChartError,
  getDashboardChartSuccess,
} from '../actions/dashboard-chart.actions';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';

export const DASHBOARD_CHART_ENDPOINT = 'chart/invoice';

@Injectable()
export class DashboardChartEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardChart),
      mergeMap(({ chartCurrency, chartInterval }) =>
        this.api.get(this.url(chartCurrency, chartInterval)).pipe(
          map((dashboardChartData) => getDashboardChartSuccess({ dashboardChartData })),
          catchError((errors) => of(getDashboardChartError(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  url(chartCurrency: string, chartInterval: string): string {
    return `/${DASHBOARD_CHART_ENDPOINT}${queryParams({
      chartCurrency: chartCurrency,
      chartInterval: chartInterval,
    })}`;
  }
}
