import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmlWizardLeaveDialogComponent } from '../../platform/merchant/aml-wizard/aml-wizard-leave-dialog/aml-wizard-leave-dialog.component';
import { DIALOG_CONFIG_WITHOUT_AUTOFOCUS } from '../dialogs-utils';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class MerchantVerificationDocumentsGuard implements CanDeactivate<ComponentCanDeactivate> {
  isDialogOpen = false;
  result: Observable<boolean>;

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // guarantees this dialog will open exactly once
    if (this.isDialogOpen) {
      return this.result;
    }

    if (component.canDeactivate()) {
      return true;
    }
    this.isDialogOpen = true;

    // NOTE: this dialog will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    this.result = this.openDialog()
      .afterClosed()
      .pipe(
        map((response: boolean) => {
          this.isDialogOpen = false;
          return response;
        }),
      );

    return this.result;
  }

  constructor(private dialog: MatDialog) {}

  openDialog(): MatDialogRef<AmlWizardLeaveDialogComponent> {
    return this.dialog.open(AmlWizardLeaveDialogComponent, DIALOG_CONFIG_WITHOUT_AUTOFOCUS);
  }
}
