/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';

export enum MerchantApiVersionActions {
  GET_API_VERSION_REQUEST = 'merchantApiVersion/GET_API_VERSION_REQUEST',
  GET_API_VERSION_SUCCESS = 'merchantApiVersion/GET_API_VERSION_SUCCESS',
  GET_API_VERSION_ERROR = 'merchantApiVersion/GET_API_VERSION_ERROR',
}

export const getMerchantApiVersionAction = createAction(MerchantApiVersionActions.GET_API_VERSION_REQUEST);

export const getMerchantApiVersionActionSuccess = createAction(
  MerchantApiVersionActions.GET_API_VERSION_SUCCESS,
  props<{ version: number }>(),
);

export const getMerchantApiVersionActionError = createAction(
  MerchantApiVersionActions.GET_API_VERSION_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
