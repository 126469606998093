import { Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { distinctUntilKeyChanged, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AbstractComponent } from './shared/components/abstract.component';
import { GOOGLE_TAG_MANAGER_ID } from './shared/config/constants';
import { ConfigProfile } from './shared/enums/config-profile.enum';
import { UserModel } from './shared/models/api/user.model';
import { AppStateModel } from './shared/models/auxiliary/app-state.model';
import { selectUser } from './shared/selectors/user.selector';
import { AppConfigService } from './shared/services/app-config.service';
import { CookiesService } from './shared/services/cookies.service';
import { LocaleService } from './shared/services/locale.service';
import { SeoService } from './shared/services/seo.service';

// Declare GTM dataLayer array.
declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

@Component({
  selector: 'bp-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent {
  private readonly router = inject(Router);
  private readonly appConfigService = inject(AppConfigService);
  // @ts-expect-error need to call constructor of LocaleService to load locales
  private readonly localeService = inject(LocaleService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly seoService = inject(SeoService);
  private readonly store = inject(Store<AppStateModel>);
  private readonly cookiesService = inject(CookiesService);

  constructor() {
    super();
    this.cookiesService.setDefaultConsent();
    this.cookiesService.initCookieYes();
    this.initGTM();
    this.initUserTracking();

    const { profile } = this.appConfigService.config;

    Sentry.init({
      dsn: 'https://cafada83a75048ab962bededed830f83@o877649.ingest.sentry.io/5828506',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracePropagationTargets: ['localhost', /(dev|mdev|test|uat)-bpay\.cfmin\.eu/],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new CaptureConsole({
          levels: ['error'],
        }) as any,
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: profile === ConfigProfile.PROD ? 0.1 : 1.0,
      environment: profile,
    });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        // needed for smooth-scroll working on landing pages
        const body = document.getElementsByTagName('body')[0];
        if (!this.router.url.includes('platform') && !this.router.url.includes('public')) {
          body.setAttribute('style', 'height: fit-content');
          // needed only for landing pages which have og:url defined
          this.seoService.updateCanonicalUrl(data['meta']['og:url']);
        } else {
          body.removeAttribute('style');
        }

        this.seoService.updateMetaTags(data['meta']);
      });

    /**
     * Automatically scrolls to the page fragment, if it is included in the url.
     * The way <a [routerLink]="<link>" [fragment]="<fragment>"></a>
     * doesn't work on landing pages because of delayed elements appearance when animations are turned on
     */
    this.activatedRoute.fragment.pipe(takeUntil(this.unsubscribe$)).subscribe((fragment: string) => {
      if (fragment) {
        setTimeout(() => {
          const article = document.getElementById(fragment);
          if (article) {
            article.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
        }, 100);
      }
    });
  }

  private initGTM(): void {
    window.dataLayer.push({
      'gtm.start': Date.now(),
      event: 'gtm.js',
    });
    const gtmScript = document.createElement('script');
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`;
    document.head.append(gtmScript);
  }

  private initUserTracking(): void {
    this.store
      .select(selectUser)
      .pipe(
        map(({ data }) => data),
        filter((data): data is UserModel => data != null),
        distinctUntilKeyChanged('id'),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((user) => {
        window.dataLayer.push({
          user_id: user.id,
          email: user.email,
          role: user.role,
          country: user.country,
          merchant_id: user.merchantId,
        });
      });
  }
}
