/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { PaymentSettingsModel } from '../models/api/payment.settings.model';

export enum PaymentSettingsActionTypes {
  GetPaymentSettings = 'paymentSettings/GET_REQUEST',
  GetPaymentSettingsSuccess = 'paymentSettings/GET_SUCCESS',
  GetPaymentSettingsError = 'paymentSettings/GET_ERROR',
  UpdatePaymentSettings = 'paymentSettings/UPDATE_REQUEST',
  UpdatePaymentSettingsAdmin = 'paymentSettingsAdmin/UPDATE_ADMIN_REQUEST',
  UpdatePaymentSettingsSuccess = 'paymentSettings/UPDATE_SUCCESS',
  UpdatePaymentSettingsError = 'paymentSettings/UPDATE_ERROR',
  GetPaymentSettingsAdmin = 'paymentSettingsAdmin/GET_ADMIN_REQUEST',
  GetDefaultPaymentSettings = 'paymentSettings/GET_DEFAULT_REQUEST',
  GetDefaultPaymentSettingsSuccess = 'paymentSettings/GET_DEFAULT_SUCCESS',
  GetDefaultPaymentSettingsError = 'paymentSettings/GET_DEFAULT_ERROR',
}

export const getPaymentSettingsAction = createAction(PaymentSettingsActionTypes.GetPaymentSettings);

export const getPaymentSettingsAdminAction = createAction(
  PaymentSettingsActionTypes.GetPaymentSettingsAdmin,
  props<{ merchantHashId: string }>(),
);

export const getPaymentSettingsSuccessAction = createAction(
  PaymentSettingsActionTypes.GetPaymentSettingsSuccess,
  props<{ settings: PaymentSettingsModel }>(),
);

export const getPaymentSettingsErrorAction = createAction(
  PaymentSettingsActionTypes.GetPaymentSettingsError,
  props<{ errors: ErrorModel[] }>(),
);

export const updatePaymentSettingsAction = createAction(
  PaymentSettingsActionTypes.UpdatePaymentSettings,
  props<{ settings: PaymentSettingsModel }>(),
);

export const updatePaymentSettingsAdminAction = createAction(
  PaymentSettingsActionTypes.UpdatePaymentSettingsAdmin,
  props<{ settings: PaymentSettingsModel; merchantHashId: string }>(),
);

export const updatePaymentSettingsSuccessAction = createAction(
  PaymentSettingsActionTypes.UpdatePaymentSettingsSuccess,
  props<{ settings: PaymentSettingsModel }>(),
);

export const updatePaymentSettingsErrorAction = createAction(
  PaymentSettingsActionTypes.UpdatePaymentSettingsError,
  props<{ errors: ErrorModel[] }>(),
);

export const getDefaultPaymentSettingsAction = createAction(PaymentSettingsActionTypes.GetDefaultPaymentSettings);

export const getDefaultPaymentSettingsSuccessAction = createAction(
  PaymentSettingsActionTypes.GetDefaultPaymentSettingsSuccess,
  props<{ settings: PaymentSettingsModel }>(),
);

export const getDefaultPaymentSettingsErrorAction = createAction(
  PaymentSettingsActionTypes.GetDefaultPaymentSettingsError,
  props<{ errors: ErrorModel[] }>(),
);
