import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import {
  IdcheckGenerationDataModel,
  IdcheckVerificationDataModel,
  IdenfyEmailModel,
} from '../models/api/merchant-profile/idcheck-data.model';
import { PersonModel } from '../models/api/merchant-profile/person-model';

export enum MerchantVerificationIdenfyActionsTypes {
  GET_SELECTED_PERSON = 'merchantVerificationIdenfy/GET_SELECTED_PERSON',
  GET_SELECTED_PERSON_SUCCESS = 'merchantVerificationIdenfy/GET_SELECTED_PERSON_SUCCESS',
  GET_SELECTED_PERSON_ERROR = 'merchantVerificationIdenfy/GET_SELECTED_PERSON_ERROR',
  REFRESH_STATE_ON_INTERVAL = 'merchantVerificationIdenfy/REFRESH_STATE_ON_INTERVAL',
  STOP_REFRESH_STATE_ON_INTERVAL = 'merchantVerificationIdenfy/STOP_REFRESH_STATE_ON_INTERVAL',
  SET_PERSON = 'merchantVerificationIdenfy/SET_PERSON',
  SET_PERSON_SUCCESS = 'merchantVerificationIdenfy/SET_PERSON_SUCCESS',
  SET_PERSON_ERROR = 'merchantVerificationIdenfy/SET_PERSON_ERROR',
  CREATE_PDF_ADMIN = 'merchantVerificationIdenfy/CREATE_PDF_ADMIN',
  CREATE_PDF_ADMIN_SUCCESS = 'merchantVerificationIdenfy/CREATE_PDF_ADMIN_SUCCESS',
  CREATE_PDF_ADMIN_ERROR = 'merchantVerificationIdenfy/CREATE_PDF_ADMIN_ERROR',
  UPDATE_STATE_ADMIN = 'merchantVerificationIdenfy/UPDATE_STATE_ADMIN',
  UPDATE_STATE_ADMIN_SUCCESS = 'merchantVerificationIdenfy/UPDATE_STATE_ADMIN_SUCCESS',
  UPDATE_STATE_ADMIN_ERROR = 'merchantVerificationIdenfy/UPDATE_STATE_ADMIN_ERROR',
  GENERATE_VERIFICATION = 'merchantVerificationIdenfy/GENERATE_VERIFICATION',
  GENERATE_VERIFICATION_SUCCESS = 'merchantVerificationIdenfy/GENERATE_VERIFICATION_SUCCESS',
  GENERATE_VERIFICATION_ERROR = 'merchantVerificationIdenfy/GENERATE_VERIFICATION_ERROR',
  SEND_LINK_TO_EMAIL = 'merchantVerificationIdenfy/SEND_LINK_TO_EMAIL',
  SEND_LINK_TO_EMAIL_SUCCESS = 'merchantVerificationIdenfy/SEND_LINK_TO_EMAIL_SUCCESS',
  SEND_LINK_TO_EMAIL_ERROR = 'merchantVerificationIdenfy/SEND_LINK_TO_EMAIL_ERROR',
}

export const getMerchantVerificationPersonAction = createAction(
  MerchantVerificationIdenfyActionsTypes.GET_SELECTED_PERSON,
  props<{ merchantId: string }>(),
);

export const getMerchantVerificationPersonActionSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.GET_SELECTED_PERSON_SUCCESS,
  props<{ person: PersonModel }>(),
);

export const getMerchantVerificationPersonActionError = createAction(
  MerchantVerificationIdenfyActionsTypes.GET_SELECTED_PERSON_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const refreshStateOnIntervalAction = createAction(
  MerchantVerificationIdenfyActionsTypes.REFRESH_STATE_ON_INTERVAL,
  props<{ merchantId: string }>(),
);

export const stopRefreshStateOnIntervalAction = createAction(
  MerchantVerificationIdenfyActionsTypes.STOP_REFRESH_STATE_ON_INTERVAL,
);

export const setMerchantVerificationPerson = createAction(
  MerchantVerificationIdenfyActionsTypes.SET_PERSON,
  props<{ hashId: string }>(),
);

export const setMerchantVerificationPersonSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.SET_PERSON_SUCCESS,
);

export const setMerchantVerificationPersonError = createAction(
  MerchantVerificationIdenfyActionsTypes.SET_PERSON_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const createMerchantVerificationPdfAdmin = createAction(
  MerchantVerificationIdenfyActionsTypes.CREATE_PDF_ADMIN,
  props<{ merchantId: string }>(),
);

export const createMerchantVerificationPdfAdminSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.CREATE_PDF_ADMIN_SUCCESS,
);

export const createMerchantVerificationPdfAdminError = createAction(
  MerchantVerificationIdenfyActionsTypes.CREATE_PDF_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantVerificationStateAdmin = createAction(
  MerchantVerificationIdenfyActionsTypes.UPDATE_STATE_ADMIN,
  props<{ merchantId: string }>(),
);

export const updateMerchantVerificationStateAdminSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.UPDATE_STATE_ADMIN_SUCCESS,
);

export const updateMerchantVerificationStateAdminError = createAction(
  MerchantVerificationIdenfyActionsTypes.UPDATE_STATE_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const generateMerchantVerification = createAction(
  MerchantVerificationIdenfyActionsTypes.GENERATE_VERIFICATION,
  props<{ idcheckGenerationData: IdcheckGenerationDataModel }>(),
);

export const generateMerchantVerificationSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.GENERATE_VERIFICATION_SUCCESS,
  props<{ idcheckVerificationData: IdcheckVerificationDataModel }>(),
);

export const generateMerchantVerificationError = createAction(
  MerchantVerificationIdenfyActionsTypes.GENERATE_VERIFICATION_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const sendVerificationLinkToEmail = createAction(
  MerchantVerificationIdenfyActionsTypes.SEND_LINK_TO_EMAIL,
  props<{ merchantId: string; email: IdenfyEmailModel }>(),
);

export const sendVerificationLinkToEmailSuccess = createAction(
  MerchantVerificationIdenfyActionsTypes.SEND_LINK_TO_EMAIL_SUCCESS,
);

export const sendVerificationLinkToEmailError = createAction(
  MerchantVerificationIdenfyActionsTypes.SEND_LINK_TO_EMAIL_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
