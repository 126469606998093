import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WEEKDAY_NAMES } from '../../constants';
import { PayoutIntervalModel } from '../../models/api/payout-interval.model';
import { SelectItemModel } from '../../models/auxiliary/select-item.model';
import { getMonthDay } from '../../utils';

const MAX_MONTHDAY = 31;

@Component({
  selector: 'bp-payout-interval-input',
  templateUrl: './payout-interval-input.component.html',
  styleUrls: ['./payout-interval-input.component.scss'],
})
export class PayoutIntervalInputComponent implements OnChanges {
  @Input() settings: PayoutIntervalModel;
  @Output() intervalChange: EventEmitter<any> = new EventEmitter();

  readonly payoutTypes: SelectItemModel[] = ['Daily', 'Weekly', 'Monthly'].map((name) => ({
    label: name,
    value: name.toLowerCase(),
  }));
  days: SelectItemModel[] = [];
  payoutTypeValue: SelectItemModel;
  dayValue: SelectItemModel;

  ngOnChanges(): void {
    this.setDays(this.settings.type);
    this.setSettings(this.settings);
  }

  setDays(payoutType: string): void {
    switch (payoutType) {
      case 'weekly':
        this.days = WEEKDAY_NAMES.map((name, index) => ({ label: name, value: index + 1 }));
        break;
      case 'monthly':
        this.days = Array(MAX_MONTHDAY)
          .fill(null)
          .map((_, index) => ({
            value: index + 1,
            label: getMonthDay(index + 1),
          }));
        break;
      default:
        this.days = [];
    }
  }

  private get currentInterval(): PayoutIntervalModel {
    const payoutType = this.payoutTypeValue.value;
    const dayNumber = this.dayValue?.value;
    return {
      type: payoutType,
      day: payoutType !== 'daily' ? Number(dayNumber) : 1,
    };
  }

  payoutTypeChanged($event: SelectItemModel): void {
    this.setDays($event.value);
    this.dayValue = this.days ? this.days[0] : null;
    this.intervalChange.emit(this.currentInterval);
  }

  dayChanged($event: SelectItemModel): void {
    this.intervalChange.emit(this.currentInterval);
  }

  setSettings(settings: PayoutIntervalModel): void {
    this.payoutTypeValue = this.payoutTypes.filter((value) => value.value === settings.type)[0];
    this.dayValue = this.days.filter((value) => value.value === settings.day)[0];
  }
}
