import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CryptoAssetCode } from '../../enums/crypto-payment-methods/crypto-asset-code.enum';
import { CurrencyTo } from '../../models/api/currencyTo';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { BigDecimal, BigDecimalizable, RoundingModes } from '../../models/auxiliary/big-decimal';
import { MessageService } from '../../services/message.service';
import { getCurrencyListAction, getCurrencyListErrorAction } from './currency-list.actions';
import { selectCurrencyList } from './currency-list.selector';

export const FIAT_AMOUNT_DECIMALS = 4;
export const LEGACY_FIAT_AMOUNT_DECIMALS = 2;
export const LEGACY_CRYPTO_AMOUNT_DECIMALS = 8;

export const CRYPTO_AMOUNT_DECIMALS_LIST: { currencyCode: CryptoAssetCode; decimals: number }[] = [
  { currencyCode: CryptoAssetCode.USDC, decimals: 6 },
  { currencyCode: CryptoAssetCode.USDT, decimals: 6 },
  { currencyCode: CryptoAssetCode.BTC, decimals: 8 },
  { currencyCode: CryptoAssetCode.LTC, decimals: 8 },
  { currencyCode: CryptoAssetCode.ETH, decimals: 18 },
  { currencyCode: CryptoAssetCode.SOL, decimals: 9 },
  { currencyCode: CryptoAssetCode.TRX, decimals: 6 },
];

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(
    private store: Store<AppStateModel>,
    private messageService: MessageService,
    private actions: Actions,
  ) {
    this.store
      .pipe(
        select(selectCurrencyList),
        filter((state) => state.initial),
      )
      .subscribe(() => {
        this.store.dispatch(getCurrencyListAction());
      });
    this.actions.pipe(ofType(getCurrencyListErrorAction)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Currency List Error`);
    });
  }

  public getCurrencies(): Observable<CurrencyTo[]> {
    return this.store.pipe(
      select(selectCurrencyList),
      filter((state) => state != null && state.data != null),
      map((state) => state.data),
    );
  }

  public getCryptoCurrencies(): Observable<CurrencyTo[]> {
    return this.getCurrencies().pipe(map((currencies) => currencies.filter((c) => c.crypto)));
  }

  /**
   * Checks whether given currencyCode of settlementCurrency means that settlement currency is crypto
   * Differs from isCurrencyCrypto by allowing null to signal that currencyCode is unknown crypto
   * @param currencyCode Code of currency
   * @return Observable, which will yield true value when currencyCode is known currency code or null
   */
  public isSettlementCurrencyCrypto(currencyCode: string): Observable<boolean> {
    return currencyCode === null ? of(true) : this.isCurrencyCrypto(currencyCode);
  }

  /**
   * Check whether given code represents known currency
   * For special usages (like settlementCurrency - null signals unknown crypto), other functions should be used (isSettlementCurrencyCrypto)
   * @param currencyCode Code of currency
   * @return Observable, which will yield true value when currencyCode is known currency code (don't use for settlementCurrency)
   */
  public isCurrencyCrypto(currencyCode: string): Observable<boolean> {
    return this.getCryptoCurrencies().pipe(
      map((cryptoCurrencies) => cryptoCurrencies.map((c) => c.code).includes(currencyCode)),
    );
  }

  public getCryptoCurrencyDisplayDecimals(currencyCode: string): number {
    //Customer doesn't want to display more than 8 decimals
    //Some currencies have less than 8 decimals (USDC for example), that's why we are doing this calculation
    return Math.min(LEGACY_CRYPTO_AMOUNT_DECIMALS, this.getCryptoCurrencyDecimals(currencyCode));
  }

  /**
   * Get number of display decimals that should be displayed for given FIAT currency code
   *
   * @param currencyCode - Not used now, but might be used in the future for specific currency decimals
   *
   */
  public getFiatCurrencyDisplayDecimals(currencyCode: string): number {
    return FIAT_AMOUNT_DECIMALS;
  }

  public getFiatCurrencyDisplayDecimalsLegacy(): number {
    return LEGACY_FIAT_AMOUNT_DECIMALS;
  }

  public getCurrencyDisplayDecimals$(currencyCode: string, useLegacyFiatFormat: boolean = false): Observable<number> {
    return this.isCurrencyCrypto(currencyCode).pipe(
      map((isCrypto) => {
        if (isCrypto) {
          return this.getCryptoCurrencyDisplayDecimals(currencyCode);
        }

        return useLegacyFiatFormat
          ? this.getFiatCurrencyDisplayDecimalsLegacy()
          : this.getFiatCurrencyDisplayDecimals(currencyCode);
      }),
    );
  }

  public getAmountRoundedToCurrencyDisplayDecimals$(
    amount: BigDecimalizable,
    currencyCode: string,
    roundingMode: RoundingModes,
    stripTrailingZeros: boolean = true,
    useLegacyFiatFormat: boolean = false,
  ): Observable<BigDecimal> {
    return this.getCurrencyDisplayDecimals$(currencyCode, useLegacyFiatFormat).pipe(
      map((decimals) => {
        const roundedValue = BigDecimal.of(amount).round(decimals, roundingMode);
        return stripTrailingZeros ? roundedValue.stripTrailingZeros() : roundedValue;
      }),
    );
  }

  public getAmountRoundedToNearest$(
    amount: BigDecimalizable,
    currencyCode: string,
    stripTrailingZeros: boolean = true,
  ): Observable<BigDecimal> {
    return this.getCurrencyDisplayDecimals$(currencyCode).pipe(
      map((decimals) => {
        const roundedValue = BigDecimal.of(amount).round(decimals, RoundingModes.HALF_EVEN);
        return stripTrailingZeros ? roundedValue.stripTrailingZeros() : roundedValue;
      }),
    );
  }

  public getCryptoCurrencyDecimals(currencyCode: string): number {
    const specificDecimals = CRYPTO_AMOUNT_DECIMALS_LIST.find(
      (crypto) => crypto.currencyCode == currencyCode,
    )?.decimals;
    return specificDecimals ?? LEGACY_CRYPTO_AMOUNT_DECIMALS;
  }
}
