import { SupportRole } from './support.role';

export class ReadWriteRole extends SupportRole {
  canModifySettlementMethod(): boolean {
    return true;
  }

  canEditApiKeys(): boolean {
    return true;
  }

  canEditInvoiceSettings(): boolean {
    return true;
  }

  canModifyInvoiceExpiration(): boolean {
    return true;
  }

  canModifyAutoAcceptUnderpayment(): boolean {
    return true;
  }

  canModifyInvoiceExpirationAutoExtension(): boolean {
    return true;
  }

  canModifyCallbackPassword(): boolean {
    return true;
  }

  canAccessPublicProfileSettings(): boolean {
    return true;
  }

  canCreateInvoice(): boolean {
    return true;
  }

  canCreateEmailInvoice(): boolean {
    return true;
  }

  canCreatePaymentButton(): boolean {
    return true;
  }

  canDeletePaymentButton(): boolean {
    return true;
  }

  canCreateOsTransaction(): boolean {
    return true;
  }

  canEditCryptoPaymentMethod(): boolean {
    return true;
  }
}
