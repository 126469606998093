/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { MerchantBalanceModel } from '../../models/api/merchant-balance.model';

export enum merchantBalanceActions {
  GET_REQUEST = 'merchantBalance/GET_REQUEST',
  GET_SUCCESS = 'merchantBalance/GET_SUCCESS',
  GET_ERROR = 'merchantBalance/GET_ERROR',
}

export const getMerchantBalancesAction = createAction(
  merchantBalanceActions.GET_REQUEST,
  props<{ merchantId?: string }>(),
);

export const getMerchantBalancesSuccessAction = createAction(
  merchantBalanceActions.GET_SUCCESS,
  props<{ merchantBalances: MerchantBalanceModel[] }>(),
);

export const getMerchantBalancesErrorAction = createAction(
  merchantBalanceActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
