<div class="merchant-directors-list">
  <div class="back-arrow__container">
    <mat-icon class="back-arrow__button" (click)="stepBack()">keyboard_backspace</mat-icon>
  </div>
  <div class="wizard-step__header">
    <h1 class="bp-heading merchant-directors-list__heading">Add managing director(s)</h1>
    <p class="wizard-step__legend">
      Please add authorised director(s) secretary(ies) or person who is authorized to act on behalf of the company
    </p>
  </div>

  <div
    class="bp-card table-card merchant-directors-list__container"
    [ngClass]="{ 'merchant-directors-list__container-empty': isDirectorsListEmpty }"
  >
    <div class="merchant-directors-list__card-header merchant-directors-list__card-row">
      <button
        *ngIf="canEditDirectors"
        class="confirmo-button confirmo-button__white-blue extra-margin button-director"
        (click)="addDirector()"
      >
        Add a Director
      </button>
    </div>

    <div class="bp-table" *ngIf="!isDirectorsListEmpty; else emptyTemplate">
      <div *ngFor="let director of merchantDirectors" class="merchant-directors-list__card">
        <div class="merchant-directors-list__card-content">
          <div class="merchant-directors-list__card-row">
            <div class="merchant-directors-list__director-full-name">
              <ng-container
                *ngTemplateOutlet="
                  rowInfo;
                  context: { row: { value: director.firstName, cssClasses: ['director-name'] } }
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  rowInfo;
                  context: { row: { value: director.lastName, cssClasses: ['director-name', 'director-last-name'] } }
                "
              ></ng-container>
            </div>
          </div>
          <div class="merchant-directors-list__card-row">
            <span class="merchant-directors-list__label">Address:</span>
            <div class="merchant-directors-list__address">
              <ng-container
                *ngTemplateOutlet="
                  rowInfo;
                  context: { row: { value: director.street, cssClasses: ['address-street'] } }
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="rowInfo; context: { row: { value: director.zip, cssClasses: ['address-zip'] } }"
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="rowInfo; context: { row: { value: director.city, cssClasses: ['address-city'] } }"
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  rowInfo;
                  context: { row: { value: director.countryCode, cssClasses: ['address-country-code'] } }
                "
              ></ng-container>
            </div>
          </div>
          <div class="merchant-directors-list__card-row merchant-directors-list__card-row-no-space">
            <span class="merchant-directors-list__label">Phone:</span>
            <ng-container *ngTemplateOutlet="rowInfo; context: { row: { value: director.phone } }"></ng-container>
          </div>
        </div>

        <div
          *ngIf="canEditDirectors"
          class="merchant-directors-list__icons-container merchant-directors-list__col-icons"
        >
          <button
            type="button"
            matTooltip="Edit user"
            class="confirmo-button confirmo-button__yellow user-list-button__edit"
            (click)="editDirector(director)"
          >
            <img src="assets/tables/icon-edit-paper.svg" class="user-list-button__edit__icon" alt="Edit user" />
          </button>
          <button
            type="button"
            class="confirmo-button confirmo-button__pink user-list-button__delete"
            matTooltip="Delete user"
            (click)="deleteDirector(director)"
          >
            <img src="assets/tables/trash.svg" class="user-list-button__delete__icon" alt="Delete user" />
          </button>
        </div>
      </div>

      <ng-template #rowInfo let-row="row">
        <div
          class="merchant-directors-list__value"
          [ngClass]="row.cssClasses"
          [bpTooltipOnEllipsis]="row.value"
          [matTooltipClass]="'row-tooltip'"
        >
          {{ row.value }}
        </div>
      </ng-template>
    </div>
    <ng-template #emptyTemplate>
      Please
      <a class="confirmo-link" (click)="addDirector()">add a Director</a>
      before continuing to the next step.
    </ng-template>
  </div>

  <div class="merchant-directors-list__bottom-container">
    <div class="merchant-directors-list__buttons">
      <button [disabled]="isDirectorsListEmpty" class="confirmo-button confirmo-button__blue" (click)="nextStep()">
        Continue
      </button>
    </div>
  </div>
</div>
