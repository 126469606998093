import { Component, Input } from '@angular/core';

@Component({
  selector: 'bp-platform-checkbox',
  templateUrl: './platform-checkbox.component.html',
  styleUrls: ['./platform-checkbox.component.scss'],
})
export class PlatformCheckboxComponent {
  @Input() label: string;
  @Input() warning: string;
}
