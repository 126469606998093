import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

/**
 * Converts Moment object to string by specified format
 */
@Pipe({
  name: 'bpMomentDate',
  standalone: true,
})
export class MomentDatePipe implements PipeTransform {
  transform(value: Moment, format: string = 'DD/MM/YYYY'): string {
    if (value == null || !value.isValid) {
      return '';
    }

    return value.format(format);
  }
}
