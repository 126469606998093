import { OwnerRole } from './owner.role';

export class AdminRole extends OwnerRole {
  canViewSettlementMethodList(): boolean {
    return false;
  }

  canViewUserList(): boolean {
    return false;
  }

  canEditInvoiceSettings(): boolean {
    return false;
  }

  canAccessNotificationSettings(): boolean {
    return false;
  }

  canAccessPublicProfileSettings(): boolean {
    return false;
  }

  canViewLightning(): boolean {
    return true;
  }
}
