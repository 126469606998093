import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CountryTo } from '../../../models/api/country.model';
import { scrollToElement } from '../../../utils';
import { AbstractSelectComponent } from '../abstract-select.component';

@Component({
  selector: 'bp-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss', '../abstract-select.component.scss'],
})
export class CountrySelectComponent extends AbstractSelectComponent<CountryTo> {
  @ViewChild('input') input: ElementRef;
  @Output() selectedChange = new EventEmitter<CountryTo>();
  @Input() countries: CountryTo[];
  @Input() selected: CountryTo;
  @Input() isHigh = false;
  @Input() minWidth: number;
  @Input() centered = false;
  @Input() normalFontWeight = false;
  @Input() errorMessage: string;
  @Input() redesigned: boolean = false;
  @Input() placeholder = 'Select country';
  @Input() warningMessage = 'Select your country';
  focused = false;

  get enabledCountries(): CountryTo[] {
    return this.countries.filter((country) => country.enabled);
  }

  setOptionByArrowButton(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Tab':
        this.listShown = false;
        break;
      case 'Enter':
        event.preventDefault();
        this.toggleOptionList();
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.enabledCountries.length > 0) {
          this.selected = this.getPrevOption(this.selected, this.enabledCountries);
          this.selectedChange.emit(this.selected);
          scrollToElement(this.optionsContainer, this.idPrefix + this.selected.alpha2.toString().replace(/ /g, '-'));
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (this.enabledCountries.length > 0) {
          this.selected = this.getNextOption(this.selected, this.enabledCountries);
          this.selectedChange.emit(this.selected);
          scrollToElement(this.optionsContainer, this.idPrefix + this.selected.alpha2.toString().replace(/ /g, '-'));
        }
        break;
      default:
        if (this.enabledCountries.length > 0) {
          this.setOptionBySubstring(event.key);
        }
    }
  }

  setOption(option: CountryTo): void {
    this.listShown = false;
    this.selectedChange.emit(option);
    this.selected = option;
  }

  isOptionSelected(option: CountryTo): boolean {
    return (
      Object.is(this.selected, option) ||
      (this.selected?.id === option.id &&
        this.selected?.name === option.name &&
        this.selected?.alpha2 === option.alpha2 &&
        this.selected?.alpha3 === option.alpha3 &&
        this.selected?.enabled === option.enabled &&
        this.selected?.sepa === option.sepa)
    );
  }

  setFocus(focused: boolean): void {
    if (focused) {
      this.touched = true;
    }
    this.focused = focused;
  }

  findOptionByStoredSubstring(): void {
    const option = this.enabledCountries.find((option) => option.name.toLowerCase().startsWith(this.searchString));
    if (option) {
      this.selected = option;
      this.selectedChange.emit(this.selected);
      scrollToElement(this.optionsContainer, this.idPrefix + this.selected.alpha2.toString().replace(/ /g, '-'));
    }
  }

  get isDisabledOptionSet(): boolean {
    return !this.selected.enabled;
  }

  get showInvalidTooltip(): boolean {
    return this.touched && !this.focused && !this.listShown;
  }

  focusInput(): void {
    this.input.nativeElement.focus();
    this.toggleOptionList();
  }
}
