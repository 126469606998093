import { createReducer, on } from '@ngrx/store';
import {
  wizardNextStepAction,
  wizardSelectStepAction,
  wizardSetMaxAllowedStepsAction,
  wizardStepBackAction,
} from '../actions/wizard.actions';

export interface WizardState {
  maxAllowedStep: number;
}

export const initialState: WizardState = {
  maxAllowedStep: 1,
};
export const wizardReducer = createReducer(
  initialState,
  on(wizardSetMaxAllowedStepsAction, (state, props) => ({
    ...state,
    maxAllowedStep: props.maxAllowedSteps,
  })),
  on(wizardSelectStepAction, (state, props) => ({
    ...state,
    step: props.step,
    route: props.route,
  })),
  on(wizardNextStepAction, (state) => ({
    ...state,
  })),
  on(wizardStepBackAction, (state) => ({
    ...state,
  })),
);
