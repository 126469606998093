import { createAction, props } from '@ngrx/store';
import { SubscriptionListFiltersModel } from './subscription-list-filters.model';

export enum SubscriptionListFiltersAction {
  GET = 'subscriptionListFilters/GET',
  UPDATE = 'subscriptionListFilters/UPDATE',
}

export const getSubscriptionListFiltersAction = createAction(SubscriptionListFiltersAction.GET);

export const updateSubscriptionListFiltersAction = createAction(
  SubscriptionListFiltersAction.UPDATE,
  props<{ filters: SubscriptionListFiltersModel }>(),
);
