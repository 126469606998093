import { createReducer, on } from '@ngrx/store';
import { getRefundPageErrorAction, getRefundPageSuccessAction } from '../actions/refund-page.actions';
import { RefundTo } from '../models/api/refund.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type RefundPageState = StateModel<PagingModel<RefundTo>>;

const INITIAL_STATE: RefundPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
};

export const refundPageReducer = createReducer(
  INITIAL_STATE,
  on(getRefundPageSuccessAction, (state, { refunds }) => ({
    errors: null,
    data: refunds,
  })),
  on(getRefundPageErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
