import { Component } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  setMerchantVerificationPerson,
  setMerchantVerificationPersonError,
  setMerchantVerificationPersonSuccess,
} from '../../../../../shared/actions/merchant-verification-idenfy.actions';
import { wizardNextStepAction, wizardStepBackAction } from '../../../../../shared/actions/wizard.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { PersonModel } from '../../../../../shared/models/api/merchant-profile/person-model';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { MessageService } from '../../../../../shared/services/message.service';
import { getMerchantDirectorsListAction } from '../../../../../shared/store/merchant-directors/merchant-directors.actions';
import { selectMerchantDirectors } from '../../../../../shared/store/merchant-directors/merchant-directors.selector';

@Component({
  selector: 'bp-director-selector',
  templateUrl: './director-selector.component.html',
  styleUrls: ['./director-selector.component.scss', '../../aml-wizard.scss'],
})
export class DirectorSelectorComponent extends AbstractComponent {
  merchantDirectors: PersonModel[];

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    protected messageService: MessageService,
  ) {
    super();

    this.store
      .select(selectMerchantDirectors)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors != null) {
          this.messageService.showErrors(state.errors, 'Merchant Directors Error');
          return;
        }

        if (state.data) {
          this.merchantDirectors = state.data;
        }
      });

    this.actions.pipe(ofType(setMerchantVerificationPersonSuccess), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.nextStep();
    });

    this.actions
      .pipe(ofType(setMerchantVerificationPersonError), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => {
        this.messageService.showErrors(errors, "Person couldn't be selected");
      });

    this.store.dispatch(getMerchantDirectorsListAction());
  }

  stepBack(): void {
    this.store.dispatch(wizardStepBackAction());
  }

  public nextStep(): void {
    this.store.dispatch(wizardNextStepAction());
  }

  setDirector(id: string): void {
    this.store.dispatch(setMerchantVerificationPerson({ hashId: id }));
  }
}
