import { createReducer, on } from '@ngrx/store';
import {
  createPaymentButtonAction,
  createPaymentButtonErrorAction,
  createPaymentButtonSuccessAction,
  getPaymentButtonDetailAction,
  getPaymentButtonDetailSuccessAction,
  initializePaymentButtonFormAction,
} from '../actions/payment-button.actions';
import { PaymentButtonModel } from '../models/api/payment-button.model';
import { StateModel } from '../models/auxiliary/state.model';

export type PaymentButtonState = StateModel<PaymentButtonModel>;

const INITIAL_STATE: PaymentButtonState = {
  errors: null,
  data: null,
};

export const paymentButtonReducer = createReducer(
  INITIAL_STATE,
  on(initializePaymentButtonFormAction, () => ({ ...INITIAL_STATE })),
  on(createPaymentButtonAction, getPaymentButtonDetailAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(getPaymentButtonDetailSuccessAction, createPaymentButtonSuccessAction, (state, { button }) => ({
    ...state,
    data: button,
    loading: false,
  })),
  on(createPaymentButtonErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
