import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getPayoutFeesAction,
  getPayoutFeesErrorAction,
  getPayoutFeesSuccessAction,
} from '../actions/payout-fees.actions';
import { ApiService } from '../services/api.service';

@Injectable()
export class PayoutFeesEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayoutFeesAction),
      mergeMap(({ currencies }) =>
        this.api.get(this.urlParams(currencies)).pipe(
          map((payoutFees) => getPayoutFeesSuccessAction({ payoutFees })),
          catchError((errors) => observableOf(getPayoutFeesErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  urlParams(params: string[]): string {
    let url = '/payouts/fees/internal?currencies=';
    params.forEach((param) => {
      url = url + param + ',';
    });
    // remove last comma from string
    url = url.substring(0, url.length - 1);

    return url;
  }
}
