<div class="idenfy-wrapper-content">
  <ng-container *ngIf="show">
    <ng-container *ngIf="!methodSelected && !verificationStarted">
      <bp-method-selector
        [merchantId]="merchantId"
        [nameToDisplay]="nameToDisplay"
        (methodSelected)="startListeningForState()"
      ></bp-method-selector>
    </ng-container>
    <ng-container *ngIf="methodSelected && !verificationStarted">
      <bp-idenfy-qr-code
        [merchantId]="merchantId"
        [nameToDisplay]="nameToDisplay"
        (showMethodOffer)="backToOffer()"
      ></bp-idenfy-qr-code>
    </ng-container>
    <ng-container *ngIf="verificationStarted">
      <bp-idenfy-status [merchantId]="merchantId" (showMethodOffer)="backToOffer()"></bp-idenfy-status>
    </ng-container>
  </ng-container>
</div>
