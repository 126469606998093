import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  getMerchantFeesAction,
  getMerchantFeesErrorAction,
  getMerchantFeesSuccessAction,
  resetMerchantFeesAction,
  resetMerchantFeesErrorAction,
  resetMerchantFeesSuccessAction,
  updateMerchantFeesAction,
  updateMerchantFeesErrorAction,
  updateMerchantFeesSuccessAction,
} from '../actions/merchant-fees.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';
import { MessageService } from '../services/message.service';

export const MERCHANT_FEES_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantId}/configuration/{feesType}`;
@Injectable()
export class MerchantFeesEffect {
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(ApiService);
  private readonly messageService = inject(MessageService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantFeesAction),
      switchMap(({ merchantId, feesType }) =>
        this.apiService
          .get(MERCHANT_FEES_ENDPOINT.replace('{merchantId}', merchantId).replace('{feesType}', feesType))
          .pipe(
            map((merchantFees) => getMerchantFeesSuccessAction({ merchantFees })),
            catchError((errors) => observableOf(getMerchantFeesErrorAction(errors))),
          ),
      ),
    ),
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantFeesAction),
      switchMap(({ merchantFees, merchantHashId }) =>
        this.apiService
          .put(
            MERCHANT_FEES_ENDPOINT.replace('{merchantId}', merchantHashId).replace('{feesType}', merchantFees.type),
            merchantFees,
          )
          .pipe(
            map((merchantFees) => updateMerchantFeesSuccessAction({ merchantFees })),
            catchError((errors) => observableOf(updateMerchantFeesErrorAction(errors))),
          ),
      ),
    ),
  );

  resetSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetMerchantFeesAction),
      switchMap(({ merchantHashId, feesType }) =>
        this.apiService
          .delete(MERCHANT_FEES_ENDPOINT.replace('{merchantId}', merchantHashId).replace('{feesType}', feesType))
          .pipe(
            map(() => resetMerchantFeesSuccessAction()),
            catchError((errors) => observableOf(resetMerchantFeesErrorAction(errors))),
          ),
      ),
    ),
  );

  showSuccessMessageOnSettingsUpdate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetMerchantFeesSuccessAction, updateMerchantFeesSuccessAction),
        tap(() => {
          this.messageService.success(`Settings successfully updated`);
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnSettingsUpdate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetMerchantFeesErrorAction, updateMerchantFeesErrorAction),
        tap(({ errors }) => {
          this.messageService.showErrors(errors, `Settings could not be updated updated`);
        }),
      ),
    { dispatch: false },
  );
}
