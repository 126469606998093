import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PersonModel } from '../../models/api/merchant-profile/person-model';
import { ApiService } from '../../services/api.service';
import { getIsoDateString } from '../../utils';
import {
  createMerchantDirectorAction,
  createMerchantDirectorErrorAction,
  createMerchantDirectorSuccessAction,
  deleteMerchantDirectorAction,
  deleteMerchantDirectorErrorAction,
  deleteMerchantDirectorSuccessAction,
  getMerchantDirectorsListAction,
  getMerchantDirectorsListErrorAction,
  getMerchantDirectorsListSuccessAction,
  updateMerchantDirectorAction,
  updateMerchantDirectorErrorAction,
  updateMerchantDirectorSuccessAction,
} from './merchant-directors.actions';

export const MERCHANT_DIRECTORS_ENDPOINT = '/merchant-profile/company-directors';
export const MERCHANT_DIRECTOR_DETAIL_ADMIN_ENDPOINT = '/admin/merchant-profile/company-directors';
export const MERCHANT_DIRECTOR_DELETE_ENDPOINT = '/merchant-profile/persons';

@Injectable()
export class MerchantDirectorsEffect {
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDirectorsListAction),
      mergeMap(() =>
        this.api.get(MERCHANT_DIRECTORS_ENDPOINT).pipe(
          map((merchantDirectors: PersonModel[]) =>
            getMerchantDirectorsListSuccessAction({
              merchantDirectors: merchantDirectors.map((director) => this.fromApiToModel(director)),
            }),
          ),
          catchError((errors) => observableOf(getMerchantDirectorsListErrorAction(errors))),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMerchantDirectorAction),
      switchMap(({ merchantDirector }) =>
        this.api.post(MERCHANT_DIRECTORS_ENDPOINT, this.fromModelToApi(merchantDirector)).pipe(
          map((returnedDirector) =>
            createMerchantDirectorSuccessAction({ merchantDirector: this.fromApiToModel(returnedDirector) }),
          ),
          catchError((errors) => observableOf(createMerchantDirectorErrorAction(errors))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantDirectorAction),
      mergeMap(({ merchantDirector, isAdmin }) =>
        this.api
          .put(
            isAdmin
              ? `${MERCHANT_DIRECTOR_DETAIL_ADMIN_ENDPOINT}/${merchantDirector.merchantHashId}`
              : `${MERCHANT_DIRECTORS_ENDPOINT}/${merchantDirector.id}`,
            this.fromModelToApi(merchantDirector),
          )
          .pipe(
            map((returnedDirector) =>
              updateMerchantDirectorSuccessAction({ merchantDirector: this.fromApiToModel(returnedDirector) }),
            ),
            catchError((errors) => observableOf(updateMerchantDirectorErrorAction(errors))),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMerchantDirectorAction),
      mergeMap(({ merchantDirectorId }) =>
        this.api.delete(`${MERCHANT_DIRECTOR_DELETE_ENDPOINT}/${merchantDirectorId}`).pipe(
          map(() => deleteMerchantDirectorSuccessAction({ merchantDirectorId: merchantDirectorId })),
          catchError((errors) => observableOf(deleteMerchantDirectorErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  protected fromModelToApi(director: PersonModel): PersonModel {
    if (typeof director.birthDate === 'string') {
      return director;
    }

    return {
      ...director,
      birthDate: getIsoDateString(director.birthDate) as any,
    };
  }

  protected fromApiToModel(director: PersonModel): PersonModel {
    if (typeof director.birthDate === 'object') {
      // assume birthDate is Moment object
      return director;
    }

    return {
      ...director,
      birthDate: moment(director.birthDate),
    };
  }
}
