import { createReducer, on } from '@ngrx/store';
import { logoutSuccessAction } from '../actions/auth.actions';
import { getUserErrorAction, getUserSuccessAction } from '../actions/user.actions';
import { UserModel } from '../models/api/user.model';
import { StateModel } from '../models/auxiliary/state.model';

export type UserState = StateModel<UserModel>;

const INITIAL_STATE: UserState = {
  errors: null,
  data: null,
};

export const userReducer = createReducer(
  INITIAL_STATE,
  on(getUserSuccessAction, (state, { user }) => ({
    errors: null,
    data: user,
  })),
  on(getUserErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(logoutSuccessAction, () => INITIAL_STATE),
);
