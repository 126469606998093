import { createReducer, on } from '@ngrx/store';
import {
  createMerchantPersonSuccessAction,
  deleteMerchantPersonSuccessAction,
  updateFlagsErrorAction,
  updateFlagsSuccessAction,
} from '../actions/merchant-person.actions';
import {
  getMerchantProfileAction,
  getMerchantProfileAsAdminAction,
  getMerchantProfileAsAdminErrorAction,
  getMerchantProfileAsAdminSuccessAction,
  getMerchantProfileErrorAction,
  getMerchantProfileSuccessAction,
  updateMerchantProfileSuccessAction,
} from '../actions/merchant-profile.actions';
import { CompanyMerchantProfileModel } from '../models/api/merchant-profile/company-merchant-profile.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantProfileState = StateModel<MerchantProfileUnionModel>;

const INITIAL_STATE: MerchantProfileState = {
  errors: null,
  data: null,
  initial: true,
};

export const MerchantProfileReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantProfileAction, getMerchantProfileAsAdminAction, () => ({
    ...INITIAL_STATE,
  })),
  on(updateMerchantProfileSuccessAction, (state, { updatedMerchantProfile }) => ({
    errors: null,
    data: updatedMerchantProfile,
    initial: false,
  })),
  on(getMerchantProfileSuccessAction, getMerchantProfileAsAdminSuccessAction, (state, { merchantProfile }) => ({
    errors: null,
    data: merchantProfile,
    initial: false,
  })),
  on(getMerchantProfileErrorAction, getMerchantProfileAsAdminErrorAction, (state, { errors }) => ({
    errors: errors,
    initial: false,
  })),
  on(deleteMerchantPersonSuccessAction, (state, { person }) => ({
    errors: null,
    data: <CompanyMerchantProfileModel>{
      ...state.data,
      persons: (<CompanyMerchantProfileModel>state.data).persons.filter((x) => x.id !== person.id),
    },
    initial: false,
  })),
  on(createMerchantPersonSuccessAction, (state, { addedPerson }) => ({
    errors: null,
    data: <CompanyMerchantProfileModel>{
      ...state.data,
      persons: [...(<CompanyMerchantProfileModel>state.data).persons, addedPerson],
    },
    initial: false,
  })),
  on(updateFlagsSuccessAction, (state, { flags, personId }) => ({
    errors: null,
    data: <CompanyMerchantProfileModel>{
      ...state.data,
      persons: (<CompanyMerchantProfileModel>state.data).persons.map((x) => {
        if (x.id === personId) {
          x.flags = flags;
        }
        return x;
      }),
    },
    initial: false,
  })),
  on(updateFlagsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
