<div class="director-selector-content">
  <div class="back-arrow__container">
    <mat-icon class="back-arrow__button" (click)="stepBack()">keyboard_backspace</mat-icon>
  </div>
  <div class="wizard-step__header">
    <h1 class="bp-heading director-selector-heading">Select person</h1>
    <p class="wizard-step__legend">Continue as</p>
  </div>
  <div *ngFor="let director of merchantDirectors">
    <div class="confirmo-button confirmo-button__white-blue selection-button" (click)="setDirector(director.id)">
      <div class="button-content">
        <img alt="Entrepreneur icon" src="assets/verification/individual.svg" class="button-image" />
        <div>{{ director.firstName }} {{ director.lastName }}</div>
      </div>
    </div>
  </div>
</div>
