import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  cryptoAddressVerifyErrorAction,
  cryptoAddressVerifyRequestAction,
  cryptoAddressVerifySuccessAction,
} from '../actions/crypto-address.actions';
import { ApiService } from '../services/api.service';

@Injectable()
export class CryptoAddressVerifyEffect {
  post$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cryptoAddressVerifyRequestAction),
      switchMap(({ cryptoAddressModel }) =>
        this.api.post('/crypto-addresses/verify', cryptoAddressModel).pipe(
          map((isValidCryptoAddress) => cryptoAddressVerifySuccessAction({ isValidCryptoAddress })),
          catchError((errors) => observableOf(cryptoAddressVerifyErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
