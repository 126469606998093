import { createReducer, on } from '@ngrx/store';
import {
  getApiKeyListAction,
  getApiKeyListErrorAction,
  getApiKeyListSuccessAction,
} from '../actions/api-key-list.actions';
import { ApiKeyModel } from '../models/api/api-key.model';
import { StateModel } from '../models/auxiliary/state.model';

export type ApiKeyListState = StateModel<ApiKeyModel[]>;

const INITIAL_STATE: ApiKeyListState = {
  errors: null,
  data: [],
};

export const apiKeyListReducer = createReducer(
  INITIAL_STATE,
  on(getApiKeyListAction, () => ({ ...INITIAL_STATE })),
  on(getApiKeyListSuccessAction, (state, { apiKeys }) => ({
    data: apiKeys,
    errors: null,
  })),
  on(getApiKeyListErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
