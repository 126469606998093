import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../models/api/user.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getUserListAsAdminAction,
  getUserListAsAdminErrorAction,
  getUserListAsAdminSuccessAction,
} from './user-list-admin.actions';

export type UserListAdminState = StateModel<UserModel[]>;

const INITIAL_STATE: UserListAdminState = {
  errors: null,
  data: null,
};

export const userListAdminReducer = createReducer(
  INITIAL_STATE,
  on(getUserListAsAdminAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(getUserListAsAdminSuccessAction, (state, { users }) => ({
    ...state,
    data: users,
  })),
  on(getUserListAsAdminErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
