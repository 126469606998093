/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { PaymentButtonListFiltersModel } from './payment-button-list-filters.model';

export enum PaymentButtonListFiltersActions {
  GET = 'paymentButtonListFilters/GET',
  UPDATE = 'paymentButtonListFilters/UPDATE',
}

export const getPaymentButtonListFiltersAction = createAction(PaymentButtonListFiltersActions.GET);

export const updatePaymentButtonListFiltersAction = createAction(
  PaymentButtonListFiltersActions.UPDATE,
  props<{ filters: PaymentButtonListFiltersModel }>(),
);
