import { createReducer, on } from '@ngrx/store';
import {
  getOtherSideTransactionListAction,
  getOtherSideTransactionsListErrorAction,
  getOtherSideTransactionsListSuccessAction,
} from '../actions/other-side-transaction.actions';
import { OtherSideTransactionForAdmin } from '../models/api/other-side-transaction.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type OtherSideTransactionListState = StateModel<PagingModel<OtherSideTransactionForAdmin>>;

const INITIAL_STATE: OtherSideTransactionListState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const otherSideTransactionListReducer = createReducer(
  INITIAL_STATE,
  on(getOtherSideTransactionsListSuccessAction, (state, { transactions }) => ({
    data: transactions,
    errors: null,
    loading: false,
  })),
  on(getOtherSideTransactionsListErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
  on(getOtherSideTransactionListAction, () => ({
    ...INITIAL_STATE,
    loading: true,
  })),
);
