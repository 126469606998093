import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { CryptoWalletStatisticsTo } from '../../models/api/crypto-wallets-statistics.model';
import { ApiService } from '../../services/api.service';
import { MessageService } from '../../services/message.service';
import { QueryParams, queryParams } from '../../utils';
import { getCryptoWalletsStatisticsErrorAction } from './crypto-wallets-statistics.actions';

@Injectable({
  providedIn: 'root',
})
export class CryptoWalletsStatisticsService {
  private readonly api = inject(ApiService);
  private readonly messageService = inject(MessageService);
  private readonly actions = inject(Actions);

  ADDRESSES_ENDPOINT = '/admin/crypto-wallet-statistics';

  constructor() {
    this.actions.pipe(ofType(getCryptoWalletsStatisticsErrorAction)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Crypto Wallets Statistics Error`);
    });
  }

  public getCryptoWalletsStatistics(cryptoProvider: string): Observable<CryptoWalletStatisticsTo[]> {
    const params: QueryParams = { providerCode: cryptoProvider };
    const url = this.ADDRESSES_ENDPOINT + queryParams(params);

    return this.api.get(url);
  }
}
