/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { UserRole } from '../../enums/user-role.enum';
import { ErrorModel } from '../../models/api/error.model';
import { UserModel } from '../../models/api/user.model';

export enum UserListActionTypes {
  GetUserList = 'userList/GET_REQUEST',
  GetUserListSuccess = 'userList/GET_SUCCESS',
  GetUserListError = 'userList/GET_ERROR',

  DeleteUser = 'userList/DELETE_USER_REQUEST',
  DeleteUserSuccess = 'userList/DELETE_USER_SUCCESS',
  DeleteUserError = 'userList/DELETE_USER_ERROR',

  ResendActivationEmail = 'userList/RESEND_ACTIVATION_EMAIL',
  ResendActivationEmailSuccess = 'userList/RESEND_ACTIVATION_EMAIL_SUCCESS',
  ResendActivationEmailError = 'userList/RESEND_ACTIVATION_EMAIL_ERROR',

  UpdateUserRole = 'userList/UPDATE_USER_ROLE_REQUEST',
  UpdateUserRoleSuccess = 'userList/UPDATE_USER_ROLE_SUCCESS',
  UpdateUserRoleError = 'userList/UPDATE_USER_ROLE_ERROR',
}

export const getUserListAction = createAction(UserListActionTypes.GetUserList);

export const getUserListSuccessAction = createAction(
  UserListActionTypes.GetUserListSuccess,
  props<{ users: UserModel[] }>(),
);

export const getUserListErrorAction = createAction(
  UserListActionTypes.GetUserListError,
  props<{ errors: ErrorModel[] }>(),
);

export const deleteUserAction = createAction(UserListActionTypes.DeleteUser, props<{ user: UserModel }>());

export const deleteUserSuccessAction = createAction(
  UserListActionTypes.DeleteUserSuccess,
  props<{ user: UserModel }>(),
);

export const deleteUserErrorAction = createAction(
  UserListActionTypes.DeleteUserError,
  props<{ errors: ErrorModel[] }>(),
);

export const resendActivationEmailAction = createAction(
  UserListActionTypes.ResendActivationEmail,
  props<{ user: UserModel }>(),
);

export const resendActivationEmailSuccessAction = createAction(
  UserListActionTypes.ResendActivationEmailSuccess,
  props<{ user: UserModel }>(),
);

export const resendActivationEmailErrorAction = createAction(
  UserListActionTypes.ResendActivationEmailError,
  props<{ errors: ErrorModel[] }>(),
);

export const updateUserRoleAction = createAction(
  UserListActionTypes.UpdateUserRole,
  props<{ id: string; role: UserRole; twoFaCode: string }>(),
);

export const updateUserRoleSuccessAction = createAction(
  UserListActionTypes.UpdateUserRoleSuccess,
  props<{ user: UserModel }>(),
);

export const updateUserRoleErrorAction = createAction(
  UserListActionTypes.UpdateUserRoleError,
  props<{ errors: ErrorModel[] }>(),
);
