export enum MerchantStatus {
  /**
   * Merchant was newly created, but wasn't granted write permissions yet.
   */
  NEW = 'NEW',
  /**
   * Merchant wasn't AML approved yet, but he was granted with limited write permissions to test our app.
   */
  TESTING = 'TESTING',
  /**
   * Merchant was approved by an AML officer, and he's now able to fully use the app.
   */
  REGULAR = 'REGULAR',
  GHOST = 'GHOST',
  /**
   * Merchant previously had write access (e.g. as TESTING_ENABLED or REGULAR merchant). For some reason, we've
   * decided that he no longer has it, and that he should only have read access.
   */
  SUSPENDED = 'SUSPENDED',
}
