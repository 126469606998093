import { Route } from '@angular/router';
import { MerchantProfileType } from '../shared/enums/merchant-profile-type.enum';
import { MerchantVerificationDocumentsGuard } from '../shared/guards/merchant-verification-documents.guard';
import { MerchantVerificationComponent } from './common-components/aml-commons/merchant-verification/merchant-verification.component';
import { DirectorSelectorComponent } from './merchant/aml-wizard/idenfy/director-selector/director-selector.component';
import { IdenfyWrapperComponent } from './merchant/aml-wizard/idenfy/idenfy-wrapper/idenfy-wrapper.component';
import { MerchantDirectorsListComponent } from './merchant/aml-wizard/merchant-directors-list/merchant-directors-list.component';
import { MerchantDocumentsComponent } from './merchant/aml-wizard/merchant-documents/merchant-documents.component';
import { MerchantPersonsComponent } from './merchant/aml-wizard/merchant-persons/merchant-persons.component';

export interface VerificationWizardRouteSet {
  prefix: string;
  routes: Route[];
}

export const merchantVerificationCompanyRoutes: VerificationWizardRouteSet = {
  prefix: 'company-verification',
  routes: [
    {
      path: '1',
      component: MerchantVerificationComponent,
      data: { name: 'Basic information', currentStep: 1, type: MerchantProfileType.COMPANY },
    },
    { path: '2', component: MerchantDirectorsListComponent, data: { name: 'Add a director', currentStep: 2 } },
    { path: '3', component: DirectorSelectorComponent, data: { name: 'Select person', currentStep: 3 } },
    { path: '4', component: IdenfyWrapperComponent, data: { name: 'Select verification method', currentStep: 4 } },
    { path: '5', component: MerchantPersonsComponent, data: { name: 'Add beneficiary', currentStep: 5 } },
    {
      path: '6',
      component: MerchantDocumentsComponent,
      data: { name: 'Document upload', currentStep: 6 },
      canDeactivate: [MerchantVerificationDocumentsGuard],
    },
  ],
};

export const merchantVerificationPersonRoutes: VerificationWizardRouteSet = {
  prefix: 'person-verification',
  routes: [
    {
      path: '1',
      component: MerchantVerificationComponent,
      data: { name: 'Basic information', currentStep: 1, type: MerchantProfileType.PERSON },
    },
    { path: '2', component: IdenfyWrapperComponent, data: { name: 'Select verification method', currentStep: 2 } },
    {
      path: '3',
      component: MerchantDocumentsComponent,
      data: { name: 'Document upload', currentStep: 3 },
      canDeactivate: [MerchantVerificationDocumentsGuard],
    },
  ],
};

export function getWizardStepByComponent(routeList: VerificationWizardRouteSet, component: any): number {
  if (routeList == null || routeList.routes == null) {
    return 1;
  }

  const index = routeList.routes.findIndex((route) => route.component === component);

  return index >= 0 ? index + 1 : -1;
}
