import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiKeyDetailActionTypes } from '../actions/api-key-detail.actions';
import {
  createCallbackPasswordErrorAction,
  createCallbackPasswordSuccessAction,
} from '../actions/callback-password-create.actions';
import {
  deleteCallbackPasswordErrorAction,
  deleteCallbackPasswordSuccessAction,
} from '../actions/callback-password.actions';
import { changePasswordErrorAction, changePasswordSuccessAction } from '../actions/login-prefill.actions';
import {
  OtherSideTransactionActionTypes,
  createOtherSideTransactionErrorAction,
  createOtherSideTransactionSuccessAction,
} from '../actions/other-side-transaction.actions';
import { closeTwoFaDialogAction } from '../actions/two-fa.actions';
import {
  emailTwoFAAction,
  emailTwoFAErrorAction,
  emailTwoFASuccessAction,
  googleActivateTwoFAAction,
  googleActivateTwoFAErrorAction,
  googleActivateTwoFASuccessAction,
  googleDisableTwoFAAction,
  googleDisableTwoFAAsAdminAction,
  googleDisableTwoFAAsAdminErrorAction,
  googleDisableTwoFAAsAdminSuccessAction,
  googleDisableTwoFAErrorAction,
  googleDisableTwoFASuccessAction,
  googleRegisterTwoFAAction,
  googleRegisterTwoFAErrorAction,
  googleRegisterTwoFASuccessAction,
} from '../actions/two-factor-auth.actions';
import { UserDetailActionTypes } from '../actions/user-detail.actions';
import { ApiService, get2FaHeaders } from '../services/api.service';
import { createRefundMerchantErrorAction, createRefundMerchantSuccessAction } from '../store/refund/refund.actions';
import {
  createSettlementMethodErrorAction,
  createSettlementMethodSuccessAction,
  updateSettlementMethodErrorAction,
  updateSettlementMethodSuccessAction,
} from '../store/settlement-method/settlement-method.actions';
import { UserListActionTypes } from '../store/user-list/user-list.actions';

const USERS_2FA_GOOGLE_REGISTER_ENDPOINT = '/user/google2fa/enable';
const USERS_2FA_GOOGLE_ACTIVATE_ENDPOINT = '/user/google2fa/activate';
const USERS_2FA_GOOGLE_DISABLE_ENDPOINT = '/user/google2fa/disable';
const USERS_2FA_GOOGLE_DISABLE_AS_ADMIN_ENDPOINT = '/admin/users/{userHashId}/google2fa/disable';
const USERS_2FA_EMAIL_ENDPOINT = '/user/email2fa';

const actionsClosing2FaDialog = [
  deleteCallbackPasswordSuccessAction,
  deleteCallbackPasswordErrorAction,

  createCallbackPasswordSuccessAction,
  createCallbackPasswordErrorAction,

  ApiKeyDetailActionTypes.POST_SUCCESS,
  ApiKeyDetailActionTypes.POST_ERROR,

  ApiKeyDetailActionTypes.UPDATE_SUCCESS,
  ApiKeyDetailActionTypes.UPDATE_ERROR,

  ApiKeyDetailActionTypes.DELETE_API_KEY_SUCCESS,
  ApiKeyDetailActionTypes.DELETE_API_KEY_ERROR,

  updateSettlementMethodSuccessAction,
  updateSettlementMethodErrorAction,

  createSettlementMethodSuccessAction,
  createSettlementMethodErrorAction,

  googleDisableTwoFASuccessAction,
  googleDisableTwoFAErrorAction,

  changePasswordSuccessAction,
  changePasswordErrorAction,

  googleRegisterTwoFASuccessAction,
  googleRegisterTwoFAErrorAction,

  OtherSideTransactionActionTypes.ConfirmOtherSideTransactionSuccess,
  OtherSideTransactionActionTypes.ConfirmOtherSideTransactionError,

  createOtherSideTransactionSuccessAction,
  createOtherSideTransactionErrorAction,

  createRefundMerchantSuccessAction,
  createRefundMerchantErrorAction,

  UserDetailActionTypes.CreateUserSuccess,
  UserDetailActionTypes.CreateUserError,

  UserListActionTypes.UpdateUserRoleSuccess,
  UserListActionTypes.UpdateUserRoleError,
];

@Injectable()
export class TwoFactorAuthEffect {
  registerGoogle2FA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(googleRegisterTwoFAAction),
      switchMap(({ twoFACode }) =>
        this.api.post(USERS_2FA_GOOGLE_REGISTER_ENDPOINT, null, get2FaHeaders(twoFACode)).pipe(
          map((twoFACode) => googleRegisterTwoFASuccessAction({ twoFACode })),
          catchError((errors) => observableOf(googleRegisterTwoFAErrorAction(errors))),
        ),
      ),
    ),
  );

  activateGoogle2FA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(googleActivateTwoFAAction),
      switchMap(({ google2faActivation }) =>
        this.api.post(`${USERS_2FA_GOOGLE_ACTIVATE_ENDPOINT}/${google2faActivation.code}`, google2faActivation).pipe(
          map(() => googleActivateTwoFASuccessAction()),
          catchError((errors) => observableOf(googleActivateTwoFAErrorAction(errors))),
        ),
      ),
    ),
  );

  disableGoogle2FA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(googleDisableTwoFAAction),
      switchMap(({ twoFACode }) =>
        this.api.post(USERS_2FA_GOOGLE_DISABLE_ENDPOINT, null, get2FaHeaders(twoFACode)).pipe(
          map(() => googleDisableTwoFASuccessAction()),
          catchError((errors) => observableOf(googleDisableTwoFAErrorAction(errors))),
        ),
      ),
    ),
  );

  disableGoogle2FAAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(googleDisableTwoFAAsAdminAction),
      switchMap(({ userId }) =>
        this.api.post(USERS_2FA_GOOGLE_DISABLE_AS_ADMIN_ENDPOINT.replace('{userHashId}', userId)).pipe(
          map(() => googleDisableTwoFAAsAdminSuccessAction()),
          catchError((errors) => observableOf(googleDisableTwoFAAsAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  email2FA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emailTwoFAAction),
      switchMap((action) =>
        this.api.post(USERS_2FA_EMAIL_ENDPOINT).pipe(
          map(() => emailTwoFASuccessAction()),
          catchError((errors) => observableOf(emailTwoFAErrorAction(errors))),
        ),
      ),
    ),
  );

  close2FaDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...actionsClosing2FaDialog),
      map(() => closeTwoFaDialogAction()),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
