<div [ngClass]="days.length > 0 ? 'payout-interval-container' : 'payout-interval-container__no-day'">
  <div
    class="payout-interval-input"
    [ngClass]="days.length > 0 ? 'payout-interval-input' : 'payout-interval-input__no-day'"
  >
    <div class="payout-interval-label">Payout interval</div>
    <bp-select
      [options]="payoutTypes"
      [enabledOptions]="payoutTypes"
      [(selected)]="payoutTypeValue"
      (selectedChange)="payoutTypeChanged($event)"
      [normalFontWeight]="true"
    ></bp-select>
  </div>
  <div class="payout-interval-day payout-interval-input" *ngIf="days.length > 0">
    <bp-select
      [options]="days"
      [enabledOptions]="days"
      [(selected)]="dayValue"
      (selectedChange)="dayChanged($event)"
      [normalFontWeight]="true"
    ></bp-select>
  </div>
</div>
