import { createReducer, on } from '@ngrx/store';
import { AdminCryptoNetworkTo } from '../../models/api/crypto-network.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getCryptoNetworksAction,
  getCryptoNetworksActionError,
  getCryptoNetworksActionSuccess,
} from './crypto-networks.actions';

export type CryptoNetworksListState = StateModel<AdminCryptoNetworkTo[]>;

const INITIAL_STATE: CryptoNetworksListState = {
  errors: null,
  data: null,
};

export const cryptoNetworksListReducer = createReducer(
  INITIAL_STATE,
  on(getCryptoNetworksAction, () => ({ ...INITIAL_STATE })),
  on(getCryptoNetworksActionSuccess, (state, { networks }) => ({
    data: networks,
    errors: null,
  })),
  on(getCryptoNetworksActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
