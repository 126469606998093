/* eslint-disable max-classes-per-file */
import { ActivatedRoute } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export enum WizardActionTypes {
  NextStep = 'wizard/NEXT_STEP',
  SelectStep = 'wizard/SELECT_STEP',
  SetMaxAllowedSteps = 'wizard/SET_MAX_ALLOWED_STEPS',
  StepBack = 'wizard/STEP_BACK',
}

export const wizardSetMaxAllowedStepsAction = createAction(
  WizardActionTypes.SetMaxAllowedSteps,
  props<{ maxAllowedSteps: number }>(),
);

export const wizardSelectStepAction = createAction(
  WizardActionTypes.SelectStep,
  props<{ step: number; route: ActivatedRoute }>(),
);

export const wizardNextStepAction = createAction(WizardActionTypes.NextStep);

export const wizardStepBackAction = createAction(WizardActionTypes.StepBack);
