import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getInvoiceExpirationFeeAction,
  getInvoiceExpirationFeeAdminAction,
  getInvoiceExpirationFeeErrorAction,
  getInvoiceExpirationFeeSuccessAction,
} from '../actions/invoice-expiration-fee.actions';
import { ApiService } from '../services/api.service';

export const INVOICE_EXPIRATION_FEE_ENDPOINT = `/payment-settings/invoice/expiration-interval/{interval}min/fee`;

export const INVOICE_EXPIRATION_FEE_ADMIN_ENDPOINT = `/admin/payment-settings/invoice/expiration-interval/{interval}min/fee/{merchantHashId}`;

@Injectable()
export class InvoiceExpirationFeeEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvoiceExpirationFeeAction),
      mergeMap(({ fiatMinutes }) =>
        this.api.get(this.url(fiatMinutes)).pipe(
          map((invoiceFee) => getInvoiceExpirationFeeSuccessAction({ invoiceFee })),
          catchError((errors) => observableOf(getInvoiceExpirationFeeErrorAction(errors))),
        ),
      ),
    ),
  );

  getAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvoiceExpirationFeeAdminAction),
      mergeMap(({ fiatMinutes, merchantHashId }) =>
        this.api.get(this.url(fiatMinutes, true, merchantHashId)).pipe(
          map((invoiceFee) => getInvoiceExpirationFeeSuccessAction({ invoiceFee })),
          catchError((errors) => observableOf(getInvoiceExpirationFeeErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  url(feeMinutes: number, isAdmin?: boolean, merchantHashId?: string): string {
    let url: string;
    if (isAdmin) {
      url = INVOICE_EXPIRATION_FEE_ADMIN_ENDPOINT.replace('{interval}', feeMinutes.toString()).replace(
        '{merchantHashId}',
        merchantHashId,
      );
    } else {
      url = INVOICE_EXPIRATION_FEE_ENDPOINT.replace('{interval}', feeMinutes.toString());
    }
    return url;
  }
}
