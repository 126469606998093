import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../models/api/error.model';
import { PageRequestFiltersModel, PageRequestModel } from '../../../models/auxiliary/page-request.model';
import { PagingModel } from '../../../models/auxiliary/paging.model';
import { SubscriptionModel } from '../subscription.model';

export enum SubscriptionActionTypes {
  GetSubscriptionList = 'subscriptionList/GET_SUBSCRIPTION_LIST',
  GetSubscriptionListSuccess = 'subscriptionList/GET_SUBSCRIPTION_LIST_SUCCESS',
  GetSubscriptionListError = 'subscriptionList/GET_SUBSCRIPTION_LIST_ERROR',
}

export const getSubscriptionListAction = createAction(
  SubscriptionActionTypes.GetSubscriptionList,
  props<{ paging: PageRequestModel; filters: PageRequestFiltersModel }>(),
);

export const getSubscriptionListSuccessAction = createAction(
  SubscriptionActionTypes.GetSubscriptionListSuccess,
  props<{ subscriptionList: PagingModel<SubscriptionModel> }>(),
);

export const getSubscriptionListErrorAction = createAction(
  SubscriptionActionTypes.GetSubscriptionListError,
  props<{ errors: ErrorModel[] }>(),
);
