/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { BalanceHistoryModel } from '../models/api/balance-history.model';
import { ErrorModel } from '../models/api/error.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum BalanceHistoryPageTypes {
  GET_BALANCE_HISTORY_PAGE = 'balanceHistoryPage/GET_BALANCE_HISTORY_PAGE',
  BALANCE_HISTORY_PAGE_ACTION_SUCCESS = 'balanceHistoryPage/BALANCE_HISTORY_PAGE_ACTION_SUCCESS',
  BALANCE_HISTORY_PAGE_ACTION_ERROR = 'balanceHistoryPage/BALANCE_HISTORY_PAGE_ACTION_ERROR',
}

export const getBalanceHistoryPageAction = createAction(
  BalanceHistoryPageTypes.GET_BALANCE_HISTORY_PAGE,
  props<{ limit: number; offset: number }>(),
);

export const getBalanceHistoryPageActionSuccess = createAction(
  BalanceHistoryPageTypes.BALANCE_HISTORY_PAGE_ACTION_SUCCESS,
  props<{ balanceHistoryList: PagingModel<BalanceHistoryModel> }>(),
);

export const getBalanceHistoryPageActionError = createAction(
  BalanceHistoryPageTypes.BALANCE_HISTORY_PAGE_ACTION_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
