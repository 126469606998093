import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoService {
  uaParser: UAParser = new UAParser();

  constructor() {}

  /**
   * on Desktops/Laptops returns undefined,
   * see {@link https://github.com/faisalman/ua-parser-js/issues/224}
   */

  getDeviceType(): string {
    return this.uaParser.getDevice().type;
  }

  isMobileOrTablet(): boolean {
    const type = this.getDeviceType();
    return type === 'mobile' || type === 'tablet';
  }
}
