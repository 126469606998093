import { createReducer, on } from '@ngrx/store';
import {
  activateUserErrorAction,
  activateUserSuccessAction,
  resetPasswordErrorAction,
  resetPasswordSuccessAction,
} from '../actions/login-prefill.actions';
import { LoginPrefillReason } from '../enums/login-prefill-reason.enum';
import { LoginPrefillModel } from '../models/api/login-prefill.model';
import { StateModel } from '../models/auxiliary/state.model';

export type LoginPrefillState = StateModel<LoginPrefillModel>;

const INITIAL_STATE: LoginPrefillState = { errors: null };

export const loginPrefillReducer = createReducer(
  INITIAL_STATE,
  on(activateUserSuccessAction, (state, { activationInfo }) => ({
    errors: null,
    data: {
      token: activationInfo.token,
      email: activationInfo.email,
      reason: LoginPrefillReason.USER_ACTIVATION,
    },
  })),
  on(resetPasswordSuccessAction, (state, { activationInfo }) => ({
    errors: null,
    data: {
      email: activationInfo.email,
      reason: LoginPrefillReason.PASSWORD_RESET,
    },
  })),
  on(activateUserErrorAction, resetPasswordErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
