import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  AdminCryptoPaymentMethodTo,
  AdminCryptoPaymentMethodUpdateTo,
} from '../../../models/api/crypto-payment-method.model';
import { AppStateModel } from '../../../models/auxiliary/app-state.model';
import { selectUser } from '../../../selectors/user.selector';
import { MessageService } from '../../../services/message.service';
import {
  getCryptoPaymentMethodsAdminAction,
  getCryptoPaymentMethodsAdminActionError,
  updateCryptoPaymentMethodAdminAction,
} from './crypto-payment-methods-admin.actions';
import { selectAdminCryptoPaymentMethods } from './crypto-payment-methods-admin.selector';

@Injectable({
  providedIn: 'root',
})
export class CryptoPaymentMethodsAdminService {
  private isAdmin: boolean;
  private isError = false;

  constructor(
    private store: Store<AppStateModel>,
    private messageService: MessageService,
    private actions: Actions,
  ) {
    combineLatest([
      this.store.pipe(select(selectAdminCryptoPaymentMethods)),
      this.store.pipe(select(selectUser)),
    ]).subscribe(([cryptoPaymentMethods, user]) => {
      this.isAdmin = user.data?.admin;
      if (cryptoPaymentMethods.initial) {
        this.refresh();
      }
    });

    this.actions.pipe(ofType(getCryptoPaymentMethodsAdminActionError)).subscribe(({ errors }) => {
      this.messageService.showErrors(errors, `Admin Crypto Payment Method List Error`);
      this.isError = true;
    });
  }

  public refresh(): void {
    this.isError = false;
    if (this.isAdmin) {
      this.store.dispatch(getCryptoPaymentMethodsAdminAction());
    }
  }

  public getPaymentMethods(): Observable<AdminCryptoPaymentMethodTo[]> {
    if (this.isError) {
      this.refresh();
    }

    return this.store.pipe(
      select(selectAdminCryptoPaymentMethods),
      filter((state) => state != null && state.data != null),
      map((state) => state.data),
    );
  }

  public getPaymentMethodCurrencyCode(paymentMethodId: string): Observable<string> {
    return this.getPaymentMethods().pipe(
      map((paymentMethods) => paymentMethods?.find((paymentMethod) => paymentMethod.id === paymentMethodId)?.asset?.id),
    );
  }

  public getPaymentMethodsWithPayoutEnabled(): Observable<AdminCryptoPaymentMethodTo[]> {
    return this.getPaymentMethods().pipe(
      map((paymentMethods) => paymentMethods.filter((paymentMethod) => paymentMethod.payoutEnabled)),
    );
  }

  public updatePaymentMethod(paymentMethod: AdminCryptoPaymentMethodUpdateTo, paymentMethodId: string): void {
    this.store.dispatch(
      updateCryptoPaymentMethodAdminAction({
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethodId,
      }),
    );
  }
}
