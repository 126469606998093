import { createReducer, on } from '@ngrx/store';
import { ANY_FILTER_VALUE } from '../../constants';
import { Time } from '../../enums/time.enum';
import { getPayoutPageFiltersAction, updatePayoutPageFiltersAction } from './payout-page-filters.actions';
import { PayoutPageFiltersModel } from './payout-page-filters.model';

export type PayoutPageFiltersState = PayoutPageFiltersModel;

export const PAYOUT_PAGE_FILTERS_ANY_STATE: PayoutPageFiltersState = {
  currencyFrom: ANY_FILTER_VALUE,
  paymentMethodId: null,
  status: ANY_FILTER_VALUE,
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const PAYOUT_PAGE_FILTERS_INITIAL_STATE: PayoutPageFiltersState = {
  currencyFrom: ANY_FILTER_VALUE,
  paymentMethodId: null,
  status: ANY_FILTER_VALUE,
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: null,
    createdAtTo: null,
  },
};

export const payoutPageFiltersReducer = createReducer(
  PAYOUT_PAGE_FILTERS_INITIAL_STATE,
  on(getPayoutPageFiltersAction, (state) => ({
    ...state,
  })),
  on(updatePayoutPageFiltersAction, (state, { filters }) => ({
    ...filters,
  })),
);
