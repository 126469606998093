export enum FinancialReportType {
  INVOICE = 'INVOICE',
  PAYOUT = 'PAYOUT',
  MERCHANT = 'MERCHANT',
}

export enum FinancialReportValueType {
  TX = 'TX',
  TURNOVER = 'TURNOVER',
  TURNOVER_REF_CURRENCY = 'TURNOVER_REF_CURRENCY',
  INVOICE_FEE_SUM = 'INVOICE_FEE_SUM',
  PAYOUT_FEE_SUM = 'PAYOUT_FEE_SUM',
  NO_EXCEPTION_AMOUNT = 'NO_EXCEPTION_AMOUNT',
  NETWORK_FEE_SUM = 'NETWORK_FEE_SUM',
  TOTAL_PROFIT = 'TOTAL_PROFIT',
  TOTAL_PROFIT_REF_CURRENCY = 'TOTAL_PROFIT_REF_CURRENCY',
  REGISTERED_MERCHANTS = 'REGISTERED_MERCHANTS',
  VERIFICATIONS_COMPLETED = 'VERIFICATIONS_COMPLETED',
  MERCHANTS_WITH_INVOICES = 'MERCHANTS_WITH_INVOICES',
}

export enum FinancialReportVersion {
  V1 = 1,
  V2 = 2,
}
