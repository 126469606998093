/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { OtherSideTransaction, OtherSideTransactionForAdmin } from '../models/api/other-side-transaction.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum OtherSideTransactionActionTypes {
  GetOtherSideTransaction = 'otherSideTransaction/GET_REQUEST',
  GetOtherSideTransactionsSuccess = 'otherSideTransaction/GET_SUCCESS',
  GetOtherSideTransactionsError = 'otherSideTransaction/GET_ERROR',
  RetryWithdraw = 'otherSideTransaction/RETRY_WITHDRAW_REQUEST',
  RetryWithdrawSuccess = 'otherSideTransaction/RETRY_WITHDRAW_SUCCESS',
  RetryWithdrawError = 'otherSideTransaction/RETRY_WITHDRAW_ERROR',
  GetOtherSideTransactionsList = 'otherSideTransactionList/GET_REQUEST',
  GetOtherSideTransactionsListSuccess = 'otherSideTransactionList/GET_SUCCESS',
  GetOtherSideTransactionsListError = 'otherSideTransactionList/GET_ERROR',
  CreateOtherSideTransaction = 'otherSideTransaction/CREATE_REQUEST',
  Create2FAOtherSideTransaction = 'otherSideTransaction/CREATE_2FA_REQUEST',
  CreateOtherSideTransactionSuccess = 'otherSideTransaction/CREATE_SUCCESS',
  CreateOtherSideTransactionError = 'otherSideTransaction/CREATE_ERROR',
  ConfirmOtherSideTransaction = 'otherSide/CONFIRM_REQUEST',
  ConfirmOtherSideTransactionSuccess = 'otherSide/CONFIRM_SUCCESS',
  ConfirmOtherSideTransactionError = 'otherSide/CONFIRM_ERROR',
  CancelOtherSideTransaction = 'otherSide/CANCEL_REQUEST',
  CancelOtherSideTransactionSuccess = 'otherSide/CANCEL_SUCCESS',
  CancelOtherSideTransactionError = 'otherSide/CANCEL_ERROR',
  PostExportRequest = 'payout/POST_EXPORT_REQUEST',
  PostExportSuccess = 'payout/POST_EXPORT_SUCCESS',
  PostExportError = 'payout/POST_EXPORT_ERROR',
}

export const getOtherSideTransactionAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransaction,
  props<{ transactionId: string; isAdmin: boolean }>(),
);

export const getOtherSideTransactionsSuccessAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransactionsSuccess,
  props<{ transaction: OtherSideTransaction }>(),
);

export const getOtherSideTransactionsErrorAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransactionsError,
  props<{ errors: ErrorModel[] }>(),
);

export const retryWithdrawAction = createAction(
  OtherSideTransactionActionTypes.RetryWithdraw,
  props<{ transactionId: string }>(),
);

export const retryWithdrawSuccessAction = createAction(
  OtherSideTransactionActionTypes.RetryWithdrawSuccess,
  props<{ transaction: OtherSideTransaction }>(),
);

export const retryWithdrawErrorAction = createAction(
  OtherSideTransactionActionTypes.RetryWithdrawError,
  props<{ errors: ErrorModel[] }>(),
);

export const getOtherSideTransactionListAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransactionsList,
  props<{ paging: PageRequestModel; isAdmin: boolean; filters: PageRequestFiltersModel }>(),
);

export const getOtherSideTransactionsListSuccessAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransactionsListSuccess,
  props<{ transactions: PagingModel<OtherSideTransactionForAdmin> }>(),
);

export const getOtherSideTransactionsListErrorAction = createAction(
  OtherSideTransactionActionTypes.GetOtherSideTransactionsListError,
  props<{ errors: ErrorModel[] }>(),
);

export const createOtherSideTransactionAction = createAction(
  OtherSideTransactionActionTypes.CreateOtherSideTransaction,
  props<{ otherSideTransaction: OtherSideTransaction }>(),
);

export const create2FAOtherSideTransactionAction = createAction(
  OtherSideTransactionActionTypes.Create2FAOtherSideTransaction,
  props<{ otherSideTransaction: OtherSideTransaction; twoFa: string }>(),
);

export const createOtherSideTransactionSuccessAction = createAction(
  OtherSideTransactionActionTypes.CreateOtherSideTransactionSuccess,
  props<{ otherSideTransaction: OtherSideTransaction }>(),
);

export const createOtherSideTransactionErrorAction = createAction(
  OtherSideTransactionActionTypes.CreateOtherSideTransactionError,
  props<{ errors: ErrorModel[] }>(),
);

export const confirmOtherSideTransactionAction = createAction(
  OtherSideTransactionActionTypes.ConfirmOtherSideTransaction,
  props<{ hashId: string; twoFa: string }>(),
);

export const confirmOtherSideTransactionSuccessAction = createAction(
  OtherSideTransactionActionTypes.ConfirmOtherSideTransactionSuccess,
  props<{ transaction: OtherSideTransaction }>(),
);

export const confirmOtherSideTransactionErrorAction = createAction(
  OtherSideTransactionActionTypes.ConfirmOtherSideTransactionError,
  props<{ errors: ErrorModel[] }>(),
);

export const cancelOtherSideTransactionAction = createAction(
  OtherSideTransactionActionTypes.CancelOtherSideTransaction,
  props<{ hashId: string }>(),
);

export const cancelOtherSideTransactionSuccessAction = createAction(
  OtherSideTransactionActionTypes.CancelOtherSideTransactionSuccess,
  props<{ transaction: OtherSideTransaction }>(),
);

export const cancelOtherSideTransactionErrorAction = createAction(
  OtherSideTransactionActionTypes.CancelOtherSideTransactionError,
  props<{ errors: ErrorModel[] }>(),
);

export const payoutExportAction = createAction(
  OtherSideTransactionActionTypes.PostExportRequest,
  props<{ paging: PageRequestModel; isAdmin: boolean; filters: PageRequestFiltersModel; uuid: string }>(),
);

export const payoutExportSuccessAction = createAction(OtherSideTransactionActionTypes.PostExportSuccess);

export const payoutExportErrorAction = createAction(
  OtherSideTransactionActionTypes.PostExportError,
  props<{ errors: ErrorModel[] }>(),
);
