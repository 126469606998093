import { createReducer, on } from '@ngrx/store';
import { MerchantBalanceModel } from '../../models/api/merchant-balance.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getMerchantBalancesAction,
  getMerchantBalancesErrorAction,
  getMerchantBalancesSuccessAction,
} from './merchant-balance.actions';

export type MerchantBalanceState = StateModel<MerchantBalanceModel[]>;

const INITIAL_STATE: MerchantBalanceState = {
  errors: null,
  data: [],
};

export const merchantBalanceReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantBalancesAction, (state) => ({
    errors: null,
    data: null,
  })),
  on(getMerchantBalancesSuccessAction, (state, { merchantBalances }) => ({
    errors: null,
    data: merchantBalances,
  })),
  on(getMerchantBalancesErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
