import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  getMarketPageAction,
  getMarketPageErrorAction,
  getMarketPageSuccessAction,
  retryMarketEntryAction,
  retryMarketEntryErrorAction,
  retryMarketEntrySuccessAction,
} from '../actions/market-page.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';

const MARKET_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/markets`;
const MARKETS_RETRY_ENDPOINT = `${MARKET_ENDPOINT}/{id}/retry`;

@Injectable()
export class MarketPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMarketPageAction),
      mergeMap(({ paging, filters, entityType, showHidden }) => {
        return this.api
          .get(
            `${MARKET_ENDPOINT}${queryParams({
              ...paging,
              ...filters,
              entityType: entityType,
              showHidden: showHidden,
            })}`,
          )
          .pipe(
            map((marketOrders) => getMarketPageSuccessAction({ marketOrders })),
            catchError((errors) => observableOf(getMarketPageErrorAction(errors))),
          );
      }),
    ),
  );

  retryMarketEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retryMarketEntryAction),
      switchMap(({ marketOrderId }) => {
        return this.api.post(this.marketsRetryUrl(marketOrderId)).pipe(
          map((response) => retryMarketEntrySuccessAction()),
          catchError((errors) => observableOf(retryMarketEntryErrorAction(errors))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  marketsRetryUrl(id: string): string {
    return MARKETS_RETRY_ENDPOINT.replace('{id}', id);
  }
}
