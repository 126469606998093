/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantAdminUpdateTo, MerchantModel } from '../models/api/merchant.model';

export enum merchantDetailActions {
  GET_MERCHANT_BY_ADMIN_REQUEST = 'merchantDetail/GET_MERCHANT_BY_ADMIN_REQUEST',
  GET_MERCHANT_BY_ADMIN_SUCCESS = 'merchantDetail/GET_MERCHANT_BY_ADMIN_SUCCESS',
  GET_MERCHANT_BY_ADMIN_ERROR = 'merchantDetail/GET_MERCHANT_BY_ADMIN_ERROR',

  UPDATE_MERCHANT_BY_ADMIN_ACTION = 'merchantDetail/UPDATE_MERCHANT_BY_ADMIN',
  UPDATE_MERCHANT_BY_ADMIN_SUCCESS_ACTION = 'merchantDetail/UPDATE_MERCHANT_BY_ADMIN_SUCCESS',
  UPDATE_MERCHANT_BY_ADMIN_ERROR_ACTION = 'merchantDetail/UPDATE_MERCHANT_BY_ADMIN_ERROR',

  DELETE_MERCHANT_BY_ADMIN_ACTION = 'merchantDetail/DELETE_MERCHANT_BY_ADMIN',
  DELETE_MERCHANT_BY_ADMIN_SUCCESS_ACTION = 'merchantDetail/DELETE_MERCHANT_BY_ADMIN_SUCCESS',
  DELETE_MERCHANT_BY_ADMIN_ERROR_ACTION = 'merchantDetail/DELETE_MERCHANT_BY_ADMIN_ERROR',
}
export const getMerchantDetailByAdminAction = createAction(
  merchantDetailActions.GET_MERCHANT_BY_ADMIN_REQUEST,
  props<{ merchantId: string }>(),
);

export const getMerchantDetailByAdminSuccessAction = createAction(
  merchantDetailActions.GET_MERCHANT_BY_ADMIN_SUCCESS,
  props<{ merchant: MerchantModel }>(),
);

export const getMerchantDetailByAdminErrorAction = createAction(
  merchantDetailActions.GET_MERCHANT_BY_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantByAdminAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_BY_ADMIN_ACTION,
  props<{ hashId: string; merchant: MerchantAdminUpdateTo }>(),
);

export const updateMerchantByAdminSuccessAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_BY_ADMIN_SUCCESS_ACTION,
);

export const updateMerchantByAdminErrorAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_BY_ADMIN_ERROR_ACTION,
  props<{ errors: ErrorModel[] }>(),
);

export const deleteMerchantByAdminAction = createAction(
  merchantDetailActions.DELETE_MERCHANT_BY_ADMIN_ACTION,
  props<{ hashId: string }>(),
);

export const deleteMerchantByAdminSuccessAction = createAction(
  merchantDetailActions.DELETE_MERCHANT_BY_ADMIN_SUCCESS_ACTION,
);

export const deleteMerchantByAdminErrorAction = createAction(
  merchantDetailActions.DELETE_MERCHANT_BY_ADMIN_ERROR_ACTION,
  props<{ errors: ErrorModel[] }>(),
);
