import { Component, HostListener } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  finishMerchantDocumentAction,
  finishMerchantDocumentErrorAction,
  finishMerchantDocumentSuccessAction,
  getMerchantDocumentsAction,
  uploadMerchantDocumentsAction,
  uploadMerchantDocumentsErrorAction,
  uploadMerchantDocumentsSuccessAction,
} from '../../../../shared/actions/merchant-document.actions';
import { getMerchantProfileAction } from '../../../../shared/actions/merchant-profile.actions';
import { getParameterAction } from '../../../../shared/actions/parameter.actions';
import { wizardNextStepAction, wizardStepBackAction } from '../../../../shared/actions/wizard.actions';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { MAX_FILE_SIZE, MERCHANT_FILE_ALLOWED_EXTENSIONS } from '../../../../shared/constants';
import { MerchantFileType } from '../../../../shared/enums/merchant-file-type.enum';
import { MerchantProfileType } from '../../../../shared/enums/merchant-profile-type.enum';
import { MerchantProfileVerificationStatus } from '../../../../shared/enums/merchant-profile-verification-status.enum';
import { ComponentCanDeactivate } from '../../../../shared/guards/can-deactivate.guard';
import { CompanyMerchantProfileModel } from '../../../../shared/models/api/merchant-profile/company-merchant-profile.model';
import { MerchantDocument } from '../../../../shared/models/api/merchant-profile/merchant-document.model';
import { AppStateModel } from '../../../../shared/models/auxiliary/app-state.model';
import { selectMerchantDocument } from '../../../../shared/selectors/merchant-document.selector';
import { selectMerchantProfile } from '../../../../shared/selectors/merchant-profile.selectors';
import { selectParameter } from '../../../../shared/selectors/parameter.selector';
import { MessageService } from '../../../../shared/services/message.service';

@Component({
  selector: 'bp-merchant-documents',
  templateUrl: './merchant-documents.component.html',
  styleUrls: ['./merchant-documents.component.scss'],
})
export class MerchantDocumentsComponent extends AbstractComponent implements ComponentCanDeactivate {
  files: MerchantDocument[];
  merchantProfile: CompanyMerchantProfileModel;
  errorMessage: string;
  link: string;
  finishDispatched = false;
  dragOver = false;
  MAX_FILE_NAME_LENGTH = 100;
  MAX_FILE_AMOUNT = '20';

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    private messageService: MessageService,
  ) {
    super();

    this.store
      .select(selectMerchantDocument)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'Uploaded Documents Error');
          return;
        }

        this.files = state.data;
      });

    this.store
      .select(selectMerchantProfile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'Merchant Verification Info Error');
          return;
        }

        if (state.data == null) {
          return;
        }

        this.merchantProfile = <CompanyMerchantProfileModel>state.data;
        this.link = this.merchantProfile.publicRegistryUrl;
      });

    this.actions
      .pipe(ofType(uploadMerchantDocumentsSuccessAction), takeUntil(this.unsubscribe$))
      .subscribe(() => this.messageService.success('Files were successfully uploaded'));

    this.actions
      .pipe(ofType(uploadMerchantDocumentsErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => this.messageService.showErrors(errors, 'Files Upload Error'));

    this.actions.pipe(ofType(finishMerchantDocumentSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.store.dispatch(wizardNextStepAction());
    });

    this.actions
      .pipe(ofType(finishMerchantDocumentErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => this.messageService.showErrors(errors, 'Finish Error'));

    this.setMaxFilesSubscriber();
    this.store.dispatch(getMerchantProfileAction());
    this.store.dispatch(getMerchantDocumentsAction());
    this.store.dispatch(getParameterAction(null));
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.finishDisabled || this.finishDispatched;
  }

  filesChanged(event: any): void {
    if (!event.target.files || event.target.files.length < 1) {
      return;
    }

    this.addFiles(event.target.files);
  }

  addFiles(files: File[]): void {
    const form = new FormData();

    for (const file of files) {
      if (file.name.length > this.MAX_FILE_NAME_LENGTH) {
        this.messageService.error(`File name exceeded ${this.MAX_FILE_NAME_LENGTH} characters`);
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        this.messageService.error(`File size exceeded ${MAX_FILE_SIZE / 1024 / 1024}MB`);
        return;
      }

      const ext = file.name.split('.').pop().toLocaleLowerCase();
      if (!MERCHANT_FILE_ALLOWED_EXTENSIONS.includes(ext)) {
        this.messageService.error(
          'File extension is not supported. Supported types are: pdf, jpeg, jpg, png, gif, doc, docx',
        );
        return;
      }

      form.append('files', file);
    }

    this.store.dispatch(uploadMerchantDocumentsAction({ files: form }));
  }

  finish(): void {
    if (this.containsWhitespace(this.link)) {
      this.messageService.error("Link to commercial register can't start or end with whitespace");
      return;
    }

    this.finishDispatched = true;
    this.store.dispatch(finishMerchantDocumentAction({ body: { publicRegistryUrl: this.link } }));
  }

  get uploadMoreDocumentDisabled(): boolean {
    return this.files && this.files.filter((x) => x.documentType === MerchantFileType.COMPANY_DOCUMENT).length >= 20;
  }

  get finishDisabled(): boolean {
    if (this.files == null || this.files.length < 1 || this.merchantProfile == null) {
      return true;
    }

    //
    if (this.merchantProfile.verificationStatus === MerchantProfileVerificationStatus.DOCUMENTS_NEEDED) {
      // do not allow finishing when there was not any document uploaded since the last merchant profile status update
      return !this.files.some((file) => file.createdAt > this.merchantProfile.verificationStatusUpdatedAt);
    }

    return false;
  }

  get supportedTypes(): string {
    return MERCHANT_FILE_ALLOWED_EXTENSIONS.join(', ');
  }

  public stepBack(): void {
    this.store.dispatch(wizardStepBackAction());
  }

  setDragOver(dragOver: boolean): void {
    this.dragOver = dragOver;
  }

  private containsWhitespace(text: string): boolean {
    if (text === undefined || text === null) {
      return false;
    }

    const trimmed = text.trim();
    return trimmed !== text;
  }

  private setMaxFilesSubscriber(): void {
    this.store
      .select(selectParameter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state === null) {
          return;
        }
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'Error getting parameters');
          return;
        }
        if (state.data) {
          this.MAX_FILE_AMOUNT = state.data;
        }
      });
  }

  get showBackButton(): Boolean {
    return this.merchantProfile.type === MerchantProfileType.COMPANY;
  }
}
