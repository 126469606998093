import { createReducer, on } from '@ngrx/store';
import {
  clearAction,
  getApiKeyAction,
  getApiKeyErrorAction,
  getApiKeySuccessAction,
} from '../actions/api-key-detail.actions';
import { ApiKeyModel } from '../models/api/api-key.model';
import { StateModel } from '../models/auxiliary/state.model';

export type ApiKeyDetailState = StateModel<ApiKeyModel>;

const INITIAL_STATE: ApiKeyDetailState = {
  errors: null,
  data: null,
};

export const apiKeyDetailReducer = createReducer(
  INITIAL_STATE,
  on(getApiKeyAction, clearAction, () => ({ ...INITIAL_STATE })),
  on(getApiKeySuccessAction, (state, { apiKey }) => ({
    data: apiKey,
    errors: null,
  })),
  on(getApiKeyErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
