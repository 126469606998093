import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  getLimitsAndFeesAction,
  getLimitsAndFeesErrorAction,
  getLimitsAndFeesSuccessAction,
  updateLimitsAndFeesAction,
  updateLimitsAndFeesErrorAction,
  updateLimitsAndFeesSuccessAction,
} from '../actions/limits-and-fees.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';
import { MessageService } from '../services/message.service';

export const LIMITS_AND_FEES_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/global/configuration/{feesType}`;

@Injectable()
export class LimitsAndFeesEffect {
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(ApiService);
  private readonly messageService = inject(MessageService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLimitsAndFeesAction),
      mergeMap(({ feesType }) =>
        this.apiService.get(LIMITS_AND_FEES_ENDPOINT.replace('{feesType}', feesType)).pipe(
          map((limitsAndFees) => getLimitsAndFeesSuccessAction({ limitsAndFees })),
          catchError((errors) => observableOf(getLimitsAndFeesErrorAction(errors))),
        ),
      ),
    ),
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLimitsAndFeesAction),
      mergeMap(({ limitsAndFees }) =>
        this.apiService.put(LIMITS_AND_FEES_ENDPOINT.replace('{feesType}', limitsAndFees.type), limitsAndFees).pipe(
          map((limitsAndFees) => updateLimitsAndFeesSuccessAction({ limitsAndFees })),
          catchError((errors) => observableOf(updateLimitsAndFeesErrorAction(errors))),
        ),
      ),
    ),
  );

  showSuccessMessageOnSettingsUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateLimitsAndFeesSuccessAction),
        tap(() => {
          this.messageService.success(`Settings successfully updated`);
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnSettingsUpdate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateLimitsAndFeesErrorAction),
        tap(({ errors }) => {
          this.messageService.showErrors(errors, `Settings could not be updated updated`);
        }),
      ),
    { dispatch: false },
  );
}
