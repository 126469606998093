import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './config';

/**
 * This is workaround to load configuration dynamically.
 * For more details, see config.ts file.
 *
 * Warning: This might bring some issues in AOT mode.
 */
fetch('/assets/app-config.json')
  .then(async (response) => {
    const json = await response.json();

    APP_CONFIG.config = json;

    if (json.production) {
      enableProdMode();
    }
  })
  .catch(() => {
    console.warn('Could not load app-config.json');
  })
  .finally(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
  });
