import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private messageService: MessageService) {}

  uploadFiles(files: File[], multiple: boolean, maxFileSize: number, allowedExtensions: string[]): File | File[] {
    if (files && files.length > 0) {
      if (files.length === 1) {
        return this.isValidFile(files[0], maxFileSize, allowedExtensions) ? files[0] : null;
      } else if (files.length > 1 && multiple) {
        return files.filter((file) => this.isValidFile(file, maxFileSize, allowedExtensions));
      } else {
        this.messageService.error('Uploading multiple files is not allowed');
      }
    }
  }

  isValidFile(file: File, maxFileSize: number, allowedExtensions: string[]): boolean {
    if (maxFileSize && file.size > maxFileSize) {
      this.messageService.error(`File (${file.name}) size exceeded ${maxFileSize / 1024 / 1024}MB`);
      return false;
    }

    const ext = file.name.split('.').pop().toLocaleLowerCase();
    if (allowedExtensions && !allowedExtensions.includes(ext)) {
      this.messageService.error(`File (${file.name}) extension is not supported. Supported types are:
       ${allowedExtensions.toString()}`);
      return false;
    }

    return true;
  }
}
