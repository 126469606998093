import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { CryptoProvider } from '../platform/admin/crypto-wallets-statistics/crypto-providers.model';
import { LanguageCode, LanguageName } from './enums/language.enum';
import { MerchantProfileVerificationStatus } from './enums/merchant-profile-verification-status.enum';
import { Time } from './enums/time.enum';
import { LangSelectModel } from './models/api/lang-select.model';
import { MerchantStatus } from './models/api/merchant-status.enum';
import { SelectItemModel } from './models/auxiliary/select-item.model';

export const ADMIN_ENDPOINT_PREFIX = 'admin';
export const API_VERSION = 'v3';
export const LIST_ITEMS_DEFAULT_LIMIT = 20;
export const PUBLIC_PAGE_DEFAULT_LANGUAGE = { name: LanguageName.ENGLISH, code: LanguageCode.ENGLISH };
export const PUBLIC_PAGE_LANGUAGES: LangSelectModel[] = [
  { name: LanguageName.ENGLISH, code: LanguageCode.ENGLISH },
  { name: LanguageName.CZECH, code: LanguageCode.CZECH },
  { name: LanguageName.GERMAN, code: LanguageCode.GERMAN },
  { name: LanguageName.ITALIAN, code: LanguageCode.ITALIAN },
  { name: LanguageName.JAPANESE, code: LanguageCode.JAPANESE },
  { name: LanguageName.CHINESE_SIMPLIFIED, code: LanguageCode.CHINESE_SIMPLIFIED },
  { name: LanguageName.CHINESE_TRADITIONAL, code: LanguageCode.CHINESE_TRADITIONAL },
];

export const CHECK_VISIBILITY_INTERVAL = 500;

export const ANY_FILTER_VALUE = '@#default#@';
export const CUSTOM_RANGE = 'CUSTOM_RANGE';
export const CUSTOM_RANGE_SELECT_ITEM = { label: 'Custom range', value: CUSTOM_RANGE };

export const FILTERED_TIMES: SelectItemModel[] = [
  { label: 'Any time', value: ANY_FILTER_VALUE },
  { label: 'Past 24 hours', value: Time.DAY },
  { label: 'Past 7 days', value: Time.WEEK },
  { label: 'Past 30 days', value: Time.MONTH },
];

export const DEFAULT_SORTING_BY_VALUE = 'createdAt';
export const ASC_SORTING_DIRECTION = 'asc';
export const DESC_SORTING_DIRECTION = 'desc';

export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const MERCHANT_FILE_ALLOWED_EXTENSIONS = ['pdf', 'jpeg', 'jpg', 'png', 'gif', 'doc', 'docx'];

export const CUSTOMER_TOKEN_LENGTH = 32;
export const WEEKDAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

/**
 * Email regex downloaded from: http://emailregex.com/
 */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CUSTOMIZED_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY', // this is how date will be parsed from Input
  },
  display: {
    dateInput: 'DD.MM.YYYY', // this is how a date will get displayed on the Input
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
export const DATE_FILTER_MONTH_FORMAT = 'MMM YYYY';
export const NEVER_ACTIVE_INVOICE_STATES = ['prepared', 'expired'];

// positive lookahead is used for performance optimization because JS doesn't support possessive group
// see http://instanceof.me/post/52245507631/regex-emulate-atomic-grouping-with-lookahead for more details
export const MOBILE_PHONE_NUMBER_REGEX = /^\+?(?=(([0-9]){1,14}))\1$/;
export const POSITIVE_NUMBER_REGEX = /^[1-9]+[0-9]*$/;
export const appActions = {
  CLEAR: 'app/CLEAR',
};

export const ANIMATIONS = {
  animateStep: trigger('stepAnimation', [
    transition(':increment', [
      query(':enter', [
        style({ opacity: 0, transform: 'translate(5%)' }),
        group([
          animate('0.4s ease', style({ opacity: 1 })),
          animate('0.8s cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translate(0)' })),
        ]),
      ]),
    ]),
    transition(':decrement', [
      query(':enter', [
        style({ opacity: 0, transform: 'translate(-5%)' }),
        group([
          animate('0.4s ease', style({ opacity: 1 })),
          animate('0.8s cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translate(0)' })),
        ]),
      ]),
    ]),
  ]),
  thereAndBackAnimation: trigger('thereAndBackAnimation', [
    transition('false => true', [
      query(':enter', [
        style({ opacity: 0, transform: 'translateX(7%)' }),
        group([
          animate('0.3s 0s ease', style({ opacity: 1 })),
          animate('0s 0.5s cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(0)' })),
        ]),
      ]),
    ]),
    transition('true => false', [
      query(':enter', [
        style({ opacity: 0, transform: 'translateX(-7%)' }),
        group([
          animate('0.3s 0s ease', style({ opacity: 1 })),
          animate('0s 0.5s cubic-bezier(0.19, 1, 0.22, 1)', style({ transform: 'translateX(0)' })),
        ]),
      ]),
    ]),
  ]),
  openCloseAnimation: (height) =>
    trigger('openCloseAnimation', [
      state(
        'hide',
        style({
          bottom: `-${height}px`,
        }),
      ),
      state(
        'show',
        style({
          bottom: '0',
        }),
      ),
      transition('hide => show', animate('1000ms')),
      transition('show => hide', animate('600ms')),
    ]),
};

export const ECOMMERCE_PLUGIN_LIST = [
  {
    name: 'PrestaShop',
    version: 'V1.5 - V1.7.*',
    link: 'https://github.com/confirmo/confirmo-prestashop',
    iconSrc: 'assets/developers/presta-shop.svg',
  },
  {
    name: 'Shopify',
    version: 'Shopify',
    link: 'https://platebni-brany.cz/register/confirmo',
    iconSrc: 'assets/developers/shopify.svg',
  },
];

export const CONFIRMO_ESTABLISHMENT_DATE = '01.01.2014';

export const CRYPTO_PROVIDERS_OPTION: SelectItemModel[] = [
  { label: 'Fireblocks', value: CryptoProvider.FIREBLOCKS },
  { label: 'Coinmate', value: CryptoProvider.COINMATE },
];

export const MERCHANT_STATUS_OPTIONS: SelectItemModel[] = [
  { label: 'New', value: MerchantStatus.NEW },
  { label: 'Testing', value: MerchantStatus.TESTING },
  { label: 'Regular', value: MerchantStatus.REGULAR },
  { label: 'Suspended', value: MerchantStatus.SUSPENDED },
  { label: 'Ghost', value: MerchantStatus.GHOST },
];

export const MERCHANT_VERIFICATION_STATUS_OPTIONS: SelectItemModel[] = [
  { label: 'Not verified', value: MerchantProfileVerificationStatus.NOT_VERIFIED },
  { label: 'Basic info entered', value: MerchantProfileVerificationStatus.BASIC_INFO_ENTERED },
  { label: 'Documents check', value: MerchantProfileVerificationStatus.DOCUMENTS_CHECK },
  { label: 'Documents needed', value: MerchantProfileVerificationStatus.DOCUMENTS_NEEDED },
  { label: 'Verified', value: MerchantProfileVerificationStatus.VERIFIED },
  { label: 'Rejected', value: MerchantProfileVerificationStatus.REJECTED },
  { label: 'Idenfy verification', value: MerchantProfileVerificationStatus.IDENFY_VERIFICATION },
  { label: 'Idenfy completed', value: MerchantProfileVerificationStatus.IDENFY_COMPLETED },
];
