/**
 * Type of profile person.
 */
export enum PersonType {
  /** Physical merchant info. */
  PERSON = 'PERSON',
  /** Company director. */
  DIRECTOR = 'DIRECTOR',
  /** Other company person.*/
  OTHER_COMPANY_PERSON = 'OTHER_COMPANY_PERSON',
}
