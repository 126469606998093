import { createReducer, on } from '@ngrx/store';
import {
  getOpenOrdersStatisticsErrorAction,
  getOpenOrdersStatisticsSuccessAction,
} from '../actions/open-orders-statistics.actions';
import { OpenOrdersStatistics } from '../models/api/market-operation.model';
import { StateModel } from '../models/auxiliary/state.model';

export type OpenOrdersStatisticsState = StateModel<OpenOrdersStatistics>;

const INITIAL_STATE: OpenOrdersStatisticsState = {
  errors: null,
  data: null,
};

export const openOrdersStatisticsReducer = createReducer(
  INITIAL_STATE,
  on(getOpenOrdersStatisticsSuccessAction, (state, { statistics }) => ({
    errors: null,
    data: statistics,
  })),
  on(getOpenOrdersStatisticsErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
