import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX } from '../../constants';
import { ApiService } from '../../services/api.service';
import {
  getMerchantBalancesAction,
  getMerchantBalancesErrorAction,
  getMerchantBalancesSuccessAction,
} from './merchant-balance.actions';

const MERCHANT_BALANCE_ENDPOINT = '/v3/balances';
const ADMIN_BALANCE_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantId}/balances`;

@Injectable()
export class MerchantBalanceEffect {
  getDetailBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantBalancesAction),
      mergeMap(({ merchantId }) => {
        return this.api.get(this.url(merchantId)).pipe(
          map((merchantBalances) => getMerchantBalancesSuccessAction({ merchantBalances })),
          catchError((errors) => observableOf(getMerchantBalancesErrorAction(errors))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  url(merchantId: string): string {
    if (merchantId) {
      return ADMIN_BALANCE_ENDPOINT.replace('{merchantId}', merchantId);
    }
    return `${MERCHANT_BALANCE_ENDPOINT}`;
  }
}
