import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MerchantFileVerificationStatus } from '../../shared/enums/merchant-file-verification-status.enum';
import { MerchantDocument } from '../../shared/models/api/merchant-profile/merchant-document.model';
import { MerchantProfileStatusModel } from '../../shared/models/api/merchant-profile/merchant-profile-status.model';
import { MerchantProfileUnionModel } from '../../shared/models/api/merchant-profile/merchant-profile-union.model';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AmlAdminService {
  public readonly baseUrl: string;
  public readonly profileUrl: string;
  public readonly statusUrl: string;
  public readonly filesUrl: string;
  public readonly fileDownloadUrl: string;
  public readonly fileStatusUrl: string;
  public readonly verificationResetUrl: string;

  constructor(protected api: ApiService) {
    this.baseUrl = `/admin/merchants/{merchantId}`;
    this.profileUrl = `${this.baseUrl}/merchant-profile`;
    this.statusUrl = `${this.profileUrl}/status`;
    this.filesUrl = `${this.profileUrl}/files`;
    this.fileDownloadUrl = `${this.filesUrl}/{fileId}`;
    this.fileStatusUrl = `${this.fileDownloadUrl}/status`;
    this.verificationResetUrl = `${this.baseUrl}/merchant-profile/reset-verification`;
  }

  getMerchantProfile(merchantId: string): Observable<MerchantProfileUnionModel> {
    const url = this.profileUrl.replace('{merchantId}', merchantId);

    return this.api.get(url);
  }

  getMerchantDocuments(merchant: MerchantProfileUnionModel): Observable<MerchantDocument[]> {
    const url = this.filesUrl.replace('{merchantId}', merchant.merchantId);

    return this.api.get(url);
  }

  changeVerificationStatus(
    merchant: MerchantProfileUnionModel,
    verificationStatus: MerchantProfileStatusModel,
  ): Observable<any> {
    const url = this.statusUrl.replace('{merchantId}', merchant.merchantId);

    return this.api.put(url, verificationStatus);
  }

  changeFileStatus(
    file: MerchantDocument,
    status: MerchantFileVerificationStatus,
    merchant: MerchantProfileUnionModel,
  ): Observable<MerchantDocument> {
    const url = this.fileStatusUrl.replace('{fileId}', file.id).replace('{merchantId}', merchant.merchantId);

    return this.api.put(url, JSON.stringify(status), { 'Content-Type': 'application/json' });
  }

  downloadFile(file: MerchantDocument, merchant: MerchantProfileUnionModel): Observable<HttpResponse<Blob>> {
    const url = this.getFileUrl(file, merchant);
    return this.api.downloadFile(url);
  }

  uploadFile(file: FormData, merchant: MerchantProfileUnionModel): Observable<MerchantDocument[]> {
    const url = this.filesUrl.replace('{merchantId}', merchant.merchantId);
    return this.api.post(url, file);
  }

  getFileUrl(file: MerchantDocument, merchant: MerchantProfileUnionModel): string {
    return this.fileDownloadUrl.replace('{fileId}', file.id).replace('{merchantId}', merchant.merchantId);
  }

  resetVerification(merchantId: string, privateComment: string): Observable<MerchantProfileUnionModel> {
    return this.api.put(this.getResetVerificationUrl(merchantId), privateComment);
  }

  private getResetVerificationUrl(merchantId: string): string {
    return this.verificationResetUrl.replace('{merchantId}', merchantId);
  }
}
