/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { InvoiceModel } from '../models/api/invoice.model';
import { PageRequestFiltersModel, PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum invoicePageActions {
  GET_REQUEST = 'invoicePage/GET_REQUEST',
  POST_EXPORT_REQUEST = 'invoicePage/POST_EXPORT_REQUEST',
  GET_SUCCESS = 'invoicePage/GET_SUCCESS',
  POST_EXPORT_SUCCESS = 'invoicePage/POST_EXPORT_SUCCESS',
  POST_EXPORT_ERROR = 'invoicePage/POST_EXPORT_ERROR',
  GET_ERROR = 'invoicePage/GET_ERROR',

  GET_SELECTED_REQUEST = 'invoicePage/GET_SELECTED_REQUEST',
  GET_SELECTED_SUCCESS = 'invoicePage/GET_SELECTED_SUCCESS',
  GET_SELECTED_ERROR = 'invoicePage/GET_SELECTED_ERROR',

  GET_EMAIL_INVOICE_INVOICES_REQUEST = 'invoicePage/GET_EMAIL_INVOICE_REQUEST',
  GET_EMAIL_INVOICE_INVOICES_SUCCESS = 'invoicePage/GET_EMAIL_INVOICE_SUCCESS',
  GET_EMAIL_INVOICE_INVOICES_ERROR = 'invoicePage/GET_EMAIL_INVOICE_ERROR',

  GET_PAYMENT_BUTTON_INVOICES_REQUEST = 'invoicePage/GET_PAYMENT_BUTTON_INVOICE_REQUEST',
  GET_PAYMENT_BUTTON_INVOICES_SUCCESS = 'invoicePage/GET_PAYMENT_BUTTON_INVOICE_SUCCESS',
  GET_PAYMENT_BUTTON_INVOICES_ERROR = 'invoicePage/GET_PAYMENT_BUTTON_INVOICE_ERROR',

  CLEAR_PAYMENT_BUTTON_INVOICES = 'invoicePage/CLEAR_PAYMENT_BUTTON_INVOICES',

  GET_SUBSCRIPTION_INVOICES_REQUEST = 'invoicePage/GET_SUBSCRIPTION_INVOICE_REQUEST',
  GET_SUBSCRIPTION_INVOICES_SUCCESS = 'invoicePage/GET_SUBSCRIPTION_INVOICE_SUCCESS',
  GET_SUBSCRIPTION_INVOICES_ERROR = 'invoicePage/GET_SUBSCRIPTION_INVOICE_ERROR',
}

export const getInvoicesAction = createAction(
  invoicePageActions.GET_REQUEST,
  props<{ pagination: PageRequestModel; isAdmin: boolean; filters: PageRequestFiltersModel }>(),
);

export const getInvoicesExportAction = createAction(
  invoicePageActions.POST_EXPORT_REQUEST,
  props<{ pagination: PageRequestModel; isAdmin: boolean; filters: PageRequestFiltersModel; uuid: string }>(),
);

export const getInvoicesExportSuccessAction = createAction(invoicePageActions.POST_EXPORT_SUCCESS);

export const getInvoicesExportErrorAction = createAction(
  invoicePageActions.POST_EXPORT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getInvoicesSuccessAction = createAction(
  invoicePageActions.GET_SUCCESS,
  props<{ invoices: PagingModel<InvoiceModel> }>(),
);

export const getInvoicesErrorAction = createAction(invoicePageActions.GET_ERROR, props<{ errors: ErrorModel[] }>());

export const getSelectedInvoicesAction = createAction(
  invoicePageActions.GET_SELECTED_REQUEST,
  props<{ hashIds: string[]; isAdmin: boolean }>(),
);

export const getSelectedInvoicesSuccessAction = createAction(
  invoicePageActions.GET_SELECTED_SUCCESS,
  props<{ invoices: PagingModel<InvoiceModel> }>(),
);

export const getSelectedInvoicesErrorAction = createAction(
  invoicePageActions.GET_SELECTED_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getEmailInvoiceInvoicesAction = createAction(
  invoicePageActions.GET_EMAIL_INVOICE_INVOICES_REQUEST,
  props<{ emailInvoiceId: string; isAdmin: boolean }>(),
);

export const getEmailInvoiceInvoicesSuccessAction = createAction(
  invoicePageActions.GET_EMAIL_INVOICE_INVOICES_SUCCESS,
  props<{ invoices: PagingModel<InvoiceModel> }>(),
);

export const getEmailInvoiceInvoicesErrorAction = createAction(
  invoicePageActions.GET_EMAIL_INVOICE_INVOICES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const getPaymentButtonInvoicesAction = createAction(
  invoicePageActions.GET_PAYMENT_BUTTON_INVOICES_REQUEST,
  props<{ paymentButtonId: string; pagination: PageRequestModel }>(),
);

export const getPaymentButtonInvoicesSuccessAction = createAction(
  invoicePageActions.GET_PAYMENT_BUTTON_INVOICES_SUCCESS,
  props<{ invoices: PagingModel<InvoiceModel> }>(),
);

export const getPaymentButtonInvoicesErrorAction = createAction(
  invoicePageActions.GET_PAYMENT_BUTTON_INVOICES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const clearPaymentButtonInvoicesAction = createAction(invoicePageActions.CLEAR_PAYMENT_BUTTON_INVOICES);

export const getSubscriptionInvoicesAction = createAction(
  invoicePageActions.GET_SUBSCRIPTION_INVOICES_REQUEST,
  props<{ subscriptionId: string; pagination: PageRequestModel }>(),
);

export const getSubscriptionInvoicesSuccessAction = createAction(
  invoicePageActions.GET_SUBSCRIPTION_INVOICES_SUCCESS,
  props<{ invoices: PagingModel<InvoiceModel> }>(),
);

export const getSubscriptionInvoicesErrorAction = createAction(
  invoicePageActions.GET_SUBSCRIPTION_INVOICES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
