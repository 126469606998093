import { createReducer, on } from '@ngrx/store';
import {
  getBalanceHistoryPageAction,
  getBalanceHistoryPageActionError,
  getBalanceHistoryPageActionSuccess,
} from '../actions/balance-history-page.actions';
import { BalanceHistoryModel } from '../models/api/balance-history.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type BalanceHistoryPageState = StateModel<PagingModel<BalanceHistoryModel>>;

const INITIAL_STATE: BalanceHistoryPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const balanceHistoryPageReducer = createReducer(
  INITIAL_STATE,
  on(getBalanceHistoryPageAction, () => ({ ...INITIAL_STATE, loading: true })),
  on(getBalanceHistoryPageActionSuccess, (state, { balanceHistoryList }) => ({
    data: balanceHistoryList,
    errors: null,
    loading: false,
  })),
  on(getBalanceHistoryPageActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
