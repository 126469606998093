export interface AuthModel {
  token: string;
  authorized?: boolean;
  twoFaRequiredType?: TwoFaType;
}

export enum TwoFaType {
  GOOGLE_2FA = 'google2fa',
  EMAIL_2FA = 'email2fa',
}
