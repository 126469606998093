import { createReducer, on } from '@ngrx/store';
import { PayoutFeesTo } from '../../models/api/payout-fees.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getPublicPayoutFeesAction,
  getPublicPayoutFeesActionError,
  getPublicPayoutFeesActionSuccess,
} from './payout-fees-public.actions';

export type PayoutFeesPublicState = StateModel<PayoutFeesTo>;

const INITIAL_STATE: PayoutFeesPublicState = {
  errors: null,
  data: null,
  initial: true,
};

export const payoutFeesPublicReducer = createReducer(
  INITIAL_STATE,
  on(getPublicPayoutFeesAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(getPublicPayoutFeesActionSuccess, (state, { payoutFees }) => ({
    data: payoutFees,
    errors: null,
  })),
  on(getPublicPayoutFeesActionError, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
