import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';

/**
 * Transforms merchant profile api response into model. Introduced for birth date transformation.
 * @param merchantProfile api result containing merchant profile
 * @returns MerchantProfileUnionModel
 */
export function fromApiToModelProfile(merchantProfile: MerchantProfileUnionModel): MerchantProfileUnionModel {
  if (merchantProfile == null) {
    return null;
  }
  return { ...merchantProfile };
}
