import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TodoItemModel } from '../../models/api/todo-item.model';
import { ApiService } from '../../services/api.service';

export const TODO_ENDPOINT = '/todo';
@Injectable({
  providedIn: 'root',
})
export class TodoListService {
  private readonly api = inject(ApiService);

  public getTodoList(): Observable<TodoItemModel[]> {
    return this.api.get(TODO_ENDPOINT).pipe(map((todoItems: TodoItemModel[]) => todoItems));
  }
}
