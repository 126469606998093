import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  activateUserAction,
  activateUserErrorAction,
  activateUserSuccessAction,
  resetPasswordAction,
  resetPasswordErrorAction,
  resetPasswordSuccessAction,
} from '../actions/login-prefill.actions';
import {
  activateUserWithPasswordAction,
  activateUserWithPasswordErrorAction,
  activateUserWithPasswordSuccessAction,
  getUserActivationStatusAction,
  getUserActivationStatusErrorAction,
  getUserActivationStatusSuccessAction,
} from '../actions/user-activation.actions';
import { ApiService } from '../services/api.service';
import { USERS_RECOVERY_ENDPOINT } from './stateless/password.effect';

const USERS_ACTIVATE_ENDPOINT = '/user/activate';

@Injectable()
export class LoginPrefillEffect {
  getUserActivationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserActivationStatusAction),
      mergeMap(({ hash }) =>
        this.api.get(`${USERS_ACTIVATE_ENDPOINT}/${hash}`).pipe(
          map((userActivation) => getUserActivationStatusSuccessAction({ userActivation })),
          catchError((errors) => observableOf(getUserActivationStatusErrorAction(errors))),
        ),
      ),
    ),
  );

  activateUserWithPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateUserWithPasswordAction),
      switchMap(({ hash, password }) => {
        const requestBody = { newPassword: password };

        return this.api.post(`${USERS_ACTIVATE_ENDPOINT}/${hash}`, requestBody).pipe(
          map((userActivation) => activateUserWithPasswordSuccessAction({ userActivation })),
          catchError((errors) => observableOf(activateUserWithPasswordErrorAction(errors))),
        );
      }),
    ),
  );

  activateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateUserAction),
      switchMap(({ hash }) =>
        this.api.post(`${USERS_ACTIVATE_ENDPOINT}/${hash}`).pipe(
          map((activationInfo) => activateUserSuccessAction({ activationInfo })),
          catchError((errors) => observableOf(activateUserErrorAction(errors))),
        ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPasswordAction),
      switchMap(({ hash, body }) =>
        this.api.post(`${USERS_RECOVERY_ENDPOINT}/${hash}`, body).pipe(
          map((activationInfo) => resetPasswordSuccessAction({ activationInfo })),
          catchError((errors) => observableOf(resetPasswordErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
