/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { FeesType } from '../models/api/global-fees.model';
import { MerchantFeesModel } from '../models/api/merchant-fees.model';

export enum merchantFeesActions {
  GET_FEES_REQUEST = 'merchantFees/GET_FEES_REQUEST',
  GET_FEES_SUCCESS = 'merchantFees/GET_FEES_SUCCESS',
  GET_FEES_ERROR = 'merchantFees/GET_FEES_ERROR',

  UPDATE_FEES_REQUEST = 'merchantFees/UPDATE_FEES_REQUEST',
  UPDATE_FEES_SUCCESS = 'merchantFees/UPDATE_FEES_SUCCESS',
  UPDATE_FEES_ERROR = 'merchantFees/UPDATE_FEES_ERROR',

  RESET_FEES_REQUEST = 'merchantFees/RESET_FEES_REQUEST',
  RESET_FEES_REQUEST_SUCCESS = 'merchantFees/RESET_FEES_REQUEST_SUCCESS',
  RESET_FEES_REQUEST_ERROR = 'merchantFees/RESET_FEES_REQUEST_ERROR',
}

export const getMerchantFeesAction = createAction(
  merchantFeesActions.GET_FEES_REQUEST,
  props<{ merchantId: string; feesType: string }>(),
);

export const getMerchantFeesSuccessAction = createAction(
  merchantFeesActions.GET_FEES_SUCCESS,
  props<{ merchantFees: MerchantFeesModel }>(),
);

export const getMerchantFeesErrorAction = createAction(
  merchantFeesActions.GET_FEES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantFeesAction = createAction(
  merchantFeesActions.UPDATE_FEES_REQUEST,
  props<{ merchantHashId: string; merchantFees: MerchantFeesModel }>(),
);

export const updateMerchantFeesSuccessAction = createAction(
  merchantFeesActions.UPDATE_FEES_SUCCESS,
  props<{ merchantFees: MerchantFeesModel }>(),
);

export const updateMerchantFeesErrorAction = createAction(
  merchantFeesActions.UPDATE_FEES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const resetMerchantFeesAction = createAction(
  merchantFeesActions.RESET_FEES_REQUEST,
  props<{ merchantHashId: string; feesType: FeesType }>(),
);

export const resetMerchantFeesSuccessAction = createAction(merchantFeesActions.RESET_FEES_REQUEST_SUCCESS);

export const resetMerchantFeesErrorAction = createAction(
  merchantFeesActions.RESET_FEES_REQUEST_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
