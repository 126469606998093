<bp-header-dialog dialogTitle="Add beneficiaries" (closePressed)="close()">
  <div class="person-add-dialog-content animate__appear-down">
    <bp-merchant-person-form [displayFlags]="false"></bp-merchant-person-form>
    <div class="row extra-margin__top set-width">
      <button
        class="confirmo-button confirmo-button__blue extra-margin"
        type="submit"
        [disabled]="formComponent.form.invalid"
        (click)="add()"
      >
        ADD
      </button>
      <button class="confirmo-button confirmo-button__pink extra-margin" (click)="close()">CANCEL</button>
    </div>
  </div>
</bp-header-dialog>
