import { createReducer, on } from '@ngrx/store';
import {
  deletePaymentButtonErrorAction,
  getPaymentButtonPageAction,
  getPaymentButtonPageErrorAction,
  getPaymentButtonPageSuccessAction,
} from '../actions/payment-button.actions';
import { PaymentButtonModel } from '../models/api/payment-button.model';
import { PagingModel } from '../models/auxiliary/paging.model';
import { StateModel } from '../models/auxiliary/state.model';

export type PaymentButtonPageState = StateModel<PagingModel<PaymentButtonModel>>;

const INITIAL_STATE: PaymentButtonPageState = {
  errors: null,
  data: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    data: [],
  },
  loading: true,
};

export const paymentButtonPageReducer = createReducer(
  INITIAL_STATE,
  on(getPaymentButtonPageAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(getPaymentButtonPageSuccessAction, (state, { paymentButtonPage }) => ({
    ...state,
    data: paymentButtonPage,
    loading: false,
  })),
  on(getPaymentButtonPageErrorAction, deletePaymentButtonErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
