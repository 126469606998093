import { Injectable, inject } from '@angular/core';
import { COOKIEYES_PROD_ID, COOKIEYES_TEST_ID } from '../config/constants';
import { ConfigProfile } from '../enums/config-profile.enum';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private readonly appConfigService = inject(AppConfigService);
  setDefaultConsent(): void {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 2000,
    });
    gtag('set', 'ads_data_redaction', true);
    gtag('set', 'url_passthrough', true);
  }

  // used to allow analytics w/o consent
  consentGrantedAnalyticsStorage(): void {
    gtag('consent', 'update', { analytics_storage: 'granted' });
  }

  initCookieYes(): void {
    if (!this.appConfigService.config) {
      return;
    }

    const { profile } = this.appConfigService.config;

    // on local, dev, test we don't use CookieYes
    if (profile === ConfigProfile.PROD || profile === ConfigProfile.UAT) {
      const cookieyesClientId = profile === ConfigProfile.PROD ? COOKIEYES_PROD_ID : COOKIEYES_TEST_ID;
      let cookieyesScript = document.createElement('script');
      cookieyesScript.id = 'cookieyes';
      cookieyesScript.type = 'text/javascript';
      cookieyesScript.src = `https://cdn-cookieyes.com/client_data/${cookieyesClientId}/script.js`;
      document.head.appendChild(cookieyesScript);
    }
  }
}
