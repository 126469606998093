<bp-header-dialog [dialogTitle]="labels.title" (closePressed)="closeDialog()">
  <div class="animate__appear-down">
    <div class="confirmation-dialog-content">
      <div class="confirmation-dialog-text">{{ labels.confirmMessage }}</div>
      <div class="confirmation-dialog-actions">
        <button class="confirmo-button confirmo-button__blue confirmation-dialog-button-confirm" (click)="confirm()">
          {{ labels.okButtonText }}
        </button>
        <button *ngIf="labels.cancelButtonText" class="confirmo-button confirmo-button__red" (click)="cancel()">
          {{ labels.cancelButtonText }}
        </button>
      </div>
    </div>
  </div>
</bp-header-dialog>
