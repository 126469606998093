import { createReducer, on } from '@ngrx/store';
import {
  getMerchantVerificationInfoAction,
  getMerchantVerificationInfoErrorAction,
  getMerchantVerificationInfoSuccessAction,
} from '../actions/merchant-verification-info.actions';
import { MerchantVerificationInfoModel } from '../models/api/merchant-profile/merchant-verification-info.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantVerificationInfoState = StateModel<MerchantVerificationInfoModel>;

const INITIAL_STATE: MerchantVerificationInfoState = {
  errors: null,
  data: null,
  initial: true,
};

export const merchantVerificationInfoReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantVerificationInfoAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getMerchantVerificationInfoSuccessAction, (state, { verificationInfo }) => ({
    errors: null,
    data: verificationInfo,
  })),
  on(getMerchantVerificationInfoErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);
