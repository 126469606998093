import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { MERCHANT_PROFILE_ENDPOINT, MerchantProfileService } from '../../platform/services/merchant-profile.service';
import {
  getMerchantProfileAction,
  getMerchantProfileAsAdminAction,
  getMerchantProfileAsAdminErrorAction,
  getMerchantProfileAsAdminSuccessAction,
  getMerchantProfileErrorAction,
  getMerchantProfileSuccessAction,
  updateMerchantProfileAction,
  updateMerchantProfileErrorAction,
  updateMerchantProfileSuccessAction,
} from '../actions/merchant-profile.actions';
import { ApiService } from '../services/api.service';

export const MERCHANT_ADMIN_ENDPOINT = '/admin/merchants/';

@Injectable()
export class MerchantProfileEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantProfileAction),
      mergeMap(() =>
        this.merchantProfileService.get().pipe(
          map((merchantProfile) => getMerchantProfileSuccessAction({ merchantProfile })),
          catchError((errors) => observableOf(getMerchantProfileErrorAction(errors))),
        ),
      ),
    ),
  );

  getAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantProfileAsAdminAction),
      mergeMap(({ id }) =>
        this.api.get(`${MERCHANT_ADMIN_ENDPOINT}${id}${MERCHANT_PROFILE_ENDPOINT}`).pipe(
          map((merchantProfile) => getMerchantProfileAsAdminSuccessAction({ merchantProfile })),
          catchError((errors) => observableOf(getMerchantProfileAsAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantProfileAction),
      switchMap(({ merchantProfile }) =>
        this.api.post(`${MERCHANT_PROFILE_ENDPOINT}`, merchantProfile).pipe(
          map((updatedMerchantProfile) => updateMerchantProfileSuccessAction({ updatedMerchantProfile })),
          catchError((errors) => observableOf(updateMerchantProfileErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private merchantProfileService: MerchantProfileService,
  ) {}
}
