import { createReducer, on } from '@ngrx/store';
import { getUserGuiSettingsSuccessAction } from '../../actions/user-gui-settings.actions';
import { ANY_FILTER_VALUE } from '../../constants';
import { Time } from '../../enums/time.enum';
import { getInvoicePageFiltersAction, updateInvoicePageFiltersAction } from './invoice-page-filters.actions';
import { InvoicePageFiltersModel } from './invoice-page-filters.model';

export type InvoicePageFiltersState = InvoicePageFiltersModel;

export const INVOICE_PAGE_FILTERS_ANY_STATE: InvoicePageFiltersState = {
  currencyFrom: ANY_FILTER_VALUE,
  currencyTo: ANY_FILTER_VALUE,
  amountFrom: '',
  amountTo: '',
  status: ANY_FILTER_VALUE,
  sourceType: ANY_FILTER_VALUE,
  posHashId: [],
  createdAt: {
    type: ANY_FILTER_VALUE,
    createdAtFrom: null,
    createdAtTo: null,
  },
  exceptionsFirst: false,
};

export const INVOICE_PAGE_FILTERS_INITIAL_STATE: InvoicePageFiltersState = {
  currencyFrom: ANY_FILTER_VALUE,
  currencyTo: ANY_FILTER_VALUE,
  amountFrom: '',
  amountTo: '',
  status: ANY_FILTER_VALUE,
  sourceType: ANY_FILTER_VALUE,
  posHashId: [],
  createdAt: {
    type: Time.MONTH,
    createdAtFrom: null,
    createdAtTo: null,
  },
  exceptionsFirst: false,
};

export const invoicePageFiltersReducer = createReducer(
  INVOICE_PAGE_FILTERS_INITIAL_STATE,
  on(getInvoicePageFiltersAction, (state) => ({
    ...state,
  })),
  on(updateInvoicePageFiltersAction, (state, { filters }) => ({
    ...filters,
  })),
  on(getUserGuiSettingsSuccessAction, (state, { userSettings }) => ({
    ...state,
    exceptionsFirst: userSettings.invoicesWithExceptionsFirst,
  })),
);
