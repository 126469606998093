import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PosDevicesApiService } from './pos-devices-api.service';
import {
  createPosDeviceAction,
  createPosDeviceErrorAction,
  createPosDeviceSuccessAction,
  deletePosDeviceAction,
  deletePosDeviceErrorAction,
  deletePosDeviceSuccessAction,
  getPosDeviceAction,
  getPosDeviceErrorAction,
  getPosDeviceSuccessAction,
  getPosDevicesAction,
  getPosDevicesErrorAction,
  getPosDevicesSuccessAction,
  refreshPosDeviceActivationCodeAction,
  refreshPosDeviceActivationCodeErrorAction,
  refreshPosDeviceActivationCodeSuccessAction,
  unpairPosDeviceAction,
  unpairPosDeviceErrorAction,
  unpairPosDeviceSuccessAction,
} from './pos-devices.actions';

@Injectable()
export class PosDevicesEffect {
  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPosDevicesAction),
      mergeMap(() =>
        this.posDevicesApiService.getList().pipe(
          map((posDevices) => getPosDevicesSuccessAction({ posDevices })),
          catchError((errors) => observableOf(getPosDevicesErrorAction(errors))),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePosDeviceAction),
      mergeMap(({ posDeviceId }) =>
        this.posDevicesApiService.delete(posDeviceId).pipe(
          map((posDevice) => deletePosDeviceSuccessAction({ posDevice })),
          catchError((errors) => observableOf(deletePosDeviceErrorAction(errors))),
        ),
      ),
    ),
  );

  unpair$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unpairPosDeviceAction),
      mergeMap(({ posDeviceId }) =>
        this.posDevicesApiService.unpair(posDeviceId).pipe(
          map((posDevice) => unpairPosDeviceSuccessAction({ posDevice })),
          catchError((errors) => observableOf(unpairPosDeviceErrorAction(errors))),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPosDeviceAction),
      switchMap(({ posDevice }) =>
        this.posDevicesApiService.create(posDevice).pipe(
          map((posDevice) => createPosDeviceSuccessAction({ posDevice })),
          catchError((errors) => observableOf(createPosDeviceErrorAction(errors))),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPosDeviceAction),
      mergeMap(({ posDeviceId }) =>
        this.posDevicesApiService.get(posDeviceId).pipe(
          map((posDevice) => getPosDeviceSuccessAction({ posDevice })),
          catchError((errors) => observableOf(getPosDeviceErrorAction(errors))),
        ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshPosDeviceActivationCodeAction),
      switchMap(({ posDeviceId }) =>
        this.posDevicesApiService.refresh(posDeviceId).pipe(
          map((posDevice) => refreshPosDeviceActivationCodeSuccessAction({ posDevice })),
          catchError((errors) => observableOf(refreshPosDeviceActivationCodeErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private posDevicesApiService: PosDevicesApiService,
  ) {}
}
