export enum CryptoAssetCode {
  BTC = 'BTC',
  LTC = 'LTC',
  ETH = 'ETH',
  USDT = 'USDT',
  USDTE = 'USDTE',
  USDC = 'USDC',
  USDCE = 'USDCE',
  SOL = 'SOL',
  TRX = 'TRX',
  POL = 'POL',
}
