import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MerchantProfilePageState } from '../reducers/merchant-profile-page.reducer';
import { MerchantProfileState } from '../reducers/merchant-profile.reducer';

export const selectMerchantProfile = createFeatureSelector<MerchantProfileState>('merchantProfile');

export const selectMerchantProfileErrors = createSelector(selectMerchantProfile, ({ errors }) => errors);

export const selectMerchantProfileData = createSelector(selectMerchantProfile, ({ data }) => data);

export const selectMerchantProfilePage = createFeatureSelector<MerchantProfilePageState>('merchantProfilePage');

export const selectMerchantProfileAdmin = createFeatureSelector<MerchantProfileState>('merchantProfileAdmin');
