import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getRefundPageAction,
  getRefundPageErrorAction,
  getRefundPageSuccessAction,
} from '../actions/refund-page.actions';
import { RefundApiService } from '../store/refund/refund-api.service';

@Injectable()
export class RefundPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRefundPageAction),
      mergeMap(({ paging }) =>
        this.refundApiService.getRefundPage(paging).pipe(
          map((refunds) => getRefundPageSuccessAction({ refunds })),
          catchError((errors) => observableOf(getRefundPageErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private refundApiService: RefundApiService,
  ) {}
}
