import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { queryParams } from '../../../utils';
import {
  getCryptoPaymentMethodsMerchantAction,
  getCryptoPaymentMethodsMerchantActionError,
  getCryptoPaymentMethodsMerchantActionSuccess,
  updateCryptoPaymentMethodMerchantAction,
  updateCryptoPaymentMethodMerchantActionError,
  updateCryptoPaymentMethodMerchantActionSuccess,
  updateCryptoPaymentMethodMerchantAsAdminAction,
  updateCryptoPaymentMethodMerchantAsAdminActionError,
  updateCryptoPaymentMethodMerchantAsAdminActionSuccess,
} from './crypto-payment-methods-merchant.actions';

export const PAYMENT_METHODS_EDIT_ENDPOINT = '/payment-methods';
export const PAYMENT_METHODS_UPDATE_AS_ADMIN_ENDPOINT = '/admin/merchants/{merchantHashId}/payment-methods';
export const PAYMENT_METHODS_ENDPOINT = '/merchants/{merchantHashId}/payment-methods';

@Injectable()
export class CryptoPaymentMethodsMerchantEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoPaymentMethodsMerchantAction),
      mergeMap(({ merchantId, invoicingEnabled, settlementEnabled, payoutEnabled }) =>
        this.api.get(this.urlGet(merchantId, invoicingEnabled, settlementEnabled, payoutEnabled)).pipe(
          map((paymentMethods) => getCryptoPaymentMethodsMerchantActionSuccess({ paymentMethods })),
          catchError((errors) => of(getCryptoPaymentMethodsMerchantActionError(errors))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCryptoPaymentMethodMerchantAction),
      mergeMap(({ paymentMethod, paymentMethodId }) =>
        this.api.put(this.urlUpdate(paymentMethodId), paymentMethod).pipe(
          map(() => updateCryptoPaymentMethodMerchantActionSuccess()),
          catchError((errors) => of(updateCryptoPaymentMethodMerchantActionError(errors))),
        ),
      ),
    ),
  );

  updateAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCryptoPaymentMethodMerchantAsAdminAction),
      mergeMap(({ merchantHashId, paymentMethod, paymentMethodId }) =>
        this.api.put(this.urlUpdateAsAdmin(merchantHashId, paymentMethodId), paymentMethod).pipe(
          map(() => updateCryptoPaymentMethodMerchantAsAdminActionSuccess()),
          catchError((errors) => of(updateCryptoPaymentMethodMerchantAsAdminActionError(errors))),
        ),
      ),
    ),
  );

  urlGet(merchantId: string, invoicingEnabled: boolean, settlementEnabled: boolean, payoutEnabled: boolean): string {
    return `${PAYMENT_METHODS_ENDPOINT.replace('{merchantHashId}', merchantId)}${queryParams({
      ...(invoicingEnabled !== null && { invoicingEnabled: invoicingEnabled }),
      ...(settlementEnabled !== null && { settlementEnabled: settlementEnabled }),
      ...(payoutEnabled !== null && { payoutEnabled: payoutEnabled }),
    })}`;
  }

  urlUpdate(paymentMethodId: string): string {
    return `${PAYMENT_METHODS_EDIT_ENDPOINT}/${paymentMethodId}`;
  }

  urlUpdateAsAdmin(merchantHashId: string, paymentMethodId: string): string {
    return `${PAYMENT_METHODS_UPDATE_AS_ADMIN_ENDPOINT.replace('{merchantHashId}', merchantHashId)}/${paymentMethodId}`;
  }

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
