/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { OtherSideTransactionLimits } from '../models/api/other-side-transaction.model';

export enum OtherSideTransactionLimitsActionTypes {
  GetOtherSideTransactionLimits = 'otherSideTransactionLimits/GET_REQUEST',
  GetOtherSideTransactionLimitsSuccess = 'otherSideTransactionLimits/GET_SUCCESS',
  GetOtherSideTransactionLimitsError = 'otherSideTransactionLimits/GET_ERROR',
}

export const getOtherSideTransactionLimitsAction = createAction(
  OtherSideTransactionLimitsActionTypes.GetOtherSideTransactionLimits,
);

export const getOtherSideTransactionLimitsSuccessAction = createAction(
  OtherSideTransactionLimitsActionTypes.GetOtherSideTransactionLimitsSuccess,
  props<{ limits: OtherSideTransactionLimits[] }>(),
);

export const getOtherSideTransactionLimitsErrorAction = createAction(
  OtherSideTransactionLimitsActionTypes.GetOtherSideTransactionLimitsError,
  props<{ errors: ErrorModel[] }>(),
);
