import { createReducer, on } from '@ngrx/store';
import { PublicProfileModel } from '../../models/api/public-profile.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getPublicProfileAction,
  getPublicProfileByMerchantIdErrorAction,
  getPublicProfileByMerchantIdSuccessAction,
  getPublicProfileErrorAction,
  getPublicProfileSuccessAction,
  updatePublicProfileErrorAction,
  updatePublicProfileSuccessAction,
} from './public-profile.actions';

export type PublicProfileState = StateModel<PublicProfileModel>;

const INITIAL_STATE: PublicProfileState = {
  data: null,
  errors: null,
  initial: true,
};

export const publicProfileReducer = createReducer(
  INITIAL_STATE,
  on(getPublicProfileAction, getPublicProfileByMerchantIdErrorAction, () => ({
    ...INITIAL_STATE,
    initial: false,
  })),
  on(
    getPublicProfileSuccessAction,
    getPublicProfileByMerchantIdSuccessAction,
    updatePublicProfileSuccessAction,
    (state, { publicProfile }) => ({
      ...state,
      errors: null,
      data: publicProfile,
      initial: false,
    }),
  ),
  on(
    getPublicProfileErrorAction,
    getPublicProfileByMerchantIdErrorAction,
    updatePublicProfileErrorAction,
    (state, { errors }) => ({
      ...state,
      errors: errors,
      initial: false,
    }),
  ),
);
