import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getCryptoWalletsStatisticsAction,
  getCryptoWalletsStatisticsErrorAction,
  getCryptoWalletsStatisticsSuccessAction,
} from './crypto-wallets-statistics.actions';
import { CryptoWalletsStatisticsService } from './crypto-wallets-statistics.service';

@Injectable()
export class CryptoWalletsStatisticsEffect {
  private readonly actions$ = inject(Actions);
  private readonly cryptoWalletsStatisticsService = inject(CryptoWalletsStatisticsService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoWalletsStatisticsAction),
      mergeMap(({ cryptoProvider }) =>
        this.cryptoWalletsStatisticsService.getCryptoWalletsStatistics(cryptoProvider).pipe(
          map((walletsStatistics) => getCryptoWalletsStatisticsSuccessAction({ walletsStatistics })),
          catchError((errors) => of(getCryptoWalletsStatisticsErrorAction(errors))),
        ),
      ),
    ),
  );
}
