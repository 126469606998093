import { createReducer, on } from '@ngrx/store';
import {
  getOtherSideTransactionLimitsAction,
  getOtherSideTransactionLimitsErrorAction,
  getOtherSideTransactionLimitsSuccessAction,
} from '../actions/other-side-transaction-limits.actions';
import { OtherSideTransactionLimits } from '../models/api/other-side-transaction.model';
import { StateModel } from '../models/auxiliary/state.model';

export type OtherSideTransactionLimitsState = StateModel<OtherSideTransactionLimits[]>;

export const initialState: OtherSideTransactionLimitsState = {
  data: null,
  errors: null,
};

export const otherSideTransactionLimitsReducer = createReducer(
  initialState,
  on(getOtherSideTransactionLimitsAction, (state) => ({
    ...initialState,
  })),
  on(getOtherSideTransactionLimitsSuccessAction, (state, { limits }) => ({
    ...state,
    errors: null,
    data: limits,
  })),
  on(getOtherSideTransactionLimitsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
