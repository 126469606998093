import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  changePasswordAction,
  changePasswordErrorAction,
  changePasswordSuccessAction,
  sendResetLinkAction,
  sendResetLinkErrorAction,
  sendResetLinkSuccessAction,
} from '../../actions/login-prefill.actions';
import { ApiService, get2FaHeaders } from '../../services/api.service';

export const USERS_RECOVERY_ENDPOINT = '/user/password/recovery';
const USERS_CHANGE_PASSWORD_ENDPOINT = '/user/password';

@Injectable()
export class PasswordEffect {
  sendResetLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendResetLinkAction),
      switchMap(({ body }) =>
        this.api.post(USERS_RECOVERY_ENDPOINT, body).pipe(
          map((response) => sendResetLinkSuccessAction()),
          catchError((errors) => observableOf(sendResetLinkErrorAction(errors))),
        ),
      ),
    ),
  );

  changePasswordMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePasswordAction),
      mergeMap(({ body, twoFaCode }) =>
        this.api.put(USERS_CHANGE_PASSWORD_ENDPOINT, body, get2FaHeaders(twoFaCode)).pipe(
          map((response) => changePasswordSuccessAction()),
          catchError((errors) => observableOf(changePasswordErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
