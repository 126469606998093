/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { PayoutFeesTo } from '../../models/api/payout-fees.model';

export enum PayoutFeesPublicActions {
  GET_PUBLIC_PAYOUT_FEES_REQUEST = 'payoutFeesPublic/GET_PUBLIC_PAYOUT_FEES_REQUEST',
  GET_PUBLIC_PAYOUT_FEES_SUCCESS = 'payoutFeesPublic/GET_PUBLIC_PAYOUT_FEES_SUCCESS',
  GET_PUBLIC_PAYOUT_FEES_ERROR = 'payoutFeesPublic/GET_PUBLIC_PAYOUT_FEES_ERROR',
}

export const getPublicPayoutFeesAction = createAction(PayoutFeesPublicActions.GET_PUBLIC_PAYOUT_FEES_REQUEST);

export const getPublicPayoutFeesActionSuccess = createAction(
  PayoutFeesPublicActions.GET_PUBLIC_PAYOUT_FEES_SUCCESS,
  props<{ payoutFees: PayoutFeesTo }>(),
);

export const getPublicPayoutFeesActionError = createAction(
  PayoutFeesPublicActions.GET_PUBLIC_PAYOUT_FEES_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
