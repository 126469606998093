/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import {
  MerchantCryptoPaymentMethodTo,
  MerchantCryptoPaymentMethodUpdateTo,
} from '../../../models/api/crypto-payment-method.model';
import { ErrorModel } from '../../../models/api/error.model';

export enum CryptoPaymentMethodsMerchantActions {
  GET_CRYPTO_PAYMENT_METHODS_MERCHANT_REQUEST = 'cryptoPaymentMethodsMerchant/GET_CRYPTO_PAYMENT_METHODS_MERCHANT_REQUEST',
  GET_CRYPTO_PAYMENT_METHODS_MERCHANT_SUCCESS = 'cryptoPaymentMethodsMerchant/GET_CRYPTO_PAYMENT_METHODS_MERCHANT_SUCCESS',
  GET_CRYPTO_PAYMENT_METHODS_MERCHANT_ERROR = 'cryptoPaymentMethodsMerchant/GET_CRYPTO_PAYMENT_METHODS_MERCHANT_ERROR',

  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_REQUEST = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_REQUEST',
  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_SUCCESS = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_SUCCESS',
  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_ERROR = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_ERROR',

  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_REQUEST = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_REQUEST',
  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_SUCCESS = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_SUCCESS',
  UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_ERROR = 'cryptoPaymentMethodsMerchant/UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_ERROR',
}

export const getCryptoPaymentMethodsMerchantAction = createAction(
  CryptoPaymentMethodsMerchantActions.GET_CRYPTO_PAYMENT_METHODS_MERCHANT_REQUEST,
  props<{
    merchantId?: string;
    invoicingEnabled?: boolean;
    settlementEnabled?: boolean;
    payoutEnabled?: boolean;
  }>(),
);

export const getCryptoPaymentMethodsMerchantActionSuccess = createAction(
  CryptoPaymentMethodsMerchantActions.GET_CRYPTO_PAYMENT_METHODS_MERCHANT_SUCCESS,
  props<{ paymentMethods: MerchantCryptoPaymentMethodTo[] }>(),
);

export const getCryptoPaymentMethodsMerchantActionError = createAction(
  CryptoPaymentMethodsMerchantActions.GET_CRYPTO_PAYMENT_METHODS_MERCHANT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateCryptoPaymentMethodMerchantAction = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_REQUEST,
  props<{
    paymentMethod: MerchantCryptoPaymentMethodUpdateTo;
    paymentMethodId: string;
  }>(),
);

export const updateCryptoPaymentMethodMerchantActionSuccess = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_SUCCESS,
);

export const updateCryptoPaymentMethodMerchantActionError = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateCryptoPaymentMethodMerchantAsAdminAction = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_REQUEST,
  props<{
    merchantHashId: string;
    paymentMethod: MerchantCryptoPaymentMethodUpdateTo;
    paymentMethodId: string;
  }>(),
);

export const updateCryptoPaymentMethodMerchantAsAdminActionSuccess = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_SUCCESS,
);

export const updateCryptoPaymentMethodMerchantAsAdminActionError = createAction(
  CryptoPaymentMethodsMerchantActions.UPDATE_CRYPTO_PAYMENT_METHOD_MERCHANT_AS_ADMIN_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
