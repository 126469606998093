import { createReducer, on } from '@ngrx/store';
import {
  getCorrectionTxListForSettlementErrorAction,
  getCorrectionTxListForSettlementSuccessAction,
} from '../actions/correction-tx.actions';
import { CorrectionTransactionAdminTo } from '../models/api/correction-transaction-admin.model';
import { StateModel } from '../models/auxiliary/state.model';

export type CorrectionTxListState = StateModel<CorrectionTransactionAdminTo[]>;

const INITIAL_STATE: CorrectionTxListState = {
  errors: null,
  data: [],
};

export const correctionTxListReducer = createReducer(
  INITIAL_STATE,
  on(getCorrectionTxListForSettlementSuccessAction, (state, { correctionTxs }) => ({
    errors: null,
    data: correctionTxs,
  })),
  on(getCorrectionTxListForSettlementErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
