import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecoveryResult } from '../../models/api/recovery-result.model';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class FireblocksVaultAccountApiService {
  public readonly baseUrl: string;
  public readonly setVisibleUrl: string;
  public readonly recoverUrl: string;

  constructor(private api: ApiService) {
    this.baseUrl = `/fireblocks/vault-accounts`;
    this.setVisibleUrl = `${this.baseUrl}/{vaultAccountId}`;
    this.recoverUrl = `${this.baseUrl}/{vaultAccountId}/recovery`;
  }

  public setVisible(id: string, body: { visible: boolean }): Observable<any> {
    const url = this.setVisibleUrl.replace('{vaultAccountId}', id);
    return this.api.patch(url, body);
  }

  public recover(id: string): Observable<RecoveryResult> {
    const url = this.recoverUrl.replace('{vaultAccountId}', id);
    return this.api.post(url);
  }
}
