import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import {
  getAuditLogEventsAction,
  getAuditLogEventsErrorAction,
  getAuditLogEventsSuccessAction,
} from './audit-log-events.actions';

export const AUDIT_LOG_ENDPOINT = '/user/activity';

@Injectable()
export class AuditLogEventsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAuditLogEventsAction),
      mergeMap(() =>
        this.api.get(AUDIT_LOG_ENDPOINT).pipe(
          map((auditLogEventsResponse) => getAuditLogEventsSuccessAction({ auditLogEventsResponse })),
          catchError((errors) => of(getAuditLogEventsErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
