/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { CallbackPasswordModel } from '../models/api/callback-password.model';
import { ErrorModel } from '../models/api/error.model';

export enum callbackPasswordActions {
  GET_REQUEST = 'callbackPassword/GET_REQUEST',
  GET_SUCCESS = 'callbackPassword/GET_SUCCESS',
  GET_ERROR = 'callbackPassword/GET_ERROR',
  CALLBACK_PASSWORD_DELETE_REQUEST = 'callbackPassword/DELETE_REQUEST',
  CALLBACK_PASSWORD_DELETE_SUCCESS = 'callbackPassword/DELETE_SUCCESS',
  CALLBACK_PASSWORD_DELETE_ERROR = 'callbackPassword/DELETE_ERROR',
}

export const getCallbackPasswordAction = createAction(callbackPasswordActions.GET_REQUEST);

export const getCallbackPasswordSuccessAction = createAction(
  callbackPasswordActions.GET_SUCCESS,
  props<{ callbackPassword: CallbackPasswordModel }>(),
);

export const getCallbackPasswordErrorAction = createAction(
  callbackPasswordActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const deleteCallbackPasswordAction = createAction(
  callbackPasswordActions.CALLBACK_PASSWORD_DELETE_REQUEST,
  props<{ twoFACode: string }>(),
);

export const deleteCallbackPasswordSuccessAction = createAction(
  callbackPasswordActions.CALLBACK_PASSWORD_DELETE_SUCCESS,
);

export const deleteCallbackPasswordErrorAction = createAction(
  callbackPasswordActions.CALLBACK_PASSWORD_DELETE_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
