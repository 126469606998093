import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  updateSettlementMethodNotificationAction,
  updateSettlementMethodNotificationErrorAction,
  updateSettlementMethodNotificationSuccessAction,
} from './settlement-method-notification.actions';

export const SETTLEMENT_METHOD_NOTIFICATIONS_ENDPOINT = '/notification-settings/settlement-method';
export const SETTLEMENT_METHOD_NOTIFICATIONS_ADMIN_ENDPOINT =
  '/admin/merchants/notification-settings/settlement-method';

@Injectable()
export class SettlementMethodNotificationEffects {
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSettlementMethodNotificationAction),
      mergeMap(({ isAdmin, methodToUpdate }) =>
        this.api.put(this.url(isAdmin), methodToUpdate).pipe(
          map((updatedMethod) => updateSettlementMethodNotificationSuccessAction({ updatedMethod })),
          catchError((errors) => of(updateSettlementMethodNotificationErrorAction(errors))),
        ),
      ),
    ),
  );

  url(isAdmin: boolean): string {
    return isAdmin ? SETTLEMENT_METHOD_NOTIFICATIONS_ADMIN_ENDPOINT : SETTLEMENT_METHOD_NOTIFICATIONS_ENDPOINT;
  }

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
