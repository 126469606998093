/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { GlobalFeesModel } from '../models/api/global-fees.model';

export enum limitsAndFeesActions {
  GET_REQUEST = 'limitsAndFees/GET_REQUEST',
  GET_SUCCESS = 'limitsAndFees/GET_SUCCESS',
  GET_ERROR = 'limitsAndFees/GET_ERROR',
  UPDATE_REQUEST = 'limitsAndFees/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'limitsAndFees/UPDATE_SUCCESS',
  UPDATE_ERROR = 'limitsAndFees/UPDATE_ERROR',
}

export const getLimitsAndFeesAction = createAction(limitsAndFeesActions.GET_REQUEST, props<{ feesType: string }>());

export const getLimitsAndFeesSuccessAction = createAction(
  limitsAndFeesActions.GET_SUCCESS,
  props<{ limitsAndFees: GlobalFeesModel }>(),
);

export const getLimitsAndFeesErrorAction = createAction(
  limitsAndFeesActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateLimitsAndFeesAction = createAction(
  limitsAndFeesActions.UPDATE_REQUEST,
  props<{ limitsAndFees: GlobalFeesModel }>(),
);

export const updateLimitsAndFeesSuccessAction = createAction(
  limitsAndFeesActions.UPDATE_SUCCESS,
  props<{ limitsAndFees: GlobalFeesModel }>(),
);

export const updateLimitsAndFeesErrorAction = createAction(
  limitsAndFeesActions.UPDATE_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
