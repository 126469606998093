import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import {
  getAuditLogEventsAdminAction,
  getAuditLogEventsAdminErrorAction,
  getAuditLogEventsAdminSuccessAction,
} from './audit-log-events-admin.actions';

export const AUDIT_LOG_ADMIN_ENDPOINT = '/admin/users/{userHashId}/activity';

@Injectable()
export class AuditLogEventsAdminEffect {
  getAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAuditLogEventsAdminAction),
      mergeMap(({ userId }) =>
        this.api.get(AUDIT_LOG_ADMIN_ENDPOINT.replace('{userHashId}', userId)).pipe(
          map((auditLogEventsResponse) => getAuditLogEventsAdminSuccessAction({ auditLogEventsResponse })),
          catchError((errors) => of(getAuditLogEventsAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
