/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { RefundTo } from '../models/api/refund.model';
import { PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum refundPageActions {
  GET_REQUEST = 'refundPage/GET_REQUEST',
  GET_SUCCESS = 'refundPage/GET_SUCCESS',
  GET_ERROR = 'refundPage/GET_ERROR',
}

export const getRefundPageAction = createAction(refundPageActions.GET_REQUEST, props<{ paging: PageRequestModel }>());

export const getRefundPageSuccessAction = createAction(
  refundPageActions.GET_SUCCESS,
  props<{ refunds: PagingModel<RefundTo> }>(),
);

export const getRefundPageErrorAction = createAction(refundPageActions.GET_ERROR, props<{ errors: ErrorModel[] }>());
