import { createAction, props } from '@ngrx/store';
import { MerchantPageFiltersModel } from './merchant-page-filters.model';

export enum MerchantPageFiltersActions {
  GET = 'merchantPageFilters/GET',
  UPDATE = 'merchantPageFilters/UPDATE',
  CLEAR = 'merchantPageFilters/CLEAR',
}

export const getMerchantPageFiltersAction = createAction(MerchantPageFiltersActions.GET);

export const updateMerchantPageFiltersAction = createAction(
  MerchantPageFiltersActions.UPDATE,
  props<{ filters: MerchantPageFiltersModel }>(),
);

export const clearMerchantPageFiltersAction = createAction(MerchantPageFiltersActions.CLEAR);
