import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ADMIN_ENDPOINT_PREFIX, API_VERSION } from '../../constants';
import { ApiService } from '../../services/api.service';
import { SETTLEMENT_METHODS_ENDPOINT } from '../settlement-method/settlement-method-api.service';
import {
  getSettlementMethodPageAction,
  getSettlementMethodPageErrorAction,
  getSettlementMethodPageSuccessAction,
} from './settlement-method-page.actions';

@Injectable()
export class SettlementMethodPageEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlementMethodPageAction),
      mergeMap(({ merchantId }) =>
        this.api.get(this.url(merchantId)).pipe(
          map((settlements) => getSettlementMethodPageSuccessAction({ settlements })),
          catchError((errors) => observableOf(getSettlementMethodPageErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  url(merchantId: string): string {
    if (merchantId) {
      return `/${ADMIN_ENDPOINT_PREFIX}/merchants/${merchantId}${SETTLEMENT_METHODS_ENDPOINT}`;
    }
    return `/${API_VERSION}${SETTLEMENT_METHODS_ENDPOINT}`;
  }
}
