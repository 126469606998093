import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../../models/api/error.model';
import { MerchantModel } from '../../../../models/api/merchant.model';
import { RebalancingCorrectionTransactionCreationForMerchantTo } from '../../../../models/api/rebalancing-correction-transaction-creation-for-merchant.model';
import { PageRequestModel } from '../../../../models/auxiliary/page-request.model';
import { PagingModel } from '../../../../models/auxiliary/paging.model';

export enum RebalancingAction {
  GetCurrentRebalancingGroup = 'rebalancing/GET_CURRENT_REBALANCING_GROUP',
  GetCurrentRebalancingGroupSuccess = 'rebalancing/GET_CURRENT_REBALANCING_GROUP_SUCCESS',
  GetCurrentRebalancingGroupError = 'rebalancing/GET_CURRENT_REBALANCING_GROUP_ERROR',
  ResetCurrentRebalancingGroup = 'rebalancing/RESET_CURRENT_REBALANCING_GROUP', // no api call
  PostRebalancingCorrectionTransaction = 'rebalancing/POST_REBALANCING_CORRECTION_TRANSACTION',
  PostRebalancingCorrectionTransactionSuccess = 'rebalancing/POST_REBALANCING_CORRECTION_TRANSACTION_SUCCESS',
  PostRebalancingCorrectionTransactionError = 'rebalancing/POST_REBALANCING_CORRECTION_TRANSACTION_ERROR',
}

// current group is determined by the currently active Merchant Detail data
export const getCurrentRebalancingGroupAction = createAction(
  RebalancingAction.GetCurrentRebalancingGroup,
  props<{ paging: PageRequestModel; groupId: number | null }>(),
);

export const getCurrentRebalancingGroupSuccessAction = createAction(
  RebalancingAction.GetCurrentRebalancingGroupSuccess,
  props<{ rebalancingGroupRes: PagingModel<MerchantModel> }>(),
);

export const getCurrentRebalancingGroupErrorAction = createAction(
  RebalancingAction.GetCurrentRebalancingGroupError,
  props<{ errors: ErrorModel[] }>(),
);

// Handles resetting the global state if the selected merchant doesn't have rebalancingGroup
// merchantRebalancingGroup == null
export const resetCurrentRebalancingGroupAction = createAction(RebalancingAction.ResetCurrentRebalancingGroup);

export const postRebalancingCorrectionTransactionAction = createAction(
  RebalancingAction.PostRebalancingCorrectionTransaction,
  props<{ merchantId: string; rebalanceTx: RebalancingCorrectionTransactionCreationForMerchantTo }>(),
);

export const postRebalancingCorrectionTransactionSuccessAction = createAction(
  RebalancingAction.PostRebalancingCorrectionTransactionSuccess,
  props<{ senderId: string; recipientId: string }>(),
);

export const postRebalancingCorrectionTransactionErrorAction = createAction(
  RebalancingAction.PostRebalancingCorrectionTransactionError,
  props<{ errors: ErrorModel[] }>(),
);
