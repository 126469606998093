/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';

export enum refundTokenMapActions {
  ADD_IF_ABSENT = 'refundTokenMap/ADD_IF_ABSENT',
  OVERWRITE = 'refundTokenMap/OVERWRITE',
}

export const addRefundTokenMapIfAbsentAction = createAction(
  refundTokenMapActions.ADD_IF_ABSENT,
  props<{ invoiceIdToken: { [invoiceId: string]: string } }>(),
);

export const overwiteRefundTokenMapAction = createAction(
  refundTokenMapActions.OVERWRITE,
  props<{ invoiceIdToken: { [invoiceId: string]: string } }>(),
);
