import { ReadWriteRole } from './read-write.role';

export class OwnerRole extends ReadWriteRole {
  canViewUserList(): boolean {
    return true;
  }

  canAccessUserDetail(): boolean {
    return true;
  }
}
