/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';

export enum PublicInvoicePreparedCurrencyCodeActions {
  SET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE = 'publicInvoicePreparedCurrencyCode/SET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE',
  GET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE = 'publicInvoicePreparedCurrencyCode/GET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE',
}

export const getPublicInvoicePreparedCurrencyCodeAction = createAction(
  PublicInvoicePreparedCurrencyCodeActions.GET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE,
);

export const setPublicInvoicePreparedCurrencyCodeAction = createAction(
  PublicInvoicePreparedCurrencyCodeActions.SET_PUBLIC_INVOICE_PREPARED_CURRENCY_CODE,
  props<{ currencyCode: string | null }>(),
);
