import { createReducer, on } from '@ngrx/store';
import { AuditLogEventModel } from '../../../models/api/audit-log-event.model';
import { StateModel } from '../../../models/auxiliary/state.model';
import {
  getAuditLogEventsAction,
  getAuditLogEventsErrorAction,
  getAuditLogEventsSuccessAction,
} from './audit-log-events.actions';

export type AuditLogEventsState = StateModel<AuditLogEventModel[]>;

const INITIAL_STATE: AuditLogEventsState = {
  errors: null,
  data: [],
};

export const auditLogEventsReducer = createReducer(
  INITIAL_STATE,
  on(getAuditLogEventsAction, () => ({
    ...INITIAL_STATE,
  })),
  on(getAuditLogEventsSuccessAction, (state, { auditLogEventsResponse }) => ({
    ...state,
    data: auditLogEventsResponse,
  })),
  on(getAuditLogEventsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
