import { createFeatureSelector } from '@ngrx/store';
import {
  IdcheckVerificationDataState,
  VerificationPersonState,
} from '../reducers/merchant-verification-idenfy.reducer';

export const selectIdcheckVerificationData =
  createFeatureSelector<IdcheckVerificationDataState>('idcheckVerificationData');

export const selectIdcheckVerificationPerson =
  createFeatureSelector<VerificationPersonState>('idcheckVerificationPerson');
