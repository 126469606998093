import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { updateMerchantProfileAction } from '../../../../../shared/actions/merchant-profile.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { MerchantProfileType } from '../../../../../shared/enums/merchant-profile-type.enum';
import { CountryTo } from '../../../../../shared/models/api/country.model';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { markAllAsTouched } from '../../../../../shared/utils';
import { whitespaceTrimValidator } from '../../../../../shared/validators/whitespace-trim.validator';

@Component({
  selector: 'bp-company-account-verification-form',
  templateUrl: './company-account-verification-form.component.html',
  styleUrls: ['../merchant-verification.component.scss'],
})
export class CompanyAccountVerificationFormComponent extends AbstractComponent {
  @Input()
  countries: CountryTo[] = [];

  selectedCountry: CountryTo;

  @Input()
  isAdmin: boolean;

  @Output()
  nextStep = new EventEmitter();

  companyAccountForm: UntypedFormGroup;
  canEditForm: boolean;

  constructor(
    private store: Store<AppStateModel>,
    private fb: UntypedFormBuilder,
  ) {
    super();

    this.companyAccountForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      street: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      city: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      zip: ['', [Validators.required, Validators.maxLength(10), whitespaceTrimValidator]],
      countryCode: ['', [Validators.required]],
      businessId: ['', [Validators.required, Validators.maxLength(100), whitespaceTrimValidator]],
      vatId: ['', [Validators.maxLength(100), whitespaceTrimValidator]],
    });
  }

  updateCompanyAccount(): void {
    if (!this.isAdmin) {
      if (this.canEditForm === false) {
        this.nextStep.emit();
      } else if (this.companyAccountForm.invalid) {
        markAllAsTouched(this.companyAccountForm);
      } else {
        this.dispatchCompanyAccountUpdate();
      }
    }
  }

  dispatchCompanyAccountUpdate(): void {
    this.companyAccountForm.get('countryCode').patchValue(this.selectedCountry.alpha2);
    this.store.dispatch(
      updateMerchantProfileAction({
        merchantProfile: {
          type: MerchantProfileType.COMPANY,
          ...this.companyAccountForm.value,
        },
      }),
    );
  }

  companyAccountFieldMissing(fieldName: string): boolean {
    const fieldControl = this.companyAccountForm.get(fieldName);
    return !!fieldControl && fieldControl.touched && !!fieldControl.getError('required');
  }

  get companyCountryNotSupported(): boolean {
    const countryControl = this.companyAccountForm.get('countryCode');
    return countryControl.touched && !!countryControl.getError('countryNotSupported');
  }

  containsWhitespaceAtStartOrEnd(fieldName: string): boolean {
    const control = this.companyAccountForm.get(fieldName);
    return control.touched && !!control.getError('whitespaces');
  }

  onSelectChange($event: CountryTo): void {
    this.companyAccountForm.get('countryCode').patchValue($event);
    this.selectedCountry = $event;
  }
}
