<bp-header-dialog dialogTitle="Merchant Director" (closePressed)="closeForm()">
  <div class="merchant-directors-content animate__appear-down">
    <form [formGroup]="merchantDirectorForm" (ngSubmit)="submitForm()">
      <div class="heading-container">
        <h2 class="bp-subheading">Managing Director Data</h2>
      </div>
      <div class="container margin-bottom">
        <div class="confirmo-row display-block-on-mobile">
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'firstName', placeholder: 'Name', autocomplete: 'given-name' }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'lastName', placeholder: 'Surname', autocomplete: 'family-name' }
            "
          ></ng-container>
        </div>
        <div class="confirmo-row display-block-on-mobile">
          <ng-container
            *ngTemplateOutlet="formFieldDate; context: { controlName: 'birthDate', placeholder: 'Date of birth' }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="formFieldInput; context: { controlName: 'birthPlace', placeholder: 'Place of birth' }"
          ></ng-container>
        </div>
        <div class="confirmo-row display-block-on-mobile">
          <ng-container
            *ngTemplateOutlet="
              formFieldSelectCountries;
              context: { controlName: 'nationalityCountryCode', placeholder: 'Nationality', countries: countries }
            "
          ></ng-container>
        </div>
      </div>
      <div class="heading-container">
        <h2 class="bp-subheading">Contact</h2>
      </div>
      <div class="container margin-bottom">
        <div class="confirmo-row display-block-on-mobile">
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'street', placeholder: 'Street', grow: true, autocomplete: 'street-address' }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'city', placeholder: 'City', grow: true, autocomplete: 'address-level2' }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'zip', placeholder: 'ZIP', autocomplete: 'postal-code' }
            "
          ></ng-container>
        </div>
        <div class="confirmo-row display-block-on-mobile">
          <ng-container
            *ngTemplateOutlet="
              formFieldSelectCountries;
              context: { controlName: 'countryCode', placeholder: 'Country', countries: countries }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              formFieldInput;
              context: { controlName: 'phone', placeholder: 'Mobile number', phone: 'true', autocomplete: 'tel' }
            "
          ></ng-container>
        </div>
      </div>
      <div formGroupName="flags" class="person-checkbox-group margin-left">
        <ng-container
          *ngTemplateOutlet="formCheckBox; context: { controlName: 'pep', text: 'Politically exposed person' }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="formCheckBox; context: { controlName: 'ubo', text: 'Ultimate Beneficiary' }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="formCheckBox; context: { controlName: 'us', text: 'U.S. person / citizen' }"
        ></ng-container>

        <ng-template let-text="text" let-controlName="controlName" #formCheckBox>
          <div class="confirmo-checkbox-wrapper margin-bottom__small">
            <bp-platform-checkbox class="checkbox-text" [label]="text">
              <input type="checkbox" class="confirmo-checkbox-input" [formControlName]="controlName" />
            </bp-platform-checkbox>
          </div>
        </ng-template>
      </div>

      <div class="confirmo-row display-block-on-mobile margin-left">
        <div class="text-input-group col nopadding">
          <button class="confirmo-button confirmo-button__blue" type="submit" [disabled]="!merchantDirectorForm.valid">
            {{ isEditForm() ? 'Update' : 'Add' }}
          </button>
        </div>
        <div class="text-input-group col nopadding margin-left__not-on-mobile">
          <button class="confirmo-button confirmo-button__red" type="button" (click)="closeForm()">Cancel</button>
        </div>
      </div>

      <ng-template
        #formFieldInput
        let-controlName="controlName"
        let-placeholder="placeholder"
        let-phone="phone"
        let-grow="grow"
        let-autocomplete="autocomplete"
      >
        <div class="text-input-group nopadding" [ngClass]="grow ? 'col-wider' : 'col'">
          <label class="label-text">
            {{ placeholder }}
          </label>
          <div class="verification-form-input-tooltip-container">
            <input
              [formControlName]="controlName"
              class="confirmo-platform-input verification-input"
              type="text"
              [placeholder]="placeholder"
              [autocomplete]="autocomplete"
            />
            <div
              class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
              *ngIf="isMerchantDirectorFieldMissing(controlName)"
            >
              Required
            </div>
            <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="phone && phoneInvalid">
              Invalid format
            </div>
            <div
              class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
              *ngIf="!phone && isFieldTooLong(controlName)"
            >
              {{ getMaxLengthErrorMessage(controlName) }}
            </div>
            <div
              class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
              *ngIf="containsWhitespaceAtStartOrEnd(controlName)"
            >
              Can't contain whitespace at the start or the end
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template
        #formFieldSelectCountries
        let-controlName="controlName"
        let-placeholder="placeholder"
        let-countries="countries"
      >
        <div class="text-input-group col nopadding">
          <label class="label-text">
            {{ placeholder }}
          </label>
          <div class="verification-form-input-tooltip-container">
            <div class="verification-input">
              <bp-country-select
                [countries]="countries"
                *ngIf="controlName === 'nationalityCountryCode'"
                (selectedChange)="onSelectChange($event, controlName)"
                [(selected)]="selectedNationality"
                [normalFontWeight]="true"
                errorMessage="Required"
              ></bp-country-select>
              <bp-country-select
                [countries]="countries"
                *ngIf="controlName === 'countryCode'"
                (selectedChange)="onSelectChange($event, controlName)"
                [(selected)]="selectedCountryCode"
                [normalFontWeight]="true"
                errorMessage="Required"
              ></bp-country-select>
            </div>
            <div class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip" *ngIf="countryNotSupported">
              Country not supported
            </div>
            <div
              class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
              *ngIf="isMerchantDirectorFieldMissing(controlName)"
            >
              Required
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #formFieldDate let-controlName="controlName" let-placeholder="placeholder">
        <div class="text-input-group col nopadding">
          <label class="label-text">
            {{ placeholder }}
          </label>
          <div class="verification-form-input-tooltip-container">
            <input
              [formControlName]="controlName"
              [max]="minimalBirthDate | bpMomentDate: 'YYYY-MM-DD'"
              type="date"
              class="confirmo-platform-input verification-input"
            />
            <ng-container *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
              <div
                class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
                *ngIf="birthDate.errors?.required"
              >
                Date of birth is required
              </div>
              <div
                class="confirmo-input-invalid-tooltip__no-prepend verification-tooltip"
                *ngIf="birthDate.errors?.maxDate"
              >
                Birth date must be in the past
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
</bp-header-dialog>
