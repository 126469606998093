import { createReducer, on } from '@ngrx/store';
import {
  activateUserWithPasswordErrorAction,
  activateUserWithPasswordSuccessAction,
  getUserActivationStatusAction,
  getUserActivationStatusErrorAction,
  getUserActivationStatusSuccessAction,
} from '../actions/user-activation.actions';
import { UserActivationModel } from '../models/api/user-activation.model';
import { StateModel } from '../models/auxiliary/state.model';

export interface UserActivationStateData {
  hash?: string;
  userActivation?: UserActivationModel;
  userActivationStatusRetrieved?: boolean;
}

export type UserActivationState = StateModel<UserActivationStateData>;

const INITIAL_STATE: UserActivationState = {
  errors: null,
  data: null,
};

export const userActivationReducer = createReducer(
  INITIAL_STATE,
  on(getUserActivationStatusAction, (state, { hash }) => ({
    ...state,
    data: {
      hash: hash,
    },
  })),
  on(getUserActivationStatusSuccessAction, (state, { userActivation }) => ({
    ...state,
    data: {
      ...state.data,
      userActivation: userActivation,
      userActivationStatusRetrieved: true,
    },
  })),
  on(activateUserWithPasswordSuccessAction, (state, { userActivation }) => ({
    ...state,
    data: {
      ...state.data,
      userActivation: userActivation,
    },
  })),
  on(getUserActivationStatusErrorAction, activateUserWithPasswordErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
