import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { SeoService } from '../services/seo.service';

@Injectable({
  providedIn: 'root',
})
export class MetaGuard implements CanActivateChild {
  DEFAULT_TITLE = 'Confirmo';

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const metaData = childRoute.data.meta;
    const canonicalLink = childRoute.data.canonical;

    if (metaData !== null && metaData !== undefined) {
      this.seoService.updateMetaTags(metaData);
    } else {
      this.seoService.updateMetaTags({ title: this.DEFAULT_TITLE, 'twitter:url': state.url, 'og:url': state.url });
    }

    this.seoService.updateCanonicalUrl(canonicalLink);

    return true;
  }

  constructor(private seoService: SeoService) {}
}
