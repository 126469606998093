import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MerchantProfileUnionModel } from '../../shared/models/api/merchant-profile/merchant-profile-union.model';
import { ApiService } from '../../shared/services/api.service';
import { fromApiToModelProfile } from '../../shared/transformations/merchant-profile-transformations';

export const MERCHANT_PROFILE_ENDPOINT = '/merchant-profile';

@Injectable({
  providedIn: 'root',
})
export class MerchantProfileService {
  constructor(private api: ApiService) {}

  get(): Observable<MerchantProfileUnionModel> {
    return this.api.get(`${MERCHANT_PROFILE_ENDPOINT}`).pipe(map((response) => fromApiToModelProfile(response)));
  }
}
