import bigDecimal from 'js-big-decimal';

export enum RoundingModes {
  UP,
  DOWN,
  HALF_EVEN,
  HALF_UP,
  HALF_DOWN,
  FLOOR,
  CEILING,
}

export type BigDecimalizable = BigDecimal | bigDecimal | bigint | string | number;

// @ts-ignore - ignore warning about type being used as a namespace
type LibraryRoundingMode = bigDecimal.RoundingModes;

/**
 * Mostly wrapper around bigDecimal library which should make our life a bit easier
 *  - things like passing rounding mode were a bit cumbersome in the library
 * Each operation which might somehow modify value returns new instance - instances should be immutable
 */
export class BigDecimal {
  public static readonly ZERO = new BigDecimal(0);
  private readonly wrappedValueObject: bigDecimal;

  constructor(value: BigDecimalizable) {
    if (value instanceof BigDecimal) {
      this.wrappedValueObject = new bigDecimal(value.stringValue);
    } else if (value instanceof bigDecimal) {
      this.wrappedValueObject = new bigDecimal(value.getValue());
    } else {
      this.wrappedValueObject = new bigDecimal(value);
    }
  }

  public toString(): string {
    return this.stringValue;
  }

  public get stringValue(): string {
    return this.wrappedValueObject.getValue();
  }

  public add(value: BigDecimal): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.add(value.wrappedValueObject));
  }

  public sub(value: BigDecimal): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.subtract(value.wrappedValueObject));
  }

  public multiply(multiplier: BigDecimal): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.multiply(multiplier.wrappedValueObject));
  }

  public divide(divisor: BigDecimal, precision: number): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.divide(divisor.wrappedValueObject, precision));
  }

  public modulus(divisor: BigDecimal): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.modulus(divisor.wrappedValueObject));
  }

  public get negate(): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.negate());
  }

  public get abs(): BigDecimal {
    return BigDecimal.of(this.wrappedValueObject.abs());
  }

  public round(precision: number, roudingMode: RoundingModes): BigDecimal {
    return BigDecimal.of(
      this.wrappedValueObject.round(precision, this.appRoundingModeToLibraryRoundingMode(roudingMode)),
    );
  }

  /**
   * js-big-decimal library, which is used for representation of decimal numbers, does not support
   * removing of trailing zeros (https://github.com/royNiladri/js-big-decimal/issues/82) yet, so for this purpose
   * we need to use our own implementation. Once the feature is implemented in the library, it would be better to
   * replace this code by using of their function.
   * @param minimalZerosCount default: 2
   */
  public stripTrailingZeros(minimalZerosCount: number = 2): BigDecimal {
    if (minimalZerosCount < 0 || !Number.isInteger(minimalZerosCount)) {
      throw new Error('Minimal zero count must be non-negative integer');
    }
    const amountToString = this.wrappedValueObject.getValue();
    const parts = amountToString.split('.');
    const partInt = parts[0];
    const partDec = parts[1];
    if (partDec) {
      if (partDec.length > minimalZerosCount) {
        const potentialToStripSubstring = partDec.substring(minimalZerosCount);
        const potentialToStripSubstringFloat = parseFloat(potentialToStripSubstring);
        return potentialToStripSubstringFloat === 0
          ? BigDecimal.of(partInt + '.' + partDec.substring(0, minimalZerosCount))
          : BigDecimal.of(partInt + '.' + partDec.replace(/0*$/, ''));
      } else if (partDec.length < minimalZerosCount) {
        return BigDecimal.of(partInt + '.' + partDec.padEnd(minimalZerosCount, '0'));
      } else {
        return BigDecimal.of(this.wrappedValueObject);
      }
    } else if (!partDec && minimalZerosCount > 0) {
      return BigDecimal.of(partInt + '.' + ''.padEnd(minimalZerosCount, '0'));
    } else if (!partDec && minimalZerosCount === 0) {
      return BigDecimal.of(partInt);
    }
  }

  public max(...otherValues: BigDecimal[]): BigDecimal {
    return BigDecimal.max(this, ...otherValues);
  }

  public static max(value: BigDecimal, ...rest: BigDecimal[]): BigDecimal {
    return [value, ...rest].reduce((crntMax, crntValue) => (crntValue.isGreaterThan(crntMax) ? crntValue : crntMax));
  }

  public min(...otherValues: BigDecimal[]): BigDecimal {
    return BigDecimal.min(this, ...otherValues);
  }

  public static min(value: BigDecimal, ...rest: BigDecimal[]) {
    return [value, ...rest].reduce((crntMin: BigDecimal, crntValue: BigDecimal) =>
      crntMin.isGreaterThan(crntValue) ? crntValue : crntMin,
    );
  }

  private appRoundingModeToLibraryRoundingMode(roundingMode: RoundingModes): LibraryRoundingMode {
    //Signature is not exactly what it should be, but wasn't able to use enum declared by library as return type
    //Using little cheat bound to the fact that bigDecimal.RoundingModes enum is number based
    switch (roundingMode) {
      case RoundingModes.UP:
        return bigDecimal.RoundingModes.UP;
      case RoundingModes.DOWN:
        return bigDecimal.RoundingModes.DOWN;
      case RoundingModes.HALF_EVEN:
        return bigDecimal.RoundingModes.HALF_EVEN;
      case RoundingModes.HALF_UP:
        return bigDecimal.RoundingModes.HALF_UP;
      case RoundingModes.HALF_DOWN:
        return bigDecimal.RoundingModes.HALF_DOWN;
      case RoundingModes.FLOOR:
        return bigDecimal.RoundingModes.FLOOR;
      case RoundingModes.CEILING:
        return bigDecimal.RoundingModes.CEILING;
    }
  }

  public compareTo(other: BigDecimal): -1 | 0 | 1 {
    return this.wrappedValueObject.compareTo(other.wrappedValueObject);
  }

  public isEqualTo(other: BigDecimal): boolean {
    return !this.compareTo(other);
  }

  public isGreaterThan(other: BigDecimal): boolean {
    return this.compareTo(other) > 0;
  }

  public isGreaterOrEqualTo(other: BigDecimal): boolean {
    return this.compareTo(other) >= 0;
  }

  public get isPositive(): boolean {
    return this.isGreaterThan(BigDecimal.ZERO);
  }

  public get isNegative(): boolean {
    return BigDecimal.ZERO.isGreaterThan(this);
  }

  public get isZero(): boolean {
    return this.isEqualTo(BigDecimal.ZERO);
  }

  public get isNonZero(): boolean {
    return !this.isZero;
  }

  public static of(value: BigDecimalizable): BigDecimal {
    return new BigDecimal(value);
  }

  public static ofOptional(value?: BigDecimalizable | null): BigDecimal | null {
    return value !== null && value !== undefined ? BigDecimal.of(value) : null;
  }
}
