import { Injectable } from '@angular/core';
import { APP_CONFIG } from '../../../config';
import { AppConfigModel } from '../models/auxiliary/app-config.model';

@Injectable()
export class AppConfigService {
  get config(): AppConfigModel {
    if (APP_CONFIG == null || APP_CONFIG.config == null) {
      throw new Error('App configuration was not loaded!');
    }

    return APP_CONFIG.config;
  }
}
