/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { SettlementMethodTo } from '../../models/api/settlement-method.model';

export enum SettlementMethodActionsTypes {
  UpdateSettlementMethodNotification = 'settlementMethod/UPDATE_NOTIFICATION_REQUEST',
  UpdateSettlementMethodNotificationSuccess = 'settlementMethod/UPDATE_NOTIFICATION_SUCCESS',
  UpdateSettlementMethodNotificationError = 'settlementMethod/UPDATE_NOTIFICATION_ERROR',
}

export const updateSettlementMethodNotificationAction = createAction(
  SettlementMethodActionsTypes.UpdateSettlementMethodNotification,
  props<{ methodToUpdate: SettlementMethodTo; isAdmin: boolean }>(),
);

export const updateSettlementMethodNotificationSuccessAction = createAction(
  SettlementMethodActionsTypes.UpdateSettlementMethodNotificationSuccess,
  props<{ updatedMethod: SettlementMethodTo }>(),
);

export const updateSettlementMethodNotificationErrorAction = createAction(
  SettlementMethodActionsTypes.UpdateSettlementMethodNotificationError,
  props<{ errors: ErrorModel[] }>(),
);
