export enum LanguageName {
  ENGLISH = 'English',
  CZECH = 'Česky',
  GERMAN = 'Deutsch',
  ITALIAN = 'Italiano',
  JAPANESE = '日本語',
  CHINESE_SIMPLIFIED = '中文(简体)',
  CHINESE_TRADITIONAL = '中文(繁體)',
}
export enum LanguageCode {
  ENGLISH = 'en',
  CZECH = 'cs',
  GERMAN = 'de',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  CHINESE_SIMPLIFIED = 'zh-Hans',
  CHINESE_TRADITIONAL = 'zh-Hant',
}
