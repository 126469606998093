/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { PublicProfileModel } from '../../models/api/public-profile.model';

export enum PublicProfileActionTypes {
  GetPublicProfile = 'publicProfile/GET_REQUEST',
  GetPublicProfileSuccess = 'publicProfile/GET_SUCCESS',
  GetPublicProfileError = 'publicProfile/GET_ERROR',
  GetPublicProfileByMerchantId = 'publicProfile/GET_BY_MERCHANT_ID_REQUEST',
  GetPublicProfileByMerchantIdSuccess = 'publicProfile/GET_BY_MERCHANT_ID_SUCCESS',
  GetPublicProfileByMerchantIdError = 'publicProfile/GET_BY_MERCHANT_ID_ERROR',
  UpdatePublicProfile = 'publicProfile/UPDATE_REQUEST',
  UpdatePublicProfileSuccess = 'publicProfile/UPDATE_SUCCESS',
  UpdatePublicProfileError = 'publicProfile/UPDATE_ERROR',
}

export const getPublicProfileAction = createAction(PublicProfileActionTypes.GetPublicProfile);

export const getPublicProfileSuccessAction = createAction(
  PublicProfileActionTypes.GetPublicProfileSuccess,
  props<{ publicProfile: PublicProfileModel }>(),
);

export const getPublicProfileErrorAction = createAction(
  PublicProfileActionTypes.GetPublicProfileError,
  props<{ errors: ErrorModel[] }>(),
);

export const getPublicProfileByMerchantIdAction = createAction(
  PublicProfileActionTypes.GetPublicProfileByMerchantId,
  props<{ merchantId: string }>(),
);

export const getPublicProfileByMerchantIdSuccessAction = createAction(
  PublicProfileActionTypes.GetPublicProfileByMerchantIdSuccess,
  props<{ publicProfile: PublicProfileModel }>(),
);

export const getPublicProfileByMerchantIdErrorAction = createAction(
  PublicProfileActionTypes.GetPublicProfileByMerchantIdError,
  props<{ errors: ErrorModel[] }>(),
);

export const updatePublicProfileErrorAction = createAction(
  PublicProfileActionTypes.UpdatePublicProfileError,
  props<{ errors: ErrorModel[] }>(),
);

export const updatePublicProfileAction = createAction(
  PublicProfileActionTypes.UpdatePublicProfile,
  props<{ publicProfile: PublicProfileModel }>(),
);

export const updatePublicProfileSuccessAction = createAction(
  PublicProfileActionTypes.UpdatePublicProfileSuccess,
  props<{ publicProfile: PublicProfileModel }>(),
);
