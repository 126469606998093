import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RefundApiService } from './refund-api.service';
import {
  createRefundAdminAction,
  createRefundAdminErrorAction,
  createRefundAdminSuccessAction,
  createRefundMerchantAction,
  createRefundMerchantErrorAction,
  createRefundMerchantSuccessAction,
  createRefundPublicAction,
  createRefundPublicErrorAction,
  createRefundPublicSuccessAction,
  markRefundAsRefundedAction,
  markRefundAsRefundedErrorAction,
  markRefundAsRefundedSuccessAction,
  sendRefundsAction,
  sendRefundsErrorAction,
  sendRefundsSuccessAction,
  updateRefundCryptoAddressAction,
  updateRefundCryptoAddressErrorAction,
  updateRefundCryptoAddressSuccessAction,
} from './refund.actions';

@Injectable()
export class RefundEffect {
  createRefundPublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRefundPublicAction),
      switchMap(({ id, body }) =>
        this.refundApiService.createRefundPublic(id, body).pipe(
          map(() => createRefundPublicSuccessAction()),
          catchError((errors) => observableOf(createRefundPublicErrorAction(errors))),
        ),
      ),
    ),
  );

  createRefundMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRefundMerchantAction),
      switchMap(({ body, invoiceId, twoFaToken }) =>
        this.refundApiService.createRefundMerchant(invoiceId, body, twoFaToken).pipe(
          map((refund) => createRefundMerchantSuccessAction({ refund })),
          catchError((errors) => observableOf(createRefundMerchantErrorAction(errors))),
        ),
      ),
    ),
  );

  createRefundAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRefundAdminAction),
      switchMap(({ body, invoiceId }) =>
        this.refundApiService.createRefundAdmin(invoiceId, body).pipe(
          map((refund) => createRefundAdminSuccessAction({ refund })),
          catchError((errors) => observableOf(createRefundAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  markRefundAsRefunded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markRefundAsRefundedAction),
      switchMap(({ id, body }) =>
        this.refundApiService.markRefundAsRefunded(id, body).pipe(
          map(() => markRefundAsRefundedSuccessAction()),
          catchError((errors) => observableOf(markRefundAsRefundedErrorAction(errors))),
        ),
      ),
    ),
  );

  sendRefunds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendRefundsAction),
      switchMap(({ refundsIds }) =>
        this.refundApiService.sendRefunds(refundsIds).pipe(
          map(() => sendRefundsSuccessAction({ refundsIds: refundsIds })),
          catchError((errors) => observableOf(sendRefundsErrorAction(errors))),
        ),
      ),
    ),
  );

  updateRefundCryptoAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRefundCryptoAddressAction),
      switchMap(({ newCryptoAddress, refundHashId }) =>
        this.refundApiService.updateRefundCryptoAddress(newCryptoAddress, refundHashId).pipe(
          map((refund) => updateRefundCryptoAddressSuccessAction({ refund })),
          catchError((errors) => observableOf(updateRefundCryptoAddressErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private refundApiService: RefundApiService,
  ) {}
}
