/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { UserStatsModel } from '../models/api/user-stats.model';

export enum UserStatsActionTypes {
  GetUserStats = 'userStats/GET_REQUEST',
  GetUserStatsSuccess = 'userStats/GET_SUCCESS',
  GetUserStatsError = 'userStats/GET_ERROR',
}

export const getUserStatsAction = createAction(UserStatsActionTypes.GetUserStats);

export const getUserStatsSuccessAction = createAction(
  UserStatsActionTypes.GetUserStatsSuccess,
  props<{ userStats: UserStatsModel }>(),
);

export const getUserStatsErrorAction = createAction(
  UserStatsActionTypes.GetUserStatsError,
  props<{ errors: ErrorModel[] }>(),
);
