import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../models/api/user.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  deleteUserErrorAction,
  getUserListAction,
  getUserListErrorAction,
  getUserListSuccessAction,
  resendActivationEmailErrorAction,
} from './user-list.actions';

export type UserListState = StateModel<UserModel[]>;

const INITIAL_STATE: UserListState = {
  errors: null,
  data: null,
};

export const userListReducer = createReducer(
  INITIAL_STATE,
  on(getUserListAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(getUserListSuccessAction, (state, { users }) => ({
    ...state,
    data: users,
  })),
  on(getUserListErrorAction, deleteUserErrorAction, resendActivationEmailErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
