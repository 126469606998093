import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/authorization/auth.service';

/**
 * This constant is used for redirecting to unsecured page (page, that can be accessed by all authorized users,
 * it isn't secured by any guards) to initialize PlatformComponent and process invalid token.
 */
const UNSECURED_PAGE_URL = '/platform/dashboard';
const DEFAULT_USER_PAGE = '/platform/dashboard';
const DEFAULT_ADMIN_PAGE = 'platform/admin';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivateChild(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isUserAuthorized().pipe(
      mergeMap((authorized) => {
        if (!authorized) {
          this.router.navigateByUrl('/home');
          return observableOf(false);
        } else {
          return this.authService.invalidTokenUsed().pipe(
            map((invalidTokenUsed) => {
              if (invalidTokenUsed && routerState.url !== UNSECURED_PAGE_URL) {
                this.router.navigateByUrl(UNSECURED_PAGE_URL);
                return false;
              }

              if (routerState.url === '/platform') {
                this.navigateToDefaultPage();
              }
              return true;
            }),
          );
        }
      }),
    );
  }

  private navigateToDefaultPage(): void {
    this.authService.isUserAdmin().subscribe((isAdmin) => {
      this.router.navigateByUrl(isAdmin ? DEFAULT_ADMIN_PAGE : DEFAULT_USER_PAGE);
    });
  }
}
