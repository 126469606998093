import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  refreshStateOnIntervalAction,
  stopRefreshStateOnIntervalAction,
} from '../../../../../shared/actions/merchant-verification-idenfy.actions';
import { wizardNextStepAction } from '../../../../../shared/actions/wizard.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { IdCheckStatus } from '../../../../../shared/enums/id-check-status.enum';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { selectIdcheckVerificationPerson } from '../../../../../shared/selectors/merchant-verification-idenfy.selector';
import { MessageService } from '../../../../../shared/services/message.service';

@Component({
  selector: 'bp-idenfy-status',
  templateUrl: './idenfy-status.component.html',
  styleUrls: ['./idenfy-status.component.scss', '../../aml-wizard.scss'],
})
export class IdenfyStatusComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input() merchantId: string;
  @Output() showMethodOffer = new EventEmitter<boolean>();
  status: IdCheckStatus;
  nextStepCalled: Boolean = false;
  timeout: any;

  constructor(
    private store: Store<AppStateModel>,
    private messageService: MessageService,
  ) {
    super();

    this.store
      .select(selectIdcheckVerificationPerson)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'ID Check Person Error');
          return;
        }

        if (state.data) {
          this.status = state.data.idCheckStatus;
          if (this.status !== IdCheckStatus.REVIEWING) {
            this.store.dispatch(stopRefreshStateOnIntervalAction());
          }

          if (this.status === IdCheckStatus.APPROVED) {
            this.timeout = setTimeout(() => {
              this.nextStep();
            }, 5000);
          }
        }
      });
  }

  ngOnInit() {
    this.store.dispatch(refreshStateOnIntervalAction({ merchantId: this.merchantId }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    clearTimeout(this.timeout);
  }

  nextStep(): void {
    if (this.nextStepCalled) {
      return;
    }

    this.nextStepCalled = true;
    this.store.dispatch(wizardNextStepAction());
  }

  emitBackToOffer(): void {
    this.showMethodOffer.emit(true);
  }
}
