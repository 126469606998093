/* eslint-disable max-classes-per-file */
import { HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';

export enum PdfSettlementActionTypes {
  GetPdfSettlement = 'pdfsettlement/GET_PDF_SETTLEMENT_REQUEST',
  GetPdfSettlementSuccess = 'pdfsettlement/GET_PDF_SETTLEMENT_SUCCESS',
  GetPdfSettlementError = 'pdfsettlement/GET_PDF_SETTLEMENT_ERROR',
}

export const getPdfSettlementAction = createAction(
  PdfSettlementActionTypes.GetPdfSettlement,
  props<{ settlementHashId: string }>(),
);

export const getPdfSettlementSuccessAction = createAction(
  PdfSettlementActionTypes.GetPdfSettlementSuccess,
  props<{ pdfSettlementResponse: HttpResponse<Blob> }>(),
);

export const getPdfSettlementErrorAction = createAction(
  PdfSettlementActionTypes.GetPdfSettlementError,
  props<{ errors: ErrorModel[] }>(),
);
