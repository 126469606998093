import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { FlattenedRouteData, RouteFlattenerService } from './route-flattener.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRouterStoreSerializerService
  implements RouterStateSerializer<SerializedRouterStateSnapshotWithFlattenedData>
{
  public constructor(protected flattener: RouteFlattenerService) {}

  public serialize(routerState: RouterStateSnapshot): SerializedRouterStateSnapshotWithFlattenedData {
    const flattened = this.flattener.getFlattenedRouteData(routerState.root);

    return {
      flattened: {
        data: flattened.data,
        params: flattened.params,
        queryParams: flattened.queryParams,
      },
      root: this.serializeRoute(routerState.root),
      url: routerState.url,
    };
  }

  private serializeRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    const children = route.children.map((c) => this.serializeRoute(c));

    return {
      title: route.title,
      params: route.params,
      paramMap: route.paramMap,
      data: route.data,
      url: route.url,
      outlet: route.outlet,
      routeConfig: route.routeConfig
        ? {
            component: route.routeConfig.component,
            path: route.routeConfig.path,
            pathMatch: route.routeConfig.pathMatch,
            redirectTo: route.routeConfig.redirectTo,
            outlet: route.routeConfig.outlet,
          }
        : null,
      queryParams: route.queryParams,
      queryParamMap: route.queryParamMap,
      fragment: route.fragment,
      component: (route.routeConfig ? route.routeConfig.component : undefined) as any,
      root: undefined as any,
      parent: undefined as any,
      firstChild: children[0],
      pathFromRoot: undefined as any,
      children,
    };
  }
}

export interface SerializedRouterStateSnapshotWithFlattenedData extends SerializedRouterStateSnapshot {
  flattened: Partial<FlattenedRouteData>;
}

export type CustomRouterReducerState = RouterReducerState<SerializedRouterStateSnapshotWithFlattenedData>;
