import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabBarItem } from '../models/auxiliary/tab-bar-item.model';
import { AuthService } from './authorization/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TabAccessService {
  constructor(private auth: AuthService) {}

  hasAccess(tabs: TabBarItem[], route: string): Observable<boolean> {
    return this.auth.getLoggedInUserRole().pipe(
      map((role) => {
        const tab: TabBarItem = tabs.find((t) => t.routerLink === route);
        return tab && (tab.hasAccess ? tab.hasAccess(role) : true);
      }),
    );
  }

  getFirstAllowedTabLink(tabs: TabBarItem[]): Observable<string> {
    return this.auth.getLoggedInUserRole().pipe(
      map((role) => {
        const tab: TabBarItem = tabs.find((t) => (t.hasAccess ? t.hasAccess(role) : true));
        return tab ? tab.routerLink : null;
      }),
    );
  }

  getVisibleTabsForRole(tabs: TabBarItem[]): Observable<TabBarItem[]> {
    return this.auth
      .getLoggedInUserRole()
      .pipe(map((role) => tabs.filter((t) => (t.hasAccess ? t.hasAccess(role) : true))));
  }
}
