import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SettlementMethodApiService } from './settlement-method-api.service';
import {
  createSettlementMethodAction,
  createSettlementMethodErrorAction,
  createSettlementMethodSuccessAction,
  getSettlementMethodFeeAction,
  getSettlementMethodFeeErrorAction,
  getSettlementMethodFeeSuccessAction,
  updateSettlementMethodAction,
  updateSettlementMethodErrorAction,
  updateSettlementMethodSuccessAction,
} from './settlement-method.actions';

@Injectable()
export class SettlementMethodEffect {
  createSettlementMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSettlementMethodAction),
      switchMap(({ settlementMethod, twoFa }) =>
        this.settlementMethodApiService.create(settlementMethod, twoFa).pipe(
          map((settlementMethodTo) => createSettlementMethodSuccessAction({ settlementMethodTo })),
          catchError((errors) => observableOf(createSettlementMethodErrorAction(errors))),
        ),
      ),
    ),
  );

  updateSettlementMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSettlementMethodAction),
      mergeMap(({ settlementMethod, twoFa }) =>
        this.settlementMethodApiService.update(settlementMethod, twoFa).pipe(
          map((settlementMethodTo) => updateSettlementMethodSuccessAction({ settlementMethodTo })),
          catchError((errors) => observableOf(updateSettlementMethodErrorAction(errors))),
        ),
      ),
    ),
  );

  getFee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSettlementMethodFeeAction),
      switchMap(({ settlementMethod }) =>
        this.settlementMethodApiService.getFee(settlementMethod).pipe(
          map((fee) => getSettlementMethodFeeSuccessAction({ fee })),
          catchError((errors) => observableOf(getSettlementMethodFeeErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private settlementMethodApiService: SettlementMethodApiService,
  ) {}
}
