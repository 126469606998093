import { createReducer, on } from '@ngrx/store';
import {
  changeMerchantVerificationStatusAction,
  getMerchantVerificationStatusAction,
  getMerchantVerificationStatusErrorAction,
  getMerchantVerificationStatusSuccessAction,
  resetMerchantVerificationAction,
  resetMerchantVerificationErrorAction,
  resetMerchantVerificationSuccessAction,
} from '../actions/merchant-verification-status.actions';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantVerificationStatusState = StateModel<MerchantProfileUnionModel>;

export const initialState: MerchantVerificationStatusState = {
  data: null,
  errors: null,
  loading: false,
};

export const merchantVerificationStatusReducer = createReducer(
  initialState,
  on(resetMerchantVerificationAction, getMerchantVerificationStatusAction, (state) => ({
    ...initialState,
    loading: true,
  })),
  on(changeMerchantVerificationStatusAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(resetMerchantVerificationSuccessAction, getMerchantVerificationStatusSuccessAction, (state, { merchant }) => ({
    ...state,
    data: merchant,
    loading: false,
  })),
  on(resetMerchantVerificationErrorAction, getMerchantVerificationStatusErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
