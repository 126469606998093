import { createReducer, on } from '@ngrx/store';
import { SettlementModel } from '../../models/api/settlement.model';
import { PagingModel } from '../../models/auxiliary/paging.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getSettlementPageAction,
  getSettlementPageErrorAction,
  getSettlementPageSuccessAction,
  patchSettlementsErrorAction,
} from './settlement-page.actions';

export type SettlementPageState = StateModel<PagingModel<SettlementModel>>;

const INITIAL_STATE: SettlementPageState = {
  data: null,
  errors: null,
  loading: false,
};

export const settlementPageReducer = createReducer(
  INITIAL_STATE,
  on(getSettlementPageAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(getSettlementPageSuccessAction, (state, { settlements }) => ({
    ...state,
    data: settlements,
    loading: false,
  })),
  on(getSettlementPageErrorAction, patchSettlementsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);
